"use client";
import { AppLayout } from "@/components/app-layout";
import CopyToClipboard from "@/components/copy-to-clipboard";
import AddIntegration, {
  IntegrationLogo,
} from "@/components/settings/add-integration";
import { TableCard } from "@/components/table-card";
import { CardHeader, CardTitle } from "@/components/ui/card";
import { apiClient } from "@/lib/api";
import { components } from "@/lib/api.types";
import { dateTimeBuilder } from "@/lib/time";
import {
  keepPreviousData,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import {
  IntegrationPlatform,
  IntegrationType,
  ROLE,
  getIntegrationConfigByPlatform,
  getIntegrationTypeConfigByType,
} from "@wire/shared";
import { FetchResponse } from "openapi-fetch";
import { useState } from "react";
import { toast } from "sonner";

export const Route = createFileRoute("/_application/settings/integrations/")({
  loader: async ({ context }) => {
    await context.queryClient.ensureQueryData(getIntegrationQueryOptions({}));
  },
  component: IntegrationSettings,
});

async function searchIntegrations(dto: components["schemas"]["PaginationDto"]) {
  const response = await apiClient.POST("/integration", {
    body: { ...dto, includeDisabled: true },
  });
  if (response.error != null) {
    throw new Error("Error searching integration");
  }
  return response.data;
}

const getIntegrationQueryOptions = (
  searchSettings: components["schemas"]["PaginationDto"]
) => ({
  queryKey: [INTEGRATION_SETTINGS_QUERY, searchSettings],
  queryFn: () => searchIntegrations(searchSettings),
  placeholderData: keepPreviousData,
});

export const INTEGRATION_SETTINGS_QUERY = "settings-integration";

export default function IntegrationSettings() {
  const [searchSettings, setSearchSettings] = useState<
    components["schemas"]["PaginationDto"]
  >({});
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  useState<IntegrationPlatform>();
  const integrationQuery = useQuery(getIntegrationQueryOptions(searchSettings));

  async function toggleIntegration(
    integration: components["schemas"]["IntegrationWithoutMetadata"]
  ) {
    let response: FetchResponse<any, any, any>;
    if (integration.enabled) {
      response = await apiClient.PATCH("/integration/{id}/deactivate", {
        params: { path: { id: integration.id } },
      });
    } else {
      response = await apiClient.PATCH("/integration/{id}/activate", {
        params: { path: { id: integration.id } },
      });
    }
    if (response.error != null) {
      return toast.error("Error updating integration");
    }
    if (integration.enabled) {
      toast.warning("Integration disabled");
    } else {
      toast.success("Integration enabled");
    }
    await queryClient.invalidateQueries({
      queryKey: [INTEGRATION_SETTINGS_QUERY],
    });
  }

  async function deleteIntegration(id: string) {
    const response = await apiClient.DELETE("/integration/{id}", {
      params: { path: { id: id } },
    });
    if (response.error != null) {
      toast.error("Error deleting integration");
    } else {
      toast.warning("Integration deleted");
    }
    await queryClient.invalidateQueries({
      queryKey: [INTEGRATION_SETTINGS_QUERY],
    });
  }

  async function testIntegration(id: string) {
    const response = await apiClient.POST("/integration/{id}/test", {
      params: { path: { id: id } },
    });
    if (response.error != null) {
      toast.error("Error testing integration");
    } else {
      toast.success("Integration test sent");
    }
  }

  return (
    <AppLayout>
      <div className="flex flex-col gap-4">
        <TableCard
          query={integrationQuery}
          onUpdate={setSearchSettings}
          onClick={(row) =>
            navigate({
              to: "/settings/integrations/$integrationId",
              params: { integrationId: row.id },
            })
          }
          rowActions={[
            {
              name: (row) => (row.enabled ? "Disable" : "Enable"),
              onClick: toggleIntegration,
              requiredRole: ROLE.ADMIN,
            },
            {
              name: "Test",
              requiredRole: ROLE.ANALYST,
              shouldDisplay: (row) =>
                getIntegrationConfigByPlatform(row.platform).types.includes(
                  IntegrationType.CHAT
                ),
              onClick: (row) => testIntegration(row.id),
            },
            {
              name: "Delete",
              requiredRole: ROLE.ADMIN,
              confirm: true,
              confirmMessage: (row) =>
                `Are you sure you want to delete the this integration?`,
              onClick: (row) => deleteIntegration(row.id),
            },
          ]}
          headers={[
            {
              key: "id",
              display: "Name",
              format: (val, row) => {
                let config = getIntegrationConfigByPlatform(row.platform);
                return (
                  <div className="flex items-center space-x-2">
                    <IntegrationLogo platform={row.platform} />
                    <div className="flex flex-col">
                      <span>{config.display}</span>
                      <span className="text-xs text-muted-foreground">
                        {config.types
                          .map((v) => getIntegrationTypeConfigByType(v).display)
                          .join(", ")}
                      </span>
                    </div>
                  </div>
                );
              },
            },
            {
              key: "identifier",
              display: "Identifier",
              format(value, row) {
                if (value == null)
                  return <div className="text-muted-foreground text-xs">-</div>;
                let label = value.split(" | ")[0];
                let display = value.split(" | ")[1];
                return (
                  <div className="text-muted-foreground text-xs">
                    {label} |{" "}
                    <CopyToClipboard showIcon iconOnlyClick text={display} />
                  </div>
                );
              },
            },
            {
              key: "enabled",
              display: "Enabled",
            },
            {
              key: "healthy",
              display: "Healthy",
            },
            {
              key: "lastUsedAt",
              display: "Last Used",
              format: (val) =>
                val != null ? dateTimeBuilder(val).fromNow() : "-",
            },
          ]}
        >
          <CardHeader>
            <div className="flex flex-col lg:items-center space-y-4 lg:space-y-0 lg:flex-row lg:space-x-4 lg:justify-between">
              <div className="flex flex-col flex-1 items-center lg:flex-row lg:justify-between">
                <CardTitle>Integrations</CardTitle>
                <AddIntegration />
              </div>
            </div>
          </CardHeader>
        </TableCard>
      </div>
    </AppLayout>
  );
}
