export enum IntegrationPlatform {
  SLACK = "SLACK",
  EMAIL = "EMAIL",
  MICROSOFT_TEAMS = "MICROSOFT_TEAMS",
  OKTA = "OKTA",
  MICROSOFT = "MICROSOFT",
  GOOGLE = "GOOGLE",
  HAVE_I_BEEN_PWNED = "HAVE_I_BEEN_PWNED",
  REVERSING_LABS = "REVERSING_LABS",
  SENTINEL_ONE = "SENTINEL_ONE",
  AWS = "AWS",
  IP_INFO = "IP_INFO",
}

export enum LogIntegrationType {
  IDENTITY = "IDENTITY",
}

export enum IntegrationUseCase {
  DIRECTORY = "DIRECTORY",
  DETECTIONS = "DETECTIONS",
  THREAT_INTELLIGENCE = "THREAT_INTELLIGENCE",
  CHAT = "CHAT",
}

export enum IntegrationType {
  CHAT = "CHAT",
  DIRECTORY = "DIRECTORY",
  IP_ENRICHMENT = "IP_ENRICHMENT",
  ENDPOINTS = "ENDPOINTS",
  DETECTIONS_ENDPOINT = "DETECTIONS_ENDPOINT",
  DETECTIONS_IDENTITY = "DETECTIONS_IDENTITY",
  DETECTIONS_EMAIL = "DETECTIONS_EMAIL",
  DETECTIONS_DATA = "DETECTIONS_DATA",
  DETECTIONS_NETWORK = "DETECTIONS_NETWORK",
  DETECTIONS_META = "DETECTIONS_META",
  DETECTIONS_CLOUD = "DETECTIONS_CLOUD",
  THREAT_INTELLIGENCE_FILE = "THREAT_INTELLIGENCE_FILE",
  UNKNOWN = "UNKNOWN",
}

export enum AdminIntegrationKeyType {
  MICROSOFT = IntegrationPlatform.MICROSOFT,
  GOOGLE = IntegrationPlatform.GOOGLE,
  SLACK = IntegrationPlatform.SLACK,
  MICROSOFT_TEAMS = IntegrationPlatform.MICROSOFT_TEAMS,
  AWS = IntegrationPlatform.AWS,
  HAVE_I_BEEN_PWNED = IntegrationPlatform.HAVE_I_BEEN_PWNED,
  REVERSING_LABS = IntegrationPlatform.REVERSING_LABS,
  IP_INFO = IntegrationPlatform.IP_INFO,
}

export interface IIntegrationConfig {
  platform: IntegrationPlatform;
  // This integration does not make use of an integration client
  // typically for first-party integrations like Have I Been Pwned
  // that don't rely on user keys
  noClient?: boolean;
  enabledByDefault?: boolean;
  display: string;
  logo?: string;
  lightLogo?: string;
  darkLogo?: string;
  beta?: boolean;
  useCases: IntegrationUseCase[];
  types: IntegrationType[];
  logTypes?: IntegrationType[];
}

export const IntegrationConfig: {
  [key in IntegrationPlatform]: IIntegrationConfig;
} = {
  [IntegrationPlatform.SLACK]: {
    platform: IntegrationPlatform.SLACK,
    display: "Slack",
    useCases: [IntegrationUseCase.CHAT],
    logo: "/logos/slack.png",
    types: [IntegrationType.CHAT],
  },
  [IntegrationPlatform.AWS]: {
    platform: IntegrationPlatform.AWS,
    display: "AWS",
    beta: true,
    useCases: [IntegrationUseCase.CHAT],
    lightLogo: "/logos/aws-light.svg",
    darkLogo: "/logos/aws-dark.svg",
    types: [IntegrationType.DETECTIONS_CLOUD],
  },
  [IntegrationPlatform.REVERSING_LABS]: {
    platform: IntegrationPlatform.REVERSING_LABS,
    display: "ReversingLabs",
    useCases: [IntegrationUseCase.THREAT_INTELLIGENCE],
    logo: "/logos/reversing-labs.png",
    types: [IntegrationType.THREAT_INTELLIGENCE_FILE],
  },
  [IntegrationPlatform.SENTINEL_ONE]: {
    platform: IntegrationPlatform.SENTINEL_ONE,
    display: "SentinelOne",
    logo: "/logos/sentinel-one.png",
    useCases: [IntegrationUseCase.DETECTIONS],
    types: [IntegrationType.DETECTIONS_ENDPOINT, IntegrationType.ENDPOINTS],
  },
  [IntegrationPlatform.MICROSOFT_TEAMS]: {
    platform: IntegrationPlatform.MICROSOFT_TEAMS,
    display: "Microsoft Teams",
    logo: "/logos/teams.png",
    useCases: [IntegrationUseCase.CHAT],
    types: [IntegrationType.CHAT],
  },
  [IntegrationPlatform.EMAIL]: {
    platform: IntegrationPlatform.EMAIL,
    display: "Email",
    logo: "/logos/email.svg",
    useCases: [IntegrationUseCase.CHAT],
    types: [IntegrationType.CHAT],
  },
  [IntegrationPlatform.OKTA]: {
    platform: IntegrationPlatform.OKTA,
    display: "Okta",
    logo: "/logos/okta.svg",
    useCases: [IntegrationUseCase.DIRECTORY],
    types: [IntegrationType.DIRECTORY],
  },
  [IntegrationPlatform.MICROSOFT]: {
    platform: IntegrationPlatform.MICROSOFT,
    display: "Microsoft",
    logo: "/logos/microsoft.svg",
    useCases: [IntegrationUseCase.DIRECTORY, IntegrationUseCase.DETECTIONS],
    types: [
      IntegrationType.DIRECTORY,
      IntegrationType.ENDPOINTS,
      IntegrationType.DETECTIONS_ENDPOINT,
      IntegrationType.DETECTIONS_IDENTITY,
    ],
    logTypes: [IntegrationType.DETECTIONS_IDENTITY],
  },
  [IntegrationPlatform.GOOGLE]: {
    platform: IntegrationPlatform.GOOGLE,
    display: "Google",
    logo: "/logos/google.png",
    useCases: [IntegrationUseCase.DIRECTORY, IntegrationUseCase.DETECTIONS],
    types: [
      IntegrationType.DETECTIONS_IDENTITY,
      IntegrationType.DETECTIONS_EMAIL,
      IntegrationType.DIRECTORY,
    ],
  },
  [IntegrationPlatform.HAVE_I_BEEN_PWNED]: {
    platform: IntegrationPlatform.HAVE_I_BEEN_PWNED,
    display: "Have I Been Pwned",
    logo: "/logos/hibp.png",
    noClient: true,
    enabledByDefault: true,
    useCases: [IntegrationUseCase.DETECTIONS],
    types: [IntegrationType.DETECTIONS_IDENTITY],
  },
  [IntegrationPlatform.IP_INFO]: {
    platform: IntegrationPlatform.IP_INFO,
    display: "IPInfo",
    logo: "/logos/ipinfo.png",
    noClient: true,
    enabledByDefault: true,
    useCases: [IntegrationUseCase.DETECTIONS],
    types: [IntegrationType.IP_ENRICHMENT],
  },
};

export function getIntegrationConfigByPlatform(platform: string) {
  return IntegrationConfig[platform as IntegrationPlatform];
}

export function getIntegrationConfigByType(type: string) {
  return Object.values(IntegrationConfig).filter((v) =>
    v.types.includes(type as IntegrationType)
  );
}

export function getIntegrationPlatformsByType(type: IntegrationType) {
  return Object.values(IntegrationConfig)
    .filter((v) => v.types.includes(type))
    .map((v) => v.platform);
}

export interface IIntegrationType {
  display: string;
  type: IntegrationType;
  detection?: boolean;
}

export const IntegrationTypeConfig: {
  [key in IntegrationType]: IIntegrationType;
} = {
  [IntegrationType.CHAT]: {
    type: IntegrationType.CHAT,
    display: "Chat",
  },
  [IntegrationType.THREAT_INTELLIGENCE_FILE]: {
    type: IntegrationType.THREAT_INTELLIGENCE_FILE,
    display: "File Enrichment",
  },
  [IntegrationType.UNKNOWN]: {
    type: IntegrationType.UNKNOWN,
    display: "Unknown",
  },
  [IntegrationType.DIRECTORY]: {
    type: IntegrationType.DIRECTORY,
    display: "Directory Sync",
  },
  [IntegrationType.IP_ENRICHMENT]: {
    type: IntegrationType.IP_ENRICHMENT,
    display: "IP Enrichment",
  },
  [IntegrationType.ENDPOINTS]: {
    type: IntegrationType.ENDPOINTS,
    display: "Endpoint Sync",
  },
  [IntegrationType.DETECTIONS_ENDPOINT]: {
    type: IntegrationType.DETECTIONS_ENDPOINT,
    display: "Endpoint Detections",
    detection: true,
  },
  [IntegrationType.DETECTIONS_IDENTITY]: {
    type: IntegrationType.DETECTIONS_IDENTITY,
    display: "Identity Detections",
    detection: true,
  },
  [IntegrationType.DETECTIONS_EMAIL]: {
    type: IntegrationType.DETECTIONS_EMAIL,
    display: "Email Detections",
    detection: true,
  },
  [IntegrationType.DETECTIONS_DATA]: {
    type: IntegrationType.DETECTIONS_DATA,
    display: "Data Detections",
    detection: true,
  },
  [IntegrationType.DETECTIONS_NETWORK]: {
    type: IntegrationType.DETECTIONS_NETWORK,
    display: "Network Detections",
    detection: true,
  },
  [IntegrationType.DETECTIONS_META]: {
    type: IntegrationType.DETECTIONS_META,
    display: "Meta Detections",
    detection: true,
  },
  [IntegrationType.DETECTIONS_CLOUD]: {
    type: IntegrationType.DETECTIONS_CLOUD,
    display: "Cloud Detections",
    detection: true,
  },
};

export function getIntegrationTypeConfigByType(
  type: IntegrationType | AdminIntegrationKeyType
) {
  return IntegrationTypeConfig[type as IntegrationType];
}
