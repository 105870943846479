import { BaseCustomEditor } from "@/components/flow/editor/base-custom-editor";
import { BaseFooter } from "@/components/flow/editor/base-footer";
import { NodeEditorFooter } from "@/components/flow/editor/utils/node-editor-footer";
import { NodeEditorProps } from "@/components/flow/flow.types";
import { BaseCustomNodeDataProps } from "@/components/flow/node/base-custom-node";
import { CardContent } from "@/components/ui/card";
import { memo } from "react";

export interface MatchNodeProps extends BaseCustomNodeDataProps {}

function MatchNodeEditor(props: NodeEditorProps<MatchNodeProps>) {
  return (
    <>
      <CardContent>
        <BaseCustomEditor {...props}></BaseCustomEditor>
      </CardContent>
      <BaseFooter
        className="flex flex-col gap-2 items-start"
        onClose={props.onClose}
      >
        <NodeEditorFooter
          onNodeUpdate={props.updateNode}
          updateNodeById={props.updateNodeById}
          selectedNode={props.selectedNode}
        />
      </BaseFooter>
    </>
  );
}

let MatchNodeEditorMemo = memo(MatchNodeEditor);

export { MatchNodeEditorMemo as MatchNodeEditor };
