import { BaseCustomEdgeDataProps } from "@/components/flow/edge/base-custom-edge";
import { BaseCustomEditor } from "@/components/flow/editor/base-custom-editor";
import { BaseFooter } from "@/components/flow/editor/base-footer";
import { EdgeLogger } from "@/components/flow/editor/utils/edge-logger";
import { EdgeStyleTypeSwitcher } from "@/components/flow/editor/utils/edge-switcher";
import { EdgeEditorProps } from "@/components/flow/flow.types";
import { CardContent } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { memo, useEffect, useState } from "react";

export interface SwitchEdgeProps extends BaseCustomEdgeDataProps {
  fieldSelector?: string;
  selectorAsTitle?: boolean;
  defaultEdge?: boolean;
}

function SwitchEdgeEditor(props: EdgeEditorProps<SwitchEdgeProps>) {
  const [selectorAsTitle, setSelectorAsTitle] = useState(
    props.selectedEdge?.data?.selectorAsTitle ?? false
  );
  const [fieldSelector, setFieldSelector] = useState(
    props.selectedEdge?.data?.fieldSelector ?? ""
  );

  useEffect(() => {
    props.updateData({ fieldSelector, selectorAsTitle });
  }, [fieldSelector, selectorAsTitle, props.updateData]);

  useEffect(() => {
    setFieldSelector(props.selectedEdge?.data?.fieldSelector ?? "");
    setSelectorAsTitle(props.selectedEdge?.data?.selectorAsTitle ?? false);
  }, [props.selectedEdge?.id]);

  return (
    <>
      <CardContent>
        <BaseCustomEditor
          hideTitle={
            props.selectedEdge?.data?.defaultEdge ??
            props.selectedEdge?.data?.selectorAsTitle
          }
          hideDescription={props.selectedEdge?.data?.defaultEdge}
          hideQuery
          {...props}
        >
          <div>
            <Label className="text-xs">Field Value</Label>
            <Input
              onChange={(e) => setFieldSelector(e.target.value)}
              value={fieldSelector}
              placeholder="id"
              className="font-mono"
            />
          </div>
          <EdgeLogger
            selectedEdge={props.selectedEdge}
            onEdgeUpdate={props.updateEdge}
            selectedDetection={props.selectedDetection}
          />
        </BaseCustomEditor>
      </CardContent>
      <BaseFooter
        className="items-start justify-normal"
        onClose={props.onClose}
      >
        <div className="flex flex-col gap-1">
          <Label className="text-xs">Use selector as title</Label>
          <Switch
            checked={selectorAsTitle}
            onCheckedChange={setSelectorAsTitle}
          />
        </div>
        <div className="flex flex-col gap-1">
          <Label className="text-xs">Default</Label>
          <Switch
            checked={props.selectedEdge?.data?.defaultEdge}
            onCheckedChange={(v) => props.updateData({ defaultEdge: v })}
          />
        </div>
        <EdgeStyleTypeSwitcher
          selectedEdge={props.selectedEdge}
          onEdgeUpdate={props.updateEdge}
        />
      </BaseFooter>
    </>
  );
}

let SwitchEdgeEditorMemo = memo(SwitchEdgeEditor);

export { SwitchEdgeEditorMemo as SwitchEdgeEditor };
