import { ConstrainedAppLayout } from "@/components/app-layout";
import AddNewClient from "@/components/service-provider/add-new-client";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute(
  "/_application/sp/settings/add-new-client"
)({
  component: AddNewClientRoute,
});

export default function AddNewClientRoute() {
  return (
    <ConstrainedAppLayout>
      <AddNewClient />
    </ConstrainedAppLayout>
  );
}
