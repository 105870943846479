import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSeparator,
  InputOTPSlot,
} from "@/components/ui/input-otp";
import { Logo } from "@/components/ui/logo";
import { apiClient, getUser, hasValidAuthToken, setAPIToken } from "@/lib/api";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Link,
  SearchSchemaInput,
  createFileRoute,
  useNavigate,
} from "@tanstack/react-router";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";
const formSchema = z.object({
  email: z.string().email().min(2, {}),
  password: z.string().min(12, {}),
});

export const Route = createFileRoute("/_authentication/login")({
  validateSearch: (
    search: {
      redirect?: string;
    } & SearchSchemaInput
  ) => {
    return { redirect: search.redirect };
  },
  component: Authentication,
});

function Authentication() {
  const navigate = useNavigate();
  const { redirect: unsafeRedirect } = Route.useSearch();
  const redirect = useMemo(() => {
    if (
      unsafeRedirect &&
      (!unsafeRedirect.startsWith("/") || unsafeRedirect.startsWith("//"))
    ) {
      return;
    }
    return unsafeRedirect;
  }, [unsafeRedirect]);

  useEffect(() => {
    if (hasValidAuthToken()) {
      if (redirect != null) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        navigate({ to: redirect });
      } else {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        navigate({ to: "/dashboard" });
      }
    }
  }, []);
  const [step, setStep] = useState<"LOGIN" | "MFA">("LOGIN");
  const [mfaToken, setMFAToken] = useState<string>();

  function loginSuccess(token: string) {
    setMFAToken(token);
    setStep("MFA");
  }

  return (
    <div className="flex min-h-full flex-1 flex-col items-stretch justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <Logo words />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight ">
          Sign in to your account
        </h2>
      </div>
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        {step == "LOGIN" && <Login onSuccess={loginSuccess} />}
        {step == "MFA" && <MFA mfaToken={mfaToken!} redirect={redirect} />}
      </div>
    </div>
  );
}

function Login(props: { onSuccess: (token: string) => void }) {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  async function onSubmit(values: z.infer<typeof formSchema>) {
    const response = await apiClient.POST("/auth/login", {
      body: { email: values.email, password: values.password },
    });
    if (response.error != null) {
      toast.error(response.error.message);
    } else if (response.data != null) {
      props.onSuccess(response.data.mfaToken);
    }
  }
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input
                  autoFocus
                  type="email"
                  autoComplete="on"
                  placeholder="me@wirespeed.co"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Password</FormLabel>
              <FormControl>
                <Input type="password" placeholder="Password" {...field} />
              </FormControl>
              <Link
                className="text-xs text-muted-foreground hover:underline"
                to="/forgot"
              >
                Forgot Password
              </Link>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button disabled={form.formState.isSubmitting} type="submit">
          Login
        </Button>
      </form>
    </Form>
  );
}

function MFA(props: { mfaToken: string; redirect?: string }) {
  const [token, setToken] = useState<string>();
  const [submitting, setSubmitting] = useState(false);
  async function checkMFA() {
    if (token == null) {
      toast.error("Invalid MFA token");
      return;
    }
    setSubmitting(true);
    const result = await apiClient.POST("/auth/login/mfa", {
      body: { mfaToken: props.mfaToken, totpToken: token },
    });
    if (result.error != null || result.data == null) {
      toast.error("Invalid MFA token");
      setSubmitting(false);
      return;
    }
    setAPIToken(result.data?.accessToken);
    let user = getUser();
    if (result.data.needsOnboarding) {
      if (user.serviceProvider) {
        window.location.href = "/sp-onboarding/1";
      } else {
        window.location.href = "/user-onboarding/1";
      }
    } else if (props.redirect) {
      window.location.href = props.redirect;
    } else {
      window.location.href = "/dashboard";
    }
  }

  return (
    <div>
      <div className="flex items-center flex-col">
        <InputOTP
          containerClassName="flex items-center justify-center"
          onComplete={checkMFA}
          onChange={setToken}
          autoFocus
          maxLength={6}
        >
          <InputOTPGroup>
            <InputOTPSlot className="h-16 w-12" index={0} />
            <InputOTPSlot className="h-16 w-12" index={1} />
            <InputOTPSlot className="h-16 w-12" index={2} />
          </InputOTPGroup>
          <InputOTPSeparator />
          <InputOTPGroup>
            <InputOTPSlot className="h-16 w-12" index={3} />
            <InputOTPSlot className="h-16 w-12" index={4} />
            <InputOTPSlot className="h-16 w-12" index={5} />
          </InputOTPGroup>
        </InputOTP>
        <p className="mt-4 text-sm text-muted-foreground">
          Enter your MFA token
        </p>
        <Button
          size="lg"
          className="mt-10"
          disabled={submitting}
          onClick={() => checkMFA()}
        >
          Login
        </Button>
      </div>
    </div>
  );
}
