import { ConstrainedAppLayout } from "@/components/app-layout";
import { TableCard } from "@/components/table-card";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { apiClient } from "@/lib/api";
import { components } from "@/lib/api.types";
import { dateTime } from "@/lib/time";
import {
  keepPreviousData,
  queryOptions,
  useQuery,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useState } from "react";
import { toast } from "sonner";

export const Route = createFileRoute("/_application/admin/$queueName")({
  component: AdminQueue,
  loader: async ({ params, context }) => {
    await context.queryClient.ensureQueryData(getOptions(params.queueName));
  },
});

async function getJobs(
  queueName: string,
  opts?: components["schemas"]["QueueJobsPagination"]
) {
  const response = await apiClient.POST("/admin/queue/{name}/jobs", {
    params: { path: { name: queueName } },
    body: opts ?? {},
  });
  if (response.error != null) {
    throw new Error("Error getting jobs");
  }
  return response.data;
}

async function getData(queueName: string) {
  const [queue] = await Promise.all([
    apiClient.GET("/admin/queue/{name}", {
      params: { path: { name: queueName } },
    }),
  ]);
  if (queue.error != null) {
    throw new Error("Error getting queue information");
  }
  return {
    queue: queue.data,
  };
}

export const ADMIN_QUEUE_QUERY_KEY = "admin-queue";
const ADMIN_QUEUE_JOBS_QUERY_KEY = "admin-queue-jobs";
const getOptions = (queueName: string) =>
  queryOptions({
    queryKey: [ADMIN_QUEUE_QUERY_KEY, queueName],
    queryFn: () => getData(queueName),
    refetchInterval: 2500,
  });

function AdminQueue() {
  const { queueName } = Route.useParams();
  const navigate = useNavigate();
  const {
    data: { queue },
  } = useSuspenseQuery(getOptions(queueName));
  const [searchOpts, setSearchOpts] = useState<
    components["schemas"]["QueueJobsPagination"]
  >({ page: 1, size: 10, status: "completed" });

  const jobQuery = useQuery({
    queryKey: [ADMIN_QUEUE_JOBS_QUERY_KEY, queueName, searchOpts],
    queryFn: () => getJobs(queueName, searchOpts),
    placeholderData: keepPreviousData,
  });
  const queryClient = useQueryClient();

  async function deleteJobs(status: string) {
    const response = await apiClient.DELETE(
      "/admin/queue/{name}/status/{status}",
      {
        params: {
          path: {
            name: queueName,
            status,
          },
        },
      }
    );
    if (response.error != null) {
      toast.error("Error clearing failed jobs");
      return;
    } else {
      toast.warning("Jobs removed");
    }
    await queryClient.invalidateQueries({
      queryKey: [ADMIN_QUEUE_JOBS_QUERY_KEY, queueName],
    });
  }

  async function pauseQueue() {
    const response = await apiClient.PATCH("/admin/queue/{name}/pause", {
      params: { path: { name: queueName } },
    });
    if (response.error != null) {
      toast.error("Error pausing queue");
      return;
    }
    await queryClient.invalidateQueries({
      queryKey: [ADMIN_QUEUE_JOBS_QUERY_KEY, queueName],
    });
    toast.success("Queue paused");
  }

  async function resumeQueue() {
    const response = await apiClient.PATCH("/admin/queue/{name}/resume", {
      params: { path: { name: queueName } },
    });
    if (response.error != null) {
      toast.error("Error resuming queue");
      return;
    } else {
      toast.success("Queue resumed");
    }
    await queryClient.invalidateQueries({
      queryKey: [ADMIN_QUEUE_JOBS_QUERY_KEY, queueName],
    });
  }

  return (
    <ConstrainedAppLayout>
      <TableCard
        onClick={(row) =>
          navigate({
            to: "/admin/$queueName/$jobId",
            params: { queueName, jobId: row.id },
          })
        }
        query={jobQuery}
        compact
        onUpdate={(update) => setSearchOpts({ ...searchOpts, ...update })}
        headers={[
          { key: "state", display: "State" },
          { key: "id", display: "ID" },
          { key: "name", display: "Name" },
          {
            key: "processedOn",
            display: "Processed On",
            format: (val) => dateTime(val),
          },
          {
            key: "data",
            display: "Data",
            format: (val) => JSON.stringify(val),
          },
          {
            key: "returnValue",
            display: "Return Value",
            format: (val) => JSON.stringify(val),
          },
        ]}
      >
        <CardHeader>
          <div className="flex flex-col gap-2">
            <div className="flex flex-col gap-2 lg:flex-row lg:justify-between">
              <div className="flex flex-col gap-1">
                <CardTitle>{queue.display}</CardTitle>
                <CardDescription>
                  Statistics and job information for the {queue.display} queue.
                  Select a status to filter the table.
                </CardDescription>
              </div>
              <div className="flex gap-2">
                {queue.paused ? (
                  <Button onClick={() => resumeQueue()} variant="outline">
                    Resume Queue
                  </Button>
                ) : (
                  <Button onClick={() => pauseQueue()} variant="outline">
                    Pause Queue
                  </Button>
                )}
                <Dialog>
                  <DialogTrigger asChild>
                    <Button variant="outlineDestructive">
                      Delete all {searchOpts.status} jobs
                    </Button>
                  </DialogTrigger>
                  <DialogContent>
                    <DialogHeader>
                      <DialogTitle>Are you sure?</DialogTitle>
                      <DialogDescription>
                        This will remove all data related to the{" "}
                        {searchOpts.status} jobs permanently!
                      </DialogDescription>
                    </DialogHeader>
                    <DialogFooter className="justify-end flex gap-2">
                      <DialogClose asChild>
                        <Button variant="outline">No</Button>
                      </DialogClose>
                      <DialogClose asChild>
                        <Button
                          variant="destructive"
                          onClick={() =>
                            deleteJobs(searchOpts.status ?? "failed")
                          }
                        >
                          Yes
                        </Button>
                      </DialogClose>
                    </DialogFooter>
                  </DialogContent>
                </Dialog>
              </div>
            </div>
            <div className="flex gap-2">
              {Object.entries(queue.statuses).map(([name, status]: any) => (
                <Badge
                  className="cursor-pointer"
                  onClick={() =>
                    setSearchOpts({ ...searchOpts, page: 1, status: name })
                  }
                  variant={searchOpts.status == name ? "default" : "outline"}
                >
                  {name}
                </Badge>
              ))}
            </div>
          </div>
        </CardHeader>
      </TableCard>
    </ConstrainedAppLayout>
  );
}
