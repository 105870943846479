import Navigation, { SERVICE_PROVIDER_PATH } from "@/components/navigation";
import { Skeleton } from "@/components/ui/skeleton";
import { hasValidAuthToken } from "@/lib/api";
import { update } from "@intercom/messenger-js-sdk";
import {
  Outlet,
  SearchSchemaInput,
  createFileRoute,
  redirect,
} from "@tanstack/react-router";
import { Suspense, useEffect } from "react";

export const Route = createFileRoute("/_application")({
  component: LayoutComponent,
  validateSearch: (
    search: {
      switchTeamId?: string;
    } & SearchSchemaInput
  ) => {
    return {
      switchTeamId: search.switchTeamId,
    };
  },
  beforeLoad: ({ location, search, context }) => {
    if (search.switchTeamId != null) {
      const searchParams = new URLSearchParams(location.searchStr);
      searchParams.delete("switchTeamId");
      if (context.user != null && context.user.teamId != search.switchTeamId) {
        throw redirect({
          to: "/switch",
          search: {
            from: context.user.teamId,
            to: search.switchTeamId,
            redirect: location.pathname + "?" + searchParams.toString(),
          },
        });
      } else {
        // Remove the query param from the URL
        throw redirect({
          to: ".",
          search: { ...location.search, switchTeamId: undefined },
        });
      }
    }
    if (!hasValidAuthToken()) {
      throw redirect({
        to: "/login",
        search: { redirect: location.href + location.searchStr },
      });
    }
    if (location.pathname.startsWith("/switch")) {
      return;
    }
    if (context.user.superAdmin && location.pathname.startsWith("/admin")) {
      return;
    }
    if (
      context.team.serviceProvider &&
      !location.pathname.startsWith(SERVICE_PROVIDER_PATH)
    ) {
      throw redirect({
        to: SERVICE_PROVIDER_PATH + location.pathname,
      });
    }
    if (
      !context.team.serviceProvider &&
      location.pathname.startsWith(SERVICE_PROVIDER_PATH)
    ) {
      throw redirect({
        to: location.pathname.replace(SERVICE_PROVIDER_PATH, ""),
      });
    }
  },
});

function LayoutComponent() {
  useEffect(() => {
    update({ hide_default_launcher: false });
  }, []);
  return (
    <div className="dark:bg-background bg-muted/40 min-h-screen flex flex-col">
      <Navigation />
      <Suspense
        fallback={
          <div className="flex-1 w-full h-full p-4 flex flex-col items-center justify-center">
            <Skeleton className="w-full flex-1 rounded-md" />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </div>
  );
}
