"use client";

import { ROLE } from "@wire/shared";
import createClient, { Middleware } from "openapi-fetch";
import { validate as isUUID } from "uuid";
import type { components, paths } from "./api.types.ts"; // generated by openapi-typescript
let impersonating = false;
let accessToken: string | undefined | null;

/**
 * When the user logs in, we persist their access token in localStorage.
 * If, after authentication, they switch teams, we persist the updated access token in session storage. This way, multiple tabs can be open and have different sessions.
 * We fall back to localStorage if session storage isn't available.
 */
function init() {
  if (typeof localStorage != "undefined") {
    let localToken = localStorage.getItem("AUTH_TOKEN");
    let impersonatedToken = localStorage.getItem("IMPERSONATED_AUTH_TOKEN");
    impersonating = impersonatedToken != null;
    if (localToken != null || impersonatedToken != null) {
      accessToken = impersonatedToken ?? localToken;
    }
  }
  if (typeof sessionStorage != "undefined") {
    let impersonatedToken = sessionStorage.getItem("IMPERSONATED_AUTH_TOKEN");
    let sessionToken = sessionStorage.getItem("AUTH_TOKEN");
    impersonating = impersonating || impersonatedToken != null;
    if (sessionToken != null || impersonatedToken != null) {
      accessToken = impersonatedToken ?? sessionToken;
    }
  }

  if (typeof window != "undefined") {
    window.removeEventListener("focus", handleTabFocusForAuth);
    window.addEventListener("focus", handleTabFocusForAuth);
  }
}

init();
/**
 * When a user is switching between multiple teams, we store the update tokens in session storage
 *   so they can have multiple browsers open (and refresh the page) without the token reverting back to whatever
 *   it originally was.
 *
 * However, if they click on a link that opens in a new tab, that new tab grabs session state from local storage, which
 *  may not be the same as the session storage of the opening tab.
 *
 * This makes sure that when a user is in this tab and clicks a link, the session storage for the opened tab
 * matches this link. There's likely some race conditions here, but that's accepted for now.
 *
 */
function handleTabFocusForAuth() {
  if (impersonating) {
    let local = localStorage.getItem("IMPERSONATED_AUTH_TOKEN");
    let session = sessionStorage.getItem("IMPERSONATED_AUTH_TOKEN");
    if (local != session && session != null) {
      localStorage.setItem("IMPERSONATED_AUTH_TOKEN", session);
    }
  } else {
    let local = localStorage.getItem("AUTH_TOKEN");
    let session = sessionStorage.getItem("AUTH_TOKEN");
    if (local != session && session != null) {
      localStorage.setItem("AUTH_TOKEN", session);
    }
  }
}

const authMiddleware: Middleware = {
  onRequest(req) {
    if (accessToken != null) {
      req.headers.set("Authorization", `Bearer ${accessToken}`);
    }
    return req;
  },
};

export function getUUIDFromPath() {
  return location.pathname.split("/").find(isUUID);
}

export async function getProperTeamForUUID(
  uuid: string
): Promise<string | undefined> {
  const response = await fetch(
    `${import.meta.env.VITE_API_URL}/team/proper-team/${uuid}`,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );
  if (response.ok) {
    const body: components["schemas"]["TeamId"] = await response.json();
    return body.teamId;
  }
  return;
}
const unauthorizedMiddleware: Middleware = {
  async onResponse(res: Response) {
    const user = getUser();
    let statusCode = res.status;
    if ((statusCode == 401 || statusCode == 404) && user != null) {
      let uuid = location.pathname.split("/").find(isUUID);
      // Technically there could be multiple UUIDs, but it's unlikely they'd be from separate teams
      if (uuid != null) {
        const teamId = await getProperTeamForUUID(uuid);
        if (teamId != null) {
          if (teamId != user.teamId) {
            window.location.href = `/switch?from=${user.teamId}&to=${teamId}&redirect=${encodeURIComponent(window.location.pathname)}`;
          }
          return;
        }
      }
      if (statusCode == 401 && window.location.pathname != "/") {
        window.location.href = "/";
      }
    } else if (statusCode == 409) {
      throw new Error((await res.json()).message);
    }

    return res;
  },
};

export const apiClient = createClient<paths>({
  baseUrl: import.meta.env.VITE_API_URL,
});

apiClient.use(authMiddleware);
apiClient.use(unauthorizedMiddleware);

export function setAPIToken(value?: string) {
  if (value == null) {
    localStorage.removeItem("AUTH_TOKEN");
    sessionStorage.removeItem("AUTH_TOKEN");
  } else {
    localStorage.setItem("AUTH_TOKEN", value);
    sessionStorage.setItem("AUTH_TOKEN", value);
  }
  accessToken = value;
  apiClient.eject(authMiddleware);
  apiClient.use(authMiddleware);
}

export function isImpersonating() {
  return impersonating;
}

export function setImpersonatedAPIToken(value?: string) {
  if (value == null) {
    localStorage.removeItem("IMPERSONATED_AUTH_TOKEN");
    sessionStorage.removeItem("IMPERSONATED_AUTH_TOKEN");
  } else {
    localStorage.setItem("IMPERSONATED_AUTH_TOKEN", value);
    sessionStorage.setItem("IMPERSONATED_AUTH_TOKEN", value);
  }
  accessToken = value;
  apiClient.eject(authMiddleware);
  apiClient.use(authMiddleware);
}

function getParsedJWT() {
  if (accessToken == null) return;
  try {
    const data = JSON.parse(atob(accessToken.split(".")[1]));
    if (data == null) return;
    return data;
  } catch (err: any) {
    return;
  }
}

export function hasValidAuthToken() {
  const data = getParsedJWT();
  if (data == null) return false;
  const expiration = new Date(parseInt(data.exp) * 1000);
  if (expiration < new Date()) {
    return false;
  }
  return true;
}

export function logout(redirect = true) {
  setImpersonatedAPIToken();
  setAPIToken();
  if (redirect) {
    window.location.pathname = "/";
  }
}

export function getUser(): User {
  const data: User = getParsedJWT();
  return data;
}

export async function getTeam(): Promise<
  components["schemas"]["Team"] | undefined
> {
  if (accessToken == null) {
    return;
  }
  const response = await apiClient.GET("/team");
  return response.data;
}

export interface User {
  email: string;
  id: string;
  role: ROLE;
  teamId: string;
  parentTeamId: string;
  superAdmin?: boolean;
  serviceProvider?: boolean;
  completedOnboarding?: boolean;
}

export async function getTeamToken(teamId: string) {
  const response = await apiClient.POST("/auth/team", { body: { teamId } });
  if (response.error != null) {
    alert("Error switching teams");
    return;
  }
  return response.data.accessToken;
}

export async function switchTeam(teamId: string, redirect?: string) {
  const token = await getTeamToken(teamId);
  if (token == null) {
    return;
  }
  switchTeamWithToken(token, redirect);
}

export function switchTeamWithToken(token: string, redirect?: string) {
  if (impersonating) {
    setImpersonatedAPIToken(token);
  } else {
    setAPIToken(token);
  }
  let path = redirect ?? "/dashboard";

  // If there aren't any UUIDs in the path, try keeping them on the current page
  if (location.pathname.split("/").find(isUUID) == null && redirect == null) {
    path = location.pathname;
  }
  window.location.href = path;
  return;
}

/**
 * Formats our search queries for the API. We let the user use double quotes for exact queries
 * but the API doesn't require them so we can strip them. If the user isn't using double quotes
 * we add wildcards to their search if they haven't already added them.
 * @param search
 * @returns
 */
export function formatSearchQuery(search?: string) {
  if (search && search.startsWith('"') && search?.endsWith('"')) {
    search = search.replaceAll('"', "");
  } else if (search != null) {
    if (!search.startsWith("*")) {
      search = `*${search}`;
    }
    if (!search.endsWith("*")) {
      search = `${search}*`;
    }
  }
  return search;
}
