import { MatchEdgeProps } from "@/components/flow/editor/edge/match-edge-editor";
import { MatchNodeProps } from "@/components/flow/editor/node/match-node-editor";
import {
  BaseCustomNode,
  BaseCustomNodeComponentProps,
} from "@/components/flow/node/base-custom-node";
import { FlowEdgeType, FlowNodeType, validateQuery } from "@wire/shared";
import { Edge, Node, NodeProps, useEdges } from "@xyflow/react";
import { memo, useMemo } from "react";

export interface MatchNodeCompProps extends BaseCustomNodeComponentProps {}

function MatchNode(
  props: NodeProps<Node<MatchNodeProps>> & MatchNodeCompProps
) {
  const edges = useEdges();

  const childEdges: Edge<MatchEdgeProps>[] = useMemo(
    () =>
      edges.filter((e) => e.source == props.id && e.type == FlowEdgeType.MATCH),
    [edges, props.id]
  );

  const warnings = useMemo(() => {
    let out: string[] = [];
    if (childEdges.length < 2) {
      out.push("Both match edges required");
    } else if (childEdges[0].data?.branch == childEdges[1].data?.branch) {
      out.push("Both match edges must have different branches");
    }
    if (props.data.query != null) {
      if (!validateQuery(props.data.query)) {
        out.push("Invalid query");
      }
    }

    return out;
  }, [childEdges, props.data.query]);
  return (
    <BaseCustomNode
      {...props}
      isConnectableStart={childEdges.length < 2}
      warnings={warnings}
      data={{ ...props.data, type: FlowNodeType.MATCH }}
    ></BaseCustomNode>
  );
}

let MatchNodeMemo = memo(MatchNode);

export { MatchNodeMemo as MatchNode };
