import { ActionEdge } from "@/components/flow/edge/action-edge";
import {
  BaseCustomEdgeComponentProps,
  BaseCustomEdgeDataProps,
  getDefaultEdgePropsByType,
} from "@/components/flow/edge/base-custom-edge";
import { JumpEdge, JumpEdgeProps } from "@/components/flow/edge/jump-edge";
import { MatchEdge } from "@/components/flow/edge/match-edge";
import { SwitchEdge } from "@/components/flow/edge/switch-edge";
import { ActionEdgeProps } from "@/components/flow/editor/edge/action-edge-editor";
import { MatchEdgeProps } from "@/components/flow/editor/edge/match-edge-editor";
import { SwitchEdgeProps } from "@/components/flow/editor/edge/switch-edge-editor";
import { EditorPanel } from "@/components/flow/editor/editor";
import { ActionNodeProps } from "@/components/flow/editor/node/action-node-editor";
import { AnnotationNodeProps } from "@/components/flow/editor/node/annotation-node-editor";
import { JumpNodeProps } from "@/components/flow/editor/node/jump-node-editor";
import { MatchNodeProps } from "@/components/flow/editor/node/match-node-editor";
import { SwitchNodeProps } from "@/components/flow/editor/node/switch-node-editor";
import { ActionNode } from "@/components/flow/node/action-node";
import { AnnotationNode } from "@/components/flow/node/annotation-node";
import {
  BaseCustomNodeComponentProps,
  BaseCustomNodeDataProps,
  getDefaultNodePropsByType,
} from "@/components/flow/node/base-custom-node";
import { JumpNode } from "@/components/flow/node/jump-node";
import { MatchNode } from "@/components/flow/node/match-node";
import { SwitchNode } from "@/components/flow/node/switch-node";
import useLocalStorageState from "@/hooks/use-local-storage-state";
import { components } from "@/lib/api.types";
import { safeJSONParse, useDebounce } from "@/lib/utils";
import { SquareIcon } from "@radix-ui/react-icons";
import {
  FlowEdgeType,
  FlowNodeType,
  FlowVerdictorHistory,
  Verdict,
} from "@wire/shared";
import {
  addEdge,
  Background,
  Connection,
  ControlButton,
  Controls,
  Edge,
  EdgeProps,
  FinalConnectionState,
  MarkerType,
  Node,
  NodeProps,
  ReactFlow,
  ReactFlowInstance,
  useEdgesState,
  useNodesState,
  useOnSelectionChange,
  useReactFlow,
  Viewport,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import { useTheme } from "next-themes";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { toast } from "sonner";
import { v4 as uuid } from "uuid";
export const ENTRY_NODE_BACKGROUND_COLOR = "#bbf7d0";
export const ENTRY_NODE_BORDER_COLOR = "#22c55e";
const initialNodes: Node[] = [
  {
    id: "1",
    type: "switch",
    position: { x: 0, y: 0 },

    data: {
      ...getDefaultNodePropsByType(FlowNodeType.SWITCH),
      backgroundColor: ENTRY_NODE_BACKGROUND_COLOR,
      borderColor: ENTRY_NODE_BORDER_COLOR,
      title: "Detection Received!",
      entryNode: true,
    } satisfies BaseCustomNodeDataProps,
  },
];

interface FlowBuilderProps {
  defaultFlow?: string;
  readOnly?: boolean;
  blur?: boolean;
  selectedDetection?: components["schemas"]["Detection"];
  onSaveUpdate?: (saved: boolean) => void;
  simulationResult?: FlowVerdictorHistory[];
  goToProblemNodeId?: string;
}

interface FlowStoredState {
  nodes: Node[];
  edges: Edge[];
  viewport: Viewport;
}

type StoredStateHistory = {
  history: (FlowStoredState & { current: boolean })[];
};

export function getFlowFromLocalStorage() {
  const flow = localStorage.getItem("flow-state");
  if (flow == null) return null;
  return JSON.parse(flow);
}

export default function FlowBuilder({
  defaultFlow,
  readOnly,
  blur,
  selectedDetection: selectedDetectionProp,
  simulationResult,
  onSaveUpdate,
  goToProblemNodeId,
}: FlowBuilderProps) {
  const [simulationLoaded, setSimulationLoaded] = useState(false);
  const [storedState, setStoredState] = useLocalStorageState<FlowStoredState>(
    "flow-state",
    {
      defaultValue: safeJSONParse(defaultFlow) ?? {
        nodes: initialNodes,
        edges: [],
        viewport: { x: 0, y: 0, zoom: 1 },
      },
      readOnly,
    }
  );
  const [storedStateHistory, setStoredStateHistory] =
    useLocalStorageState<StoredStateHistory>("flow-state-history", {
      defaultValue: {
        history: [],
      },
      readOnly,
    });

  const { setViewport } = useReactFlow();
  const [selectedDetection, setSelectedDetection] = useState<
    components["schemas"]["Detection"] | undefined
  >(selectedDetectionProp);
  const [nodes, setNodes, onNodesChange] = useNodesState(storedState.nodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(storedState.edges);
  const debouncedStoredState = useDebounce(500, storedState);
  const [selectedNode, setSelectedNode] = useState<Node | undefined>();
  const [selectedEdge, setSelectedEdge] = useState<Edge | undefined>();
  const [skipHistoryUpdate, setSkipHistoryUpdate] = useState(false);
  const { screenToFlowPosition, fitView } = useReactFlow();

  useEffect(() => {
    setNodes((nodes) =>
      nodes.map((n) => ({ ...n, data: { ...n.data, blur } }))
    );
    setEdges((edges) =>
      edges.map((e) => ({ ...e, data: { ...e.data, blur } }))
    );
  }, [blur]);

  useEffect(() => {
    let status = true;
    // Check if all nodes and edges are in stored state and vice versa
    storedState.nodes.forEach((node) => {
      if (!nodes.some((n) => n.id === node.id)) {
        status = false;
      }
    });

    nodes.forEach((node) => {
      if (!storedState.nodes.some((n) => n.id === node.id)) {
        status = false;
      }
    });

    storedState.edges.forEach((edge) => {
      if (!edges.some((e) => e.id === edge.id)) {
        status = false;
      }
    });

    edges.forEach((edge) => {
      if (!storedState.edges.some((e) => e.id === edge.id)) {
        status = false;
      }
    });
    onSaveUpdate?.(status);
  }, [nodes, edges, storedState, onSaveUpdate]);

  useEffect(() => {
    setSelectedDetection(selectedDetectionProp);
  }, [selectedDetectionProp]);

  useEffect(() => {
    if (selectedDetection == null) return;
    if (selectedDetection.raw != null) {
      const updatedDetection = { ...selectedDetection, raw: null, logs: null };
      setSelectedDetection(updatedDetection as any);
    }
  }, [selectedDetection]);

  // Just to migrate old flows from using the built-in hidden property to the new one
  // TODO: If you're seeing this you can delete it
  useEffect(() => {
    setEdges((v) =>
      v.map((e) => {
        if (e.type == FlowEdgeType.JUMP && e.hidden) {
          return { ...e, hidden: false, data: { ...e.data, hidden: true } };
        }
        return e;
      })
    );
  }, []);

  useEffect(() => {
    if (debouncedStoredState.debounced == undefined) return;
    if (skipHistoryUpdate) {
      setSkipHistoryUpdate(false);
      return;
    }
    setStoredStateHistory((v: StoredStateHistory) => {
      if (debouncedStoredState.debounced == null) return v;
      let update = { current: true, ...debouncedStoredState.debounced };
      v.history = v.history.map((v) => ({ ...v, current: false }));
      return {
        ...v,
        history: [update, ...v.history.slice(0, 20)],
      } satisfies StoredStateHistory;
    });
  }, [debouncedStoredState.debounced]);

  const onChange = useCallback(
    ({ nodes, edges }: { nodes: Node[]; edges: Edge[] }) => {
      let node = nodes?.[0];
      let edge = edges?.[0];
      if (node != null) {
        node.selected = true;
      }
      if (edge != null) {
        edge.selected = true;
      }
      setSelectedNode(node);
      setSelectedEdge(edge);
    },
    []
  );

  useEffect(() => {
    if (defaultFlow) {
      try {
        const flowData: FlowStoredState = JSON.parse(defaultFlow);
        setStoredState(flowData);
        setNodes(flowData.nodes);
        setEdges(flowData.edges);
        void fitView({
          nodes: [flowData.nodes.find((v) => v.data.entryNode)!],
          maxZoom: 1,
        });
      } catch (error) {
        console.error("Failed to parse default flow:", error);
        alert(error);
      }
    }
  }, [defaultFlow]);

  useOnSelectionChange({
    onChange,
  });

  useEffect(() => {
    if (simulationResult != null) {
      setNodes((nodes: Node<BaseCustomNodeDataProps>[]) => {
        return nodes.map((n) => {
          let simulationNode = simulationResult.some((v) => v.nodeId == n.id);

          return {
            ...n,
            selected: false,
            animated: simulationNode,
            data: {
              ...n.data,
              simulating: simulationNode,
              simulationEndNode:
                simulationResult[simulationResult.length - 1].nodeId == n.id,
            },
          };
        });
      });
      setEdges((edges) => {
        return edges.map((e) => {
          let simulationEdge = simulationResult.some((v) => v.edgeId == e.id);
          return {
            ...e,
            selected: false,
            animated: simulationEdge,
            data: {
              ...e.data,
              simulating: simulationEdge,
            },
          };
        });
      });

      setSimulationLoaded(true);
    }
  }, [simulationResult, setNodes, setSimulationLoaded, setEdges]);

  useEffect(() => {
    if (simulationLoaded) {
      void fitView({
        nodes: [nodes.find((v) => v.data.simulationEndNode)!],
        duration: 500,
        maxZoom: 1,
      });
    }
  }, [simulationLoaded, fitView]);

  const [viewport, setViewportState] = useState(storedState.viewport);
  const [rfInstance, setRfInstance] = useState<ReactFlowInstance<
    any,
    any
  > | null>(null);
  const reactFlowWrapper = useRef(null);
  const theme = useTheme();

  const saveState = useCallback(() => {
    if (rfInstance != null) {
      let object = rfInstance.toObject();
      object.nodes = object.nodes.map(
        (n: NodeProps<Node<BaseCustomNodeDataProps>>) => {
          return {
            ...n,
            animated: false,
            data: {
              ...n.data,
              simulating: false,
              simulationInvalid: false,
              simulationEndNode: false,
            },
          };
        }
      );
      object.edges = object.edges.map(
        (e: EdgeProps<Edge<BaseCustomEdgeDataProps>>) => {
          return {
            ...e,
            animated: false,
            data: {
              ...e.data,
              simulating: false,
              simulationInvalid: false,
            },
          };
        }
      );
      setStoredState(object);
    }
  }, [rfInstance, setStoredState]);

  useEffect(() => {
    if (goToProblemNodeId != null) {
      setNodes((nds) =>
        nds.map((n: Node<BaseCustomNodeDataProps>) => {
          return {
            ...n,
            data: {
              ...n.data,
              simulationInvalid: n.id == goToProblemNodeId,
            },
          };
        })
      );
      void fitView({ nodes: [{ id: goToProblemNodeId }], duration: 500 });
    } else {
      setNodes((nds) =>
        nds.map((n: Node<BaseCustomNodeDataProps>) => {
          return {
            ...n,
            data: {
              ...n.data,
              simulationInvalid: false,
            },
          };
        })
      );
    }
  }, [goToProblemNodeId, fitView, setNodes]);

  useEffect(() => {
    let interval = setInterval(() => {
      saveState();
    }, 500);
    return () => clearInterval(interval);
  }, [saveState]);

  const multipleNodesSelected = useMemo(() => {
    return nodes.filter((n) => n.selected).length > 1;
  }, [nodes]);

  const multipleEdgesSelected = useMemo(() => {
    return edges.filter((e) => e.selected).length > 1;
  }, [edges]);

  useEffect(() => {
    if (nodes.length == 0) {
      setNodes(initialNodes);
    }
  }, [nodes]);

  useEffect(() => {
    const { x, y, zoom } = viewport;

    void setViewport({ x, y, zoom });
  }, []);

  const getId = useCallback(() => {
    return uuid();
  }, [nodes, edges]);

  // Try and guess the curve type based on the siblings
  const getEdgeCurveType = useCallback(
    (parentNodeId: string) => {
      const siblings = edges.filter((e) => e.source === parentNodeId);
      return (
        ((siblings?.[0]?.data as BaseCustomEdgeDataProps)
          ?.curveType as string) ?? "smoothstep"
      );
    },
    [edges]
  );

  const getDataForSwitchEdge = useCallback(
    (parent: Node, edgeProps: SwitchEdgeProps) => {
      edgeProps.curveType = getEdgeCurveType(parent.id) as any;
      return edgeProps;
    },
    [getEdgeCurveType]
  );

  const getDataForMatchEdge = useCallback(
    (parent: Node, edgeProps: MatchEdgeProps) => {
      let sibling: Edge<MatchEdgeProps> | undefined = edges.find(
        (e) => e.source == parent.id
      );
      if (sibling != null) {
        edgeProps.branch = !sibling.data?.branch;
      }
      edgeProps.curveType = getEdgeCurveType(parent.id) as any;
      return edgeProps;
    },
    [edges, getEdgeCurveType]
  );

  const getDataForActionEdge = useCallback(
    (parent: Node, edgeProps: ActionEdgeProps) => {
      let siblings: Edge<ActionEdgeProps>[] = edges.filter(
        (e) => e.source == parent.id
      );
      if (!siblings.some((v) => v.data?.verdict == Verdict.MALICIOUS)) {
        edgeProps.verdict = Verdict.MALICIOUS;
      } else if (!siblings.some((v) => v.data?.verdict == Verdict.BENIGN)) {
        edgeProps.verdict = Verdict.BENIGN;
      } else if (!siblings.some((v) => v.data?.verdict == Verdict.SUSPICIOUS)) {
        edgeProps.verdict = Verdict.SUSPICIOUS;
      }
      edgeProps.curveType = getEdgeCurveType(parent.id) as any;

      return edgeProps;
    },
    [edges, getEdgeCurveType]
  );

  const onConnect = useCallback(
    (params: Connection) => {
      let parentNode = nodes.find((n) => n.id == params.source);
      let edgeData =
        getDefaultEdgePropsByType(parentNode?.type as FlowEdgeType) ?? {};
      if (parentNode?.type == FlowEdgeType.MATCH) {
        edgeData = getDataForMatchEdge(parentNode, edgeData);
      } else if (parentNode?.type == FlowEdgeType.ACTION) {
        edgeData = getDataForActionEdge(parentNode, edgeData);
      } else if (parentNode?.type == FlowEdgeType.SWITCH) {
        edgeData = getDataForSwitchEdge(parentNode, edgeData);
      }

      setEdges((eds) =>
        eds.concat({
          ...params,
          id: getId(),
          type: parentNode?.type,
          data: edgeData,
        })
      );
    },
    [
      getId,
      nodes,
      edges,
      setEdges,
      addEdge,
      getDataForMatchEdge,
      getDataForActionEdge,
      getDataForSwitchEdge,
      getDefaultEdgePropsByType,
    ]
  );

  const onConnectEnd = useCallback(
    (event: MouseEvent | TouchEvent, connectionState: FinalConnectionState) => {
      if (connectionState.isValid) return;
      // Connecting two existing nodes
      const defaultData = getDefaultNodePropsByType(
        connectionState.fromNode!.type as any
      );
      // User is connecting to nothing, which is a shortcut for creating new nodes
      const id = getId();
      const { clientX, clientY } =
        "changedTouches" in event ? event.changedTouches[0] : event;
      const newNode: Node = {
        id,
        position: screenToFlowPosition({
          x: clientX ?? 0,
          y: clientY ?? 500,
        }),
        type: "switch",
        data: defaultData,
        origin: [0.5, 0.0],
      };

      setNodes((nds) => nds.concat(newNode));
      let edgeType: FlowEdgeType = connectionState.fromNode!
        .type as FlowEdgeType;
      let data: Record<string, any> = defaultData;
      if (edgeType == FlowEdgeType.MATCH) {
        data = getDataForMatchEdge(connectionState.fromNode!, data);
      } else if (edgeType == FlowEdgeType.ACTION) {
        data = getDataForActionEdge(connectionState.fromNode!, data);
      } else if (edgeType == FlowEdgeType.SWITCH) {
        data = getDataForSwitchEdge(connectionState.fromNode!, data);
      }
      setEdges((eds) =>
        eds.concat({
          id,
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 32,
            height: 32,
          },
          type: connectionState.fromNode!.type,
          source: connectionState.fromNode!.id,
          target: id,
          data: {
            ...getDefaultEdgePropsByType(edgeType),
            ...data,
          },
        })
      );
      return;
    },
    [screenToFlowPosition, nodes, setNodes, getDataForMatchEdge, getId]
  );

  const createNode = useCallback(
    (node: Partial<Node>) => {
      let newNode: Node = {
        id: getId(),
        position: { x: 0, y: 0 },
        ...node,
        data: {
          ...getDefaultNodePropsByType(node.type as FlowNodeType),
          ...node.data,
        },
      };
      setNodes((nds) => nds.concat(newNode));
    },
    [getId, setNodes]
  );
  [setNodes];

  const createEdge = useCallback(
    (edge: Partial<Edge>) => {
      let newEdge: Edge = {
        id: getId(),
        markerEnd: {
          type: MarkerType.ArrowClosed,
          width: 32,
          height: 32,
        },
        source: edge.source!,
        target: edge.target!,
        data: {
          ...getDefaultEdgePropsByType(edge.type as FlowEdgeType),
          ...edge.data,
        },
        ...edge,
      };
      setEdges((eds) => eds.concat(newEdge));
    },
    [setEdges, getId]
  );

  // https://reactflow.dev/examples/nodes/update-node
  const updateNode = useCallback(
    (id: string, updatedNode: Partial<Node>) => {
      let latestNode: Node | undefined;
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id == id) {
            latestNode = node;
            // it's important that you create a new node object
            // in order to notify react flow about the change
            return {
              ...node,
              ...updatedNode,
              data: {
                ...node.data,
                ...updatedNode.data,
              },
            };
          }

          return node;
        })
      );
      if (latestNode == null || selectedNode == null) return;
      setSelectedNode((selectedNode: any) => ({
        ...selectedNode,
        ...updatedNode,
        data: {
          ...selectedNode?.data,
          ...updatedNode.data,
        },
      }));
    },
    [selectedNode, setNodes, setSelectedNode]
  );

  const nodeTypes = useMemo(() => {
    let compProps: BaseCustomNodeComponentProps = {
      updateNode,
    };
    return {
      match: (props: NodeProps<Node<MatchNodeProps>>) => (
        <MatchNode {...props} {...compProps} />
      ),
      switch: (props: NodeProps<Node<SwitchNodeProps>>) => (
        <SwitchNode {...props} {...compProps} />
      ),
      action: (props: NodeProps<Node<ActionNodeProps>>) => (
        <ActionNode {...props} {...compProps} />
      ),
      jump: (props: NodeProps<Node<JumpNodeProps>>) => (
        <JumpNode {...props} {...compProps} />
      ),
      annotation: (props: NodeProps<Node<AnnotationNodeProps>>) => (
        <AnnotationNode {...props} data={props.data as any} />
      ),
    };
  }, []);

  const updateEdge = useCallback(
    (id: string, updatedEdge: Partial<Edge>) => {
      let matchedEdge: Edge | undefined;
      setEdges((eds) =>
        eds.map((edge) => {
          if (edge.id === id) {
            matchedEdge = edge;
            return {
              ...edge,
              ...updatedEdge,
              data: {
                ...edge.data,
                ...updatedEdge.data,
              },
            };
          }
          return edge;
        })
      );
      if (matchedEdge == null || selectedEdge == null) return;
      setSelectedEdge({
        ...matchedEdge,
        ...updatedEdge,
        data: {
          ...matchedEdge.data,
          ...updatedEdge.data,
        },
      });
    },
    [selectedEdge]
  );
  const edgeTypes = useMemo(() => {
    let compProps: BaseCustomEdgeComponentProps = {
      updateEdge,
    };
    return {
      match: (props: EdgeProps<Edge<MatchEdgeProps>>) => (
        <MatchEdge {...props} {...compProps} />
      ),
      switch: (props: EdgeProps<Edge<SwitchEdgeProps>>) => (
        <SwitchEdge {...props} {...compProps} />
      ),
      jump: (props: EdgeProps<Edge<JumpEdgeProps>>) => (
        <JumpEdge {...props} {...compProps} />
      ),
      action: (props: EdgeProps<Edge<ActionEdgeProps>>) => (
        <ActionEdge {...props} {...compProps} />
      ),
    };
  }, []);

  const shouldShowEditorPanel = useMemo(() => {
    let validEdgesOrNodes =
      (selectedNode != null && !multipleNodesSelected) ||
      (selectedEdge != null && !multipleEdgesSelected);
    let notBothSelected = selectedNode == null || selectedEdge == null;
    return validEdgesOrNodes && notBothSelected;
  }, [
    selectedNode,
    selectedEdge,
    multipleNodesSelected,
    multipleEdgesSelected,
  ]);

  const onEdgeDelete = useCallback(
    (id: string) => {
      setEdges((eds) => eds.filter((e) => e.id !== id));
      let edge = edges.find((v) => v.id == id);
      if (edge != null && edge.type == "jump") {
        updateNode(edge.source, { data: { jumpToNodeId: null } });
      }
    },
    [setEdges, updateNode, edges]
  );

  const onClose = useCallback(() => {
    if (selectedNode != null) {
      updateNode(selectedNode.id, { selected: false });
    }
    if (selectedEdge != null) {
      updateEdge(selectedEdge.id, { selected: false });
    }
  }, [selectedNode, selectedEdge, updateNode, updateEdge]);

  const onAddNodeClick = useCallback(
    (type?: FlowNodeType, data?: Partial<Node>) => {
      setNodes((nds) =>
        nds.concat({
          id: getId(),
          position: screenToFlowPosition({
            x: 500,
            y: 500,
          }),
          type: type ?? FlowNodeType.MATCH,
          data: getDefaultNodePropsByType(type ?? FlowNodeType.MATCH),
          ...data,
        })
      );
      toast.success("Node added");
    },
    [getId, setNodes]
  );

  const onNodeDelete = useCallback(
    (id: string) => {
      setNodes((nds) => nds.filter((n) => n.id !== id));
    },
    [setNodes, onAddNodeClick]
  );
  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if ((e.ctrlKey || e.metaKey) && e.key == "z" && !e.shiftKey) {
        setSkipHistoryUpdate(true);
        let historyObject = storedStateHistory.history[0];
        if (historyObject != null) {
          setStoredState(historyObject);
          setNodes(historyObject.nodes);
          setEdges(historyObject.edges);
          setSelectedNode(historyObject.nodes.find((v) => v.selected));
          setSelectedEdge(historyObject.edges.find((v) => v.selected));
          void setViewport(historyObject.viewport);
        }
        setStoredStateHistory((v) => {
          let updatedHistory = v.history.slice(1);
          if (v.history[0].current) {
            updatedHistory = updatedHistory.slice(1);
          }
          return {
            history: updatedHistory,
          };
        });
      }
    },
    [
      storedStateHistory,
      storedState,
      setNodes,
      setEdges,
      setSelectedNode,
      setSelectedEdge,
      setViewport,
      setStoredState,
      setStoredStateHistory,
    ]
  );

  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);
    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, [onKeyDown]);

  return (
    <div className="wrapper h-full w-full" ref={reactFlowWrapper}>
      <ReactFlow
        nodes={nodes}
        colorMode={theme.theme as any}
        edges={edges}
        onConnectEnd={onConnectEnd}
        onNodesChange={onNodesChange}
        zoomOnScroll={false}
        snapToGrid
        panOnScroll={true}
        nodesDraggable={!readOnly}
        nodesConnectable={!readOnly}
        edgesReconnectable={!readOnly}
        onNodesDelete={(nodes) => nodes.forEach((v) => onNodeDelete(v.id))}
        onEdgesDelete={(edges) => edges.forEach((v) => onEdgeDelete(v.id))}
        onInit={setRfInstance}
        onViewportChange={setViewportState}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        elevateNodesOnSelect
        elevateEdgesOnSelect
        minZoom={0.1}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
      >
        <Controls>
          <ControlButton title="add node" onClick={() => onAddNodeClick()}>
            <SquareIcon />
          </ControlButton>
          <ControlButton
            title="add annotation"
            onClick={() => onAddNodeClick(FlowNodeType.ANNOTATION)}
          >
            <span className="-rotate-90">⤹</span>
          </ControlButton>
        </Controls>
        <Background />
        <EditorPanel
          onClose={onClose}
          show={shouldShowEditorPanel}
          selectedDetection={selectedDetection}
          onSelectedDetection={setSelectedDetection}
          onNodeDelete={onNodeDelete}
          onEdgeDelete={onEdgeDelete}
          onNodeUpdate={updateNode}
          onNodeCreate={createNode}
          onEdgeUpdate={updateEdge}
          onEdgeCreate={createEdge}
          selectedNode={selectedNode}
          selectedEdge={selectedEdge}
        />
      </ReactFlow>
    </div>
  );
}
