import {
  ENTRY_NODE_BACKGROUND_COLOR,
  ENTRY_NODE_BORDER_COLOR,
} from "@/components/flow/flow-builder";
import {
  BaseCustomNodeDataProps,
  getDefaultNodePropsByType,
} from "@/components/flow/node/base-custom-node";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Switch } from "@/components/ui/switch";
import { FlowNodeType } from "@wire/shared";
import { Node, useEdges, useNodes, useReactFlow } from "@xyflow/react";
import { memo, useMemo } from "react";

function NodeEditorFooter({
  onNodeUpdate,
  selectedNode,
  updateNodeById,
}: {
  onNodeUpdate: (node: Partial<Node>) => void;
  selectedNode: Node<BaseCustomNodeDataProps> | undefined;
  updateNodeById: (id: string, node: Partial<Node>) => void;
}) {
  if (selectedNode == null) return;
  const { fitView } = useReactFlow();
  const edges = useEdges();
  const nodes = useNodes();
  const nodeHasOutgoingEdges = useMemo(() => {
    return edges.filter((e) => e.source == selectedNode?.id).length > 0;
  }, [edges, selectedNode]);

  const showSetAsEntryNodeButton = useMemo(() => {
    let noEntryNodes = nodes.find((n) => n.data.entryNode) == null;
    let noInboundNodes =
      edges.filter((e) => e.target == selectedNode?.id).length == 0;
    return (
      noEntryNodes && selectedNode.type == FlowNodeType.MATCH && noInboundNodes
    );
  }, [nodes, edges, selectedNode]);

  const nodeHasInboundEdges = useMemo(() => {
    return edges.filter((e) => e.target == selectedNode?.id).length > 0;
  }, [edges, selectedNode]);
  const showInboundSwitch = useMemo(() => {
    return selectedNode.type != FlowNodeType.ANNOTATION;
  }, [selectedNode]);

  const showOutboundSwitch = useMemo(() => {
    return (
      selectedNode.type != FlowNodeType.ACTION &&
      selectedNode.type != FlowNodeType.ANNOTATION
    );
  }, [selectedNode]);

  const nodesJumpingHere = useMemo(() => {
    return nodes.filter((n) => n.data.jumpToNodeId == selectedNode?.id);
  }, [nodes, selectedNode]);
  return (
    <div className="flex flex-row gap-4">
      <div className="flex flex-col gap-2">
        <Label className="text-xs">Node Type</Label>
        <Select
          disabled={nodeHasOutgoingEdges}
          onValueChange={(v) =>
            onNodeUpdate({
              type: v as any,
              data: getDefaultNodePropsByType(selectedNode.type as any),
            })
          }
        >
          <SelectTrigger>
            <SelectValue
              defaultValue={selectedNode.type}
              placeholder={selectedNode.type}
            ></SelectValue>
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={FlowNodeType.MATCH}>match</SelectItem>
            <SelectItem value={FlowNodeType.SWITCH}>switch</SelectItem>
            <SelectItem value={FlowNodeType.ACTION}>action</SelectItem>
            <SelectItem value={FlowNodeType.JUMP}>jump</SelectItem>
            <SelectItem value={FlowNodeType.ANNOTATION}>annotation</SelectItem>
          </SelectContent>
        </Select>
      </div>
      {showInboundSwitch && (
        <div className="flex flex-col gap-2">
          <Label className="text-xs">Inbound</Label>
          <Switch
            disabled={nodeHasInboundEdges}
            checked={!selectedNode.data.noStartHandle}
            onCheckedChange={(v) =>
              onNodeUpdate({ data: { noStartHandle: !v } })
            }
          />
        </div>
      )}
      {showOutboundSwitch && (
        <div className="flex flex-col gap-2">
          <Label className="text-xs">Outbound</Label>
          <Switch
            disabled={nodeHasOutgoingEdges}
            checked={!selectedNode.data.noEndHandle}
            onCheckedChange={(v) => onNodeUpdate({ data: { noEndHandle: !v } })}
          />
        </div>
      )}
      {nodesJumpingHere.length > 0 && (
        <div className="flex flex-col gap-2">
          <Label className="text-xs">Jumps Here</Label>
          <div className="text-xs">
            {nodesJumpingHere.map((n) => (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  void fitView({ nodes: [n], duration: 500, maxZoom: 1 });
                  updateNodeById(selectedNode.id, { selected: false });
                  updateNodeById(n.id, { selected: true });
                }}
                key={n.id}
                className="hover:underline cursor-pointer "
              >
                {n.id}
              </div>
            ))}
          </div>
        </div>
      )}
      {showSetAsEntryNodeButton && (
        <div className="flex flex-col gap-2">
          <Label className="text-xs">Entry Node</Label>
          <Button
            variant="outline"
            className="text-xs"
            onClick={() =>
              onNodeUpdate({
                data: {
                  entryNode: true,
                  backgroundColor: ENTRY_NODE_BACKGROUND_COLOR,
                  borderColor: ENTRY_NODE_BORDER_COLOR,
                },
              })
            }
          >
            Set as Entry Node
          </Button>
        </div>
      )}
    </div>
  );
}

let NodeEditorFooterMemo = memo(NodeEditorFooter);

export { NodeEditorFooterMemo as NodeEditorFooter };
