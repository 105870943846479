import {
  FileRiskType,
  FileRiskTypeConfig,
  getVerdictConfigByVerdict,
  type Verdict,
} from "./shared.types";

/*
 * READ THIS
 * When adding new categories, make sure to also add a matching subcategory, which is what we always default to if we don't match a more specific subcategory
 * Display values should be able to be combined in the sentence form "X alerted on a [verdict] [subcategory] [category]"
 */
export enum NewDetectionCategory {
  EXECUTION = "EXECUTION",
  LOGIN = "LOGIN",
  PERSISTENCE = "PERSISTENCE",
  DATA_TRANSFER = "DATA_TRANSFER",
  NETWORK_CONNECTION = "NETWORK_CONNECTION",
  CREDENTIAL_EXPOSURE = "CREDENTIAL_EXPOSURE",
  DECEPTION = "DECEPTION",
  EMAIL = "EMAIL",
  DIAGNOSTIC = "DIAGNOSTIC",
  NON_THREAT = "NON_THREAT",
  WARNING = "WARNING",
  UNKNOWN = "UNKNOWN",
}

export enum NewDetectionSubCategory {
  // Defaults
  UNKNOWN = "UNKNOWN",
  DIAGNOSTIC = "DIAGNOSTIC",
  WARNING = "WARNING",
  NON_THREAT = "NON_THREAT",

  // Execution
  EXECUTION = "EXECUTION",
  EXECUTION__MALWARE = "EXECUTION__MALWARE",
  EXECUTION__LATE_STAGE = "EXECUTION__LATE_STAGE",

  // Credential Exposure
  CREDENTIAL_EXPOSURE = "CREDENTIAL_EXPOSURE",
  CREDENTIAL_EXPOSURE__PUBLICLY_EXPOSED_CREDENTIALS = "CREDENTIAL_EXPOSURE__PUBLICLY_EXPOSED_CREDENTIALS",
  CREDENTIAL_EXPOSURE__PRIVATELY_EXPOSED_CREDENTIALS = "CREDENTIAL_EXPOSURE__PRIVATELY_EXPOSED_CREDENTIALS",

  // Email
  EMAIL = "EMAIL",
  EMAIL__REPORTED_PHISH = "EMAIL__REPORTED_PHISH",
  EMAIL__PHISH = "EMAIL__PHISH",

  // Login
  LOGIN = "LOGIN",
  LOGIN__BRUTE_FORCE = "LOGIN__BRUTE_FORCE",

  // Persistence
  PERSISTENCE = "PERSISTENCE",

  // Data transfer
  DATA_TRANSFER = "DATA_TRANSFER",

  // Deception
  DECEPTION = "DECEPTION",

  // Network connection
  NETWORK_CONNECTION = "NETWORK_CONNECTION",
  NETWORK_CONNECTION__PHISH = "NETWORK_CONNECTION__PHISH",
  NETWORK_CONNECTION__MALICIOUS = "NETWORK_CONNECTION__MALICIOUS",
}

export const NewDetectionCategoryConfig: Record<
  NewDetectionCategory,
  {
    display: string;
    category: NewDetectionCategory;
  }
> = {
  [NewDetectionCategory.EXECUTION]: {
    display: "Execution",
    category: NewDetectionCategory.EXECUTION,
  },
  [NewDetectionCategory.LOGIN]: {
    display: "Login",
    category: NewDetectionCategory.LOGIN,
  },
  [NewDetectionCategory.PERSISTENCE]: {
    display: "Persistence",
    category: NewDetectionCategory.PERSISTENCE,
  },
  [NewDetectionCategory.NON_THREAT]: {
    display: "Non-threat",
    category: NewDetectionCategory.NON_THREAT,
  },
  [NewDetectionCategory.DATA_TRANSFER]: {
    display: "Data Transfer",
    category: NewDetectionCategory.DATA_TRANSFER,
  },
  [NewDetectionCategory.CREDENTIAL_EXPOSURE]: {
    display: "Credential Exposure",
    category: NewDetectionCategory.CREDENTIAL_EXPOSURE,
  },
  [NewDetectionCategory.NETWORK_CONNECTION]: {
    display: "Network Connection",
    category: NewDetectionCategory.NETWORK_CONNECTION,
  },
  [NewDetectionCategory.DECEPTION]: {
    display: "Deception",
    category: NewDetectionCategory.DECEPTION,
  },
  [NewDetectionCategory.EMAIL]: {
    display: "Email",
    category: NewDetectionCategory.EMAIL,
  },
  [NewDetectionCategory.WARNING]: {
    display: "Warning",
    category: NewDetectionCategory.WARNING,
  },
  [NewDetectionCategory.UNKNOWN]: {
    display: "Unknown",
    category: NewDetectionCategory.UNKNOWN,
  },
  [NewDetectionCategory.DIAGNOSTIC]: {
    display: "Diagnostic",
    category: NewDetectionCategory.DIAGNOSTIC,
  },
};

export const NewDetectionSubCategoryConfig: Record<
  NewDetectionSubCategory,
  {
    display: string;
    category: NewDetectionCategory;
    subcategory: NewDetectionSubCategory;
  }
> = {
  // Execution
  [NewDetectionSubCategory.EXECUTION]: {
    display: "Execution",
    category: NewDetectionCategory.EXECUTION,
    subcategory: NewDetectionSubCategory.EXECUTION,
  },
  [NewDetectionSubCategory.EXECUTION__LATE_STAGE]: {
    display: "Late Stage Tool",
    category: NewDetectionCategory.EXECUTION,
    subcategory: NewDetectionSubCategory.EXECUTION__LATE_STAGE,
  },
  [NewDetectionSubCategory.EXECUTION__MALWARE]: {
    display: "Malware",
    category: NewDetectionCategory.EXECUTION,
    subcategory: NewDetectionSubCategory.EXECUTION__MALWARE,
  },

  // Credential exposure
  [NewDetectionSubCategory.CREDENTIAL_EXPOSURE]: {
    display: "Credential Exposure",
    category: NewDetectionCategory.CREDENTIAL_EXPOSURE,
    subcategory: NewDetectionSubCategory.CREDENTIAL_EXPOSURE,
  },
  [NewDetectionSubCategory.CREDENTIAL_EXPOSURE__PUBLICLY_EXPOSED_CREDENTIALS]: {
    display: "Public",
    category: NewDetectionCategory.CREDENTIAL_EXPOSURE,
    subcategory:
      NewDetectionSubCategory.CREDENTIAL_EXPOSURE__PUBLICLY_EXPOSED_CREDENTIALS,
  },
  [NewDetectionSubCategory.CREDENTIAL_EXPOSURE__PRIVATELY_EXPOSED_CREDENTIALS]:
    {
      display: "Private",
      category: NewDetectionCategory.CREDENTIAL_EXPOSURE,
      subcategory:
        NewDetectionSubCategory.CREDENTIAL_EXPOSURE__PRIVATELY_EXPOSED_CREDENTIALS,
    },

  // Email
  [NewDetectionSubCategory.EMAIL]: {
    display: "Email",
    category: NewDetectionCategory.EMAIL,
    subcategory: NewDetectionSubCategory.EMAIL,
  },
  [NewDetectionSubCategory.EMAIL__REPORTED_PHISH]: {
    display: "Reported Phishing",
    category: NewDetectionCategory.EMAIL,
    subcategory: NewDetectionSubCategory.EMAIL__REPORTED_PHISH,
  },
  [NewDetectionSubCategory.EMAIL__PHISH]: {
    display: "Phishing",
    category: NewDetectionCategory.EMAIL,
    subcategory: NewDetectionSubCategory.EMAIL__PHISH,
  },

  // Login
  [NewDetectionSubCategory.LOGIN]: {
    display: "Login",
    category: NewDetectionCategory.LOGIN,
    subcategory: NewDetectionSubCategory.LOGIN,
  },
  [NewDetectionSubCategory.LOGIN__BRUTE_FORCE]: {
    display: "Brute Force",
    category: NewDetectionCategory.LOGIN,
    subcategory: NewDetectionSubCategory.LOGIN__BRUTE_FORCE,
  },

  // Persistence
  [NewDetectionSubCategory.PERSISTENCE]: {
    display: "Persistence",
    category: NewDetectionCategory.PERSISTENCE,
    subcategory: NewDetectionSubCategory.PERSISTENCE,
  },

  // Data transfer
  [NewDetectionSubCategory.DATA_TRANSFER]: {
    display: "Data Transfer",
    category: NewDetectionCategory.DATA_TRANSFER,
    subcategory: NewDetectionSubCategory.DATA_TRANSFER,
  },

  // Deception
  [NewDetectionSubCategory.DECEPTION]: {
    display: "Deception",
    category: NewDetectionCategory.DECEPTION,
    subcategory: NewDetectionSubCategory.DECEPTION,
  },

  // Network connection
  [NewDetectionSubCategory.NETWORK_CONNECTION]: {
    display: "Network Connection",
    category: NewDetectionCategory.NETWORK_CONNECTION,
    subcategory: NewDetectionSubCategory.NETWORK_CONNECTION,
  },
  [NewDetectionSubCategory.NETWORK_CONNECTION__PHISH]: {
    display: "Phishing",
    category: NewDetectionCategory.NETWORK_CONNECTION,
    subcategory: NewDetectionSubCategory.NETWORK_CONNECTION__PHISH,
  },
  [NewDetectionSubCategory.NETWORK_CONNECTION__MALICIOUS]: {
    display: "Network Connection",
    category: NewDetectionCategory.NETWORK_CONNECTION,
    subcategory: NewDetectionSubCategory.NETWORK_CONNECTION__MALICIOUS,
  },

  // Defaults
  [NewDetectionSubCategory.UNKNOWN]: {
    display: "Unknown",
    category: NewDetectionCategory.UNKNOWN,
    subcategory: NewDetectionSubCategory.UNKNOWN,
  },
  [NewDetectionSubCategory.WARNING]: {
    display: "Warning",
    category: NewDetectionCategory.WARNING,
    subcategory: NewDetectionSubCategory.WARNING,
  },
  [NewDetectionSubCategory.DIAGNOSTIC]: {
    display: "Diagnostic",
    category: NewDetectionCategory.DIAGNOSTIC,
    subcategory: NewDetectionSubCategory.DIAGNOSTIC,
  },
  [NewDetectionSubCategory.NON_THREAT]: {
    display: "Non-threat",
    category: NewDetectionCategory.NON_THREAT,
    subcategory: NewDetectionSubCategory.NON_THREAT,
  },
};
export function getSubCategoryTitle(subcategory: NewDetectionSubCategory) {
  const category = NewDetectionSubCategoryConfig[subcategory].category;
  const categoryDisplay = NewDetectionCategoryConfig[category].display;
  const subcategoryDisplay = NewDetectionSubCategoryConfig[subcategory].display;

  if (categoryDisplay === subcategoryDisplay) {
    return subcategoryDisplay;
  }
  return `${subcategoryDisplay} ${categoryDisplay}`;
}

export function getSubcategoryTitleWithFileRiskType(
  subcategory: NewDetectionSubCategory,
  fileRiskType: FileRiskType
) {
  const subcategoryTitle = getSubCategoryTitle(subcategory);
  const riskType = FileRiskTypeConfig[fileRiskType].name;

  if (subcategoryTitle.toLowerCase().includes(riskType.toLowerCase())) {
    return subcategoryTitle;
  }
  return `${riskType} ${subcategoryTitle}`;
}

export function getSubcategoryTitleWithVerdict(
  verdict: Verdict,
  subcategory: NewDetectionSubCategory
) {
  const verdictDisplay = getVerdictConfigByVerdict(verdict).display;
  const subcategoryTitle = getSubCategoryTitle(subcategory);
  return `${verdictDisplay} ${subcategoryTitle}`;
}

export function getSubcategoryTitleWithVerdictAndFileRiskType(
  verdict: Verdict,
  subcategory: NewDetectionSubCategory,
  fileRiskType: FileRiskType
) {
  const verdictDisplay = getVerdictConfigByVerdict(verdict).display;
  const subcategoryWithRisk = getSubcategoryTitleWithFileRiskType(
    subcategory,
    fileRiskType
  );
  if (
    subcategoryWithRisk.toLowerCase().includes(verdictDisplay.toLowerCase())
  ) {
    return subcategoryWithRisk;
  }
  return `${verdictDisplay} ${subcategoryWithRisk}`;
}
