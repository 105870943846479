import { AppLayout } from "@/components/app-layout";
import { TableCard } from "@/components/table-card";
import { Badge } from "@/components/ui/badge";
import {
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Switch } from "@/components/ui/switch";
import { apiClient } from "@/lib/api";
import { components } from "@/lib/api.types";
import { getCaseStatusBadge, getVerdictBadgeVariant } from "@/lib/case";
import { getTimezone } from "@/lib/time";
import { cn } from "@/lib/utils";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import {
  keepPreviousData,
  queryOptions,
  useQuery,
} from "@tanstack/react-query";
import { Link, useNavigate, useRouteContext } from "@tanstack/react-router";
import {
  getSubCategoryTitle,
  NewDetectionSubCategory,
  Status,
  Verdict,
  VerdictConfig,
} from "@wire/shared";
import moment from "moment";
import { useEffect, useState } from "react";

async function getData(
  searchSettings: components["schemas"]["SearchCasesDto"]
) {
  const [cases] = await Promise.all([
    apiClient.POST("/cases", {
      body: searchSettings,
    }),
  ]);
  if (cases.error != null) {
    throw new Error("Error getting cases information");
  }
  return cases.data;
}

export type CaseSearchStatus = NonNullable<
  components["schemas"]["SearchCasesDto"]["statuses"]
>[number];

export interface CasesSearchProps {
  filter?: CaseSearchStatus;
  page: number;
  orderBy?: string;
  orderDir?: "asc" | "desc";
  search?: string;
}

export const CASES_QUERY_KEY = "case-settings";
export const getCasesOptions = (
  searchSettings: components["schemas"]["SearchCasesDto"]
) =>
  queryOptions({
    queryKey: [CASES_QUERY_KEY, searchSettings],
    queryFn: () => getData(searchSettings),
    placeholderData: keepPreviousData,
  });

export function Cases(props: { search: CasesSearchProps }) {
  const { team } = useRouteContext({ from: "/_application" });
  const [hideDemoClients, setHideDemoClients] = useState(
    team.serviceProvider
      ? (localStorage.getItem("hideDemoClients") ?? "true") == "true"
      : false
  );
  const {
    filter: urlFilter,
    page: urlPage,
    search: urlSearch,
    orderBy: urlOrderBy,
    orderDir: urlOrderDir,
  } = props.search;
  const navigate = useNavigate();
  const [searchSettings, setSearchSettings] = useState<
    components["schemas"]["SearchCasesDto"]
  >({
    statuses: urlFilter ? [urlFilter] : [],
    page: urlPage ?? "1",
    orderBy: urlOrderBy,
    orderDir: urlOrderDir,
    search: urlSearch,
    hideDemoClients,
  });
  const casesQuery = useQuery(getCasesOptions(searchSettings));
  async function updateSearchSettings(
    settings: components["schemas"]["SearchCasesDto"]
  ) {
    let search = settings.search ?? searchSettings.search;
    if (settings?.search?.length == 0) {
      search = undefined;
    }
    let orderBy = settings.orderBy;
    let orderDir = settings.orderDir;
    let page = settings.page;

    if (
      (settings.statuses != null && settings.statuses.length > 0) ||
      (search != null && search != searchSettings.search) ||
      orderBy != null ||
      orderDir != null
    ) {
      page = 1;
    }
    if (search != null && search.length == 0) search = undefined;
    await navigate({
      replace: true,
      to: ".",
      search: {
        page: page ?? searchSettings.page,
        orderBy: orderBy ?? searchSettings.orderBy,
        orderDir: orderDir ?? searchSettings.orderDir,
        search: search,
        filter: urlFilter,
      },
    });

    setSearchSettings({
      ...searchSettings,
      ...settings,
      page,
      statuses: urlFilter ? [urlFilter] : [],
    });
  }

  useEffect(() => {
    setSearchSettings({
      ...searchSettings,
      hideDemoClients,
    });
    localStorage.setItem("hideDemoClients", JSON.stringify(hideDemoClients));
  }, [hideDemoClients]);

  useEffect(() => {
    //eslint-disable-next-line @typescript-eslint/no-floating-promises
    updateSearchSettings({ statuses: urlFilter ? [urlFilter] : [] });
  }, [urlFilter]);

  return (
    <AppLayout>
      <main className="flex-1 flex flex-col items-start space-y-4">
        <TableCard
          query={casesQuery}
          defaultPage={urlPage}
          orderBy={urlOrderBy}
          orderByDir={urlOrderDir}
          defaultSearch={urlSearch}
          onUpdate={updateSearchSettings}
          searchable
          onClick={
            team.serviceProvider
              ? (row) => {
                  window.open(
                    `/cases/${row.id}?switchTeamId=${row.teamId}`,
                    team.serviceProvider ? "_blank" : undefined
                  );
                }
              : undefined
          }
          onClickNavigate={
            team.serviceProvider
              ? undefined
              : (row) => ({
                  to: "/cases/$caseId",
                  params: { caseId: row.id },
                  target: team.serviceProvider ? "_blank" : undefined,
                })
          }
          headers={[
            {
              display: "ID",
              key: "sid",
              sortable: true,
            },
            team.serviceProvider
              ? {
                  display: "Client",
                  key: "teamName",
                }
              : undefined,
            {
              display: "Category",
              key: "subcategories",
              format(value: NewDetectionSubCategory[], row) {
                let hoverBadge: React.ReactNode = null;
                if (value != null && value.length > 1) {
                  let subCats = value.slice(1);
                  hoverBadge = (
                    <HoverCard openDelay={0} closeDelay={50}>
                      <HoverCardTrigger>
                        <Badge
                          variant={getVerdictBadgeVariant(
                            row.verdict as any,
                            row.status as any
                          )}
                        >
                          +{subCats.length}
                        </Badge>
                      </HoverCardTrigger>
                      <HoverCardContent>
                        <ul>
                          {subCats.map((v) => (
                            <li key={v}>{getSubCategoryTitle(v)}</li>
                          ))}
                        </ul>
                      </HoverCardContent>
                    </HoverCard>
                  );
                }
                return (
                  <div className="flex flex-row gap-1">
                    <Badge
                      variant={getVerdictBadgeVariant(
                        row.verdict as any,
                        row.status as any
                      )}
                    >
                      {getSubCategoryTitle(value[0])}
                    </Badge>
                    {hoverBadge}
                  </div>
                );
              },
            },
            {
              display: "Name",
              key: "name",
              sortable: true,
            },
            {
              display: "Status",
              key: "status",
              sortable: true,
              format: (value) => getCaseStatusBadge(value),
            },
            {
              display: `Created At (${getTimezone()})`,
              key: "firstDetectionSourceDetectedAt",
              sortable: true,
              dateTime: true,
            },
            {
              display: "MTTR",
              key: "mttr",
              format(value, row) {
                if (value == null || row.status != Status.CLOSED) return "-";
                return moment.duration(value, "seconds").humanize();
              },
            },
            {
              display: "",
              key: "id",
              format(value, row) {
                let text = "";
                if (row.testMode) {
                  text =
                    "This case was ingested in test mode and may have exaggerated numbers for MTTR and MTTD.";
                } else if (row.reingested) {
                  text =
                    "This case was reingested and may have exaggerated numbers for MTTR and MTTD.";
                } else {
                  return;
                }
                return (
                  <div className="flex items-center justify-center">
                    <HoverCard closeDelay={50} openDelay={0}>
                      <HoverCardTrigger className="ml-1">
                        <InformationCircleIcon className="h-6 w-6 text-red-500" />
                      </HoverCardTrigger>
                      <HoverCardContent className="whitespace-normal font-normal">
                        {text}
                      </HoverCardContent>
                    </HoverCard>
                  </div>
                );
              },
            },
          ]}
        >
          <>
            <CardHeader className="pb-2">
              <CardTitle>Cases</CardTitle>
              <CardDescription>Cases for your organization.</CardDescription>
            </CardHeader>
            <CardContent className="pb-2">
              <div className="flex w-full  lg:items-center flex-col gap-2 lg:flex-row">
                <ul className="bg-background shadow-sm border p-2 rounded-md flex flex-col lg:flex-row w-full items-center lg:w-auto lg:items-start">
                  <FilterItem key="all-filter" activeFilter={urlFilter}>
                    All
                  </FilterItem>
                  <FilterItem
                    key="processing-filter"
                    filter="PROCESSING"
                    activeFilter={urlFilter}
                  >
                    Processing
                  </FilterItem>
                  <FilterItem
                    key="escalated-filter"
                    filter="ESCALATED"
                    activeFilter={urlFilter}
                  >
                    Escalated
                  </FilterItem>
                  <FilterItem
                    key="closed-filter"
                    filter="CLOSED"
                    activeFilter={urlFilter}
                  >
                    Closed
                  </FilterItem>
                </ul>
                <div>
                  <Select
                    onValueChange={(v) =>
                      updateSearchSettings({
                        verdict: v == "any" ? undefined : (v as Verdict),
                      })
                    }
                  >
                    <SelectTrigger className="h-full bg-background font-medium min-w-[180px]">
                      <SelectValue
                        key="select-value"
                        placeholder="Filter By Verdict"
                      />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem key="any" value="any">
                        Any
                      </SelectItem>
                      {Object.values(VerdictConfig).map((v) => (
                        <SelectItem key={v.display} value={v.type}>
                          {v.display}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
                {team.serviceProvider && (
                  <div className="flex items-center flex-1 justify-end gap-1">
                    <Switch
                      checked={hideDemoClients}
                      onCheckedChange={(v) => setHideDemoClients(v)}
                    />{" "}
                    <Label>Hide Demo Clients</Label>
                  </div>
                )}
              </div>
            </CardContent>
          </>
        </TableCard>
      </main>
    </AppLayout>
  );
}

function FilterItem(
  props: React.PropsWithChildren<{
    activeFilter: CaseSearchStatus | undefined;
    filter?: CaseSearchStatus;
  }>
) {
  return (
    <li
      className={cn(
        "px-3 text-sm text-muted-foreground font-medium rounded-md",
        {
          "bg-background font-semibold text-foreground":
            props.filter == props.activeFilter,
        }
      )}
    >
      <Link to="." search={{ filter: props.filter }}>
        {props.children}
      </Link>
    </li>
  );
}
