import { BaseCustomEdgeDataProps } from "@/components/flow/edge/base-custom-edge";
import { BaseCustomEditor } from "@/components/flow/editor/base-custom-editor";
import { BaseFooter } from "@/components/flow/editor/base-footer";
import { EdgeLogger } from "@/components/flow/editor/utils/edge-logger";
import { EdgeStyleTypeSwitcher } from "@/components/flow/editor/utils/edge-switcher";
import { EdgeEditorProps } from "@/components/flow/flow.types";
import { CardContent } from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { memo } from "react";

export interface MatchEdgeProps extends BaseCustomEdgeDataProps {
  name?: string;
  description?: string;
  branch?: boolean;
}

function MatchEdgeEditor(props: EdgeEditorProps<MatchEdgeProps>) {
  return (
    <>
      <CardContent>
        <BaseCustomEditor {...props} hideQuery hideTitle>
          <EdgeLogger
            selectedEdge={props.selectedEdge}
            onEdgeUpdate={props.updateEdge}
            selectedDetection={props.selectedDetection}
          />
        </BaseCustomEditor>
      </CardContent>
      <BaseFooter onClose={props.onClose}>
        <div className="flex flex-col gap-1">
          <Label className="text-xs">Match Type</Label>
          <Select
            key={props.selectedEdge?.id}
            value={props.selectedEdge?.data?.branch ? "true" : "false"}
            onValueChange={(v) => {
              props.updateData({ branch: v == "true" });
            }}
          >
            <SelectTrigger>
              <SelectValue
                defaultValue={
                  props.selectedEdge?.data?.branch ? "true" : "false"
                }
                placeholder={
                  props.selectedEdge?.data?.branch ? "true" : "false"
                }
              ></SelectValue>
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="true">true</SelectItem>
              <SelectItem value="false">false</SelectItem>
            </SelectContent>
          </Select>
        </div>
        <EdgeStyleTypeSwitcher
          selectedEdge={props.selectedEdge}
          onEdgeUpdate={props.updateEdge}
        />
      </BaseFooter>
    </>
  );
}

let MatchEdgeEditorMemo = memo(MatchEdgeEditor);

export { MatchEdgeEditorMemo as MatchEdgeEditor };
