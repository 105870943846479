import {
  Cases,
  CaseSearchStatus,
  getCasesOptions,
} from "@/components/cases/cases";
import { createFileRoute, SearchSchemaInput } from "@tanstack/react-router";

export const Route = createFileRoute("/_application/sp/cases")({
  validateSearch: (
    search: {
      filter?: CaseSearchStatus;
      page?: string | number;
      orderBy?: string;
      orderDir?: "asc" | "desc";
      search?: string;
    } & SearchSchemaInput
  ) => {
    let page = search.page ?? 1;
    if (typeof page == "string") {
      page = parseInt(page);
    }
    if (isNaN(page)) {
      page = 1;
    }
    return {
      filter: search.filter,
      search: search.search,
      orderBy: search.orderBy ?? "sid",
      orderDir: search.orderDir ?? "desc",
      page: isNaN(page) ? 1 : page,
    };
  },
  loaderDeps: ({ search }) => {
    return {
      filter: search.filter,
      page: search.page,
      search: search.search,
      orderBy: search.orderBy,
      orderDir: search.orderDir,
    };
  },
  loader: async ({ context, deps }) => {
    await context.queryClient.ensureQueryData(
      getCasesOptions({
        statuses: deps.filter ? [deps.filter] : [],
        page: deps.page,
        search: deps.search,
        orderBy: deps.orderBy,
        orderDir: deps.orderDir,
      })
    );
  },
  component: () => {
    const search = Route.useSearch();
    return <Cases search={search} />;
  },
});
