import { apiClient } from "@/lib/api";
import { useEffect, useState } from "react";
import { OAuthButton } from "./oauth-button";

export default function Slack(props: { onComplete: () => void }) {
  const [error] = useState<string>();
  const [url, setUrl] = useState<string>();

  useEffect(() => {
    async function loadUrl() {
      let response = await apiClient.POST("/integration/slack/url");
      setUrl(response.data?.url);
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    loadUrl();
  }, []);

  return (
    <OAuthButton
      title="Integrate Slack"
      error={error}
      url={url}
      onComplete={props.onComplete}
    />
  );
}
