import { ConstrainedAppLayout } from "@/components/app-layout";
import { Button } from "@/components/ui/button";
import { apiClient } from "@/lib/api";
import { SearchSchemaInput, createFileRoute } from "@tanstack/react-router";
import { ReactNode, useEffect, useState } from "react";
import { toast } from "sonner";

export const Route = createFileRoute(
  "/_application/settings/integrations/teams/finish"
)({
  component: FinishTeamsIntegration,
  validateSearch: (
    search: {
      state: string;
    } & SearchSchemaInput
  ) => {
    return {
      state: search.state,
    };
  },
});

function FinishTeamsIntegration() {
  const { teams, team } = Route.useRouteContext();
  const { state } = Route.useSearch();
  const [saved, setSaved] = useState(false);
  let text: ReactNode;
  async function saveIntegration() {
    const response = await apiClient.POST(
      "/integration/microsoft/teams/redirect",
      {
        params: {
          query: {
            state,
          },
        },
      }
    );
    if (response.error != null) {
      toast.error(response.error.message);
      return;
    }
    setSaved(true);
    toast.success("Integration finished");
  }
  useEffect(() => {
    if (teams.length == 1) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      (async () => {
        await saveIntegration();
      })();
    }
  }, []);

  if (saved) {
    text = <div>Integration added, you may now close this page.</div>;
  } else if (teams.length == 1) {
    text = <div>Adding integration, please wait...</div>;
  } else {
    text = (
      <>
        <p className="text-lg">
          Confirm you would like to add this integration to the{" "}
          <b>{team.name}</b> team. If not, switch teams in the top right corner
          and visit this URL again.
        </p>
        <Button
          className="mt-8"
          size="lg"
          onClick={saveIntegration}
          disabled={saved}
        >
          Add Integration
        </Button>
      </>
    );
  }
  return (
    <ConstrainedAppLayout>
      <div className="flex flex-col items-center">{text}</div>
    </ConstrainedAppLayout>
  );
}
