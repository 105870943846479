"use client";
import { Button } from "@/components/ui/button";
import { apiClient } from "@/lib/api";
import { components } from "@/lib/api.types";
import { ROLE } from "@wire/shared";
import { useState } from "react";
import { toast } from "sonner";

export default function ToggleIntegration({
  integration,
  onComplete,
}: {
  integration: components["schemas"]["Integration"];
  onComplete: () => void;
}) {
  const [loading, setLoading] = useState(false);
  const [enabled, setEnabled] = useState(integration.enabled);
  async function enable() {
    setLoading(true);
    const promise = apiClient.PATCH("/integration/{id}/activate", {
      params: { path: { id: integration.id } },
    });
    toast.promise(promise, {
      loading: "Enabling integration...",
    });
    const response = await promise;
    if (response.error != null) {
      toast.error((response as any).error.message);
      return;
    }
    setEnabled(true);
    toast.success("Integration enabled");
    onComplete();
    setLoading(false);
  }
  async function disable() {
    setLoading(true);

    let promise = apiClient.PATCH("/integration/{id}/deactivate", {
      params: { path: { id: integration.id } },
    });
    toast.promise(promise, {
      loading: "Disabling integration...",
    });
    const response = await promise;
    if (response.error != null) {
      toast.error((response as any).error.message);
      return;
    }
    setEnabled(false);
    toast.warning("Integration disabled");
    onComplete();
    setLoading(false);
  }
  if (enabled) {
    return (
      <Button
        requiredRole={ROLE.ADMIN}
        disabled={loading}
        variant="outlineDestructive"
        onClick={disable}
      >
        Disable Integration
      </Button>
    );
  } else {
    return (
      <Button requiredRole={ROLE.ADMIN} onClick={enable}>
        Enable Integration
      </Button>
    );
  }
}
