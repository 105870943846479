import { ROLE } from "./types/shared.types";
export * from "./lib/flow/verdict-flow";
export * from "./lib/query/detection-query";
export * from "./types/shared.types";

export * from "./lib/docs";

export function userHasRole(userRole: ROLE, requiredRole: ROLE) {
  if (requiredRole == userRole) return true;

  if (userRole == ROLE.ADMIN) {
    return true;
  }
  if (requiredRole == ROLE.VIEWER && userRole == ROLE.ANALYST) {
    return true;
  }
  return false;
}

export const ROLE_CONFIG = {
  [ROLE.ADMIN]: {
    name: "Admin",
    description: "Full access to all features",
  },
  [ROLE.ANALYST]: {
    name: "Analyst",
    description: "Access to all features",
  },
  [ROLE.VIEWER]: {
    name: "Viewer",
    description: "Limited access to features",
  },
};

export function oxfordComma(items: string[]) {
  if (items.length === 0) {
    return "";
  }
  items = [...new Set(items)];
  if (items.length == 1) {
    return items[0];
  }
  if (items.length == 2) {
    return `${items[0]} and ${items[1]}`;
  }
  let last = items.pop();
  return `${items.join(", ")}, and ${last}`;
}
