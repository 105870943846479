import Underline from "@/components/onboarding/underline";
import AddIntegration, {
  IntegrationLogo,
} from "@/components/settings/add-integration";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import { Logo } from "@/components/ui/logo";
import { apiClient } from "@/lib/api";
import { components } from "@/lib/api.types";
import { GLOBAL_TEAM_QUERY_KEY } from "@/lib/tanstack";
import { cn, oxfordComma, pluralize } from "@/lib/utils";
import { CheckCircleIcon } from "@heroicons/react/16/solid";
import {
  queryOptions,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import {
  createFileRoute,
  Link,
  useNavigate,
  useRouteContext,
} from "@tanstack/react-router";
import {
  DOC_LINKS,
  getIntegrationConfigByPlatform,
  getIntegrationTypeConfigByType,
  IntegrationConfig,
  IntegrationPlatform,
  IntegrationType,
  ROLE,
} from "@wire/shared";
import { AnimatePresence, motion } from "framer-motion";
import React, { ReactNode, useEffect, useMemo, useState } from "react";
export const Route = createFileRoute("/_onboarding/user-onboarding/$step")({
  component: UserOnboarding,
  loader: ({ context }) => context.queryClient.ensureQueryData(getOptions()),
});

async function getData() {
  const integrations = await apiClient.POST("/integration", {
    body: { size: 100 },
  });
  if (integrations.error != null) {
    throw new Error("Error getting integration data");
  }
  return { integrations: integrations.data };
}

export const ONBOARDING_USER_QUERY_KEY = "onboarding-user";
const getOptions = () =>
  queryOptions({
    queryKey: [ONBOARDING_USER_QUERY_KEY],
    queryFn: () => getData(),
  });

export function UserOnboarding() {
  const {
    data: { integrations },
  } = useSuspenseQuery(getOptions());
  const { team, user } = useRouteContext({ from: "/_onboarding" });
  const { step } = Route.useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  async function complete() {
    await apiClient.PATCH("/users", { body: { completedOnboarding: true } });
    await queryClient.invalidateQueries({ queryKey: [GLOBAL_TEAM_QUERY_KEY] });
    await navigate({ to: "/dashboard" });
  }

  const StepComponent = useMemo(() => {
    switch (step) {
      default:
      case "1":
        return StepOne;
      case "2":
        return StepTwo;
      case "3":
        return StepThree;
    }
  }, [step]);
  return (
    <div className="w-screen min-h-screen bg-background flex">
      <div className="flex p-4 w-full flex-col justify-center items-center">
        <AnimatePresence mode="wait">
          <StepComponent
            team={team}
            complete={complete}
            key={step}
            role={user.role}
            integrations={integrations?.data}
          />
        </AnimatePresence>
      </div>
    </div>
  );
}

function StepOne(props: { complete: () => Promise<void> }) {
  const { user } = useRouteContext({ from: "/_onboarding" });
  return (
    <motion.div
      className="flex flex-col items-center"
      exit={{ opacity: 0, scale: 0.5 }}
      transition={{ duration: 0.25 }}
    >
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.25 }}
      >
        <img src="/illustrations/welcome.svg" className="max-h-96 w-auto" />
        <h1 className="text-4xl text-center font-semibold mt-8">
          Welcome to Wirespeed!
        </h1>
      </motion.div>
      <div className="w-96 p-4 h-auto">
        <Underline className="h-auto stroke-primary w-auto" />
      </div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1, duration: 0.5 }}
      >
        <p className="mt-4 text-center text-xl">
          Let us introduce you to the platform, we promise it'll be quick
        </p>
      </motion.div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1.6, duration: 0.5 }}
      >
        {user.role == ROLE.ADMIN ? (
          <Link to="/user-onboarding/$step" params={{ step: "2" }}>
            <Button size="lg" className="mt-8">
              Get Started
            </Button>
          </Link>
        ) : (
          <Button onClick={props.complete} size="lg" className="mt-8">
            Get Started
          </Button>
        )}
      </motion.div>
    </motion.div>
  );
}

function StepTwo(props: { complete: () => Promise<void>; role: ROLE }) {
  const { user } = useRouteContext({ from: "/_onboarding" });
  const [carouselComplete, setCarouselComplete] = useState(false);
  return (
    <motion.div
      className="flex flex-col items-center"
      exit={{ opacity: 0, scale: 0.5 }}
      transition={{ duration: 0.25 }}
    >
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.25 }}
      >
        <div>
          <h1 className="text-4xl max-w-2xl font-bold">
            <Logo words />
          </h1>

          <h2 className="text-xl font-semibold text-right">...in 60 seconds</h2>
        </div>
        <div className="mt-8">
          <AboutWirespeedCarousel
            role={props.role}
            carouselComplete={() => setCarouselComplete(true)}
          />
        </div>
        <div
          className={cn("flex justify-center transition-opacity opacity-0", {
            "opacity-100": carouselComplete,
          })}
        >
          {user.role == ROLE.ADMIN ? (
            <Link to="/user-onboarding/$step" params={{ step: "3" }}>
              <Button disabled={!carouselComplete} size="lg" className="mt-8">
                Set up Integrations
              </Button>
            </Link>
          ) : (
            <Button
              disabled={!carouselComplete}
              onClick={props.complete}
              size="lg"
              className="mt-8"
            >
              Get Started
            </Button>
          )}
        </div>
      </motion.div>
    </motion.div>
  );
}

function StepThree(props: {
  team: components["schemas"]["Team"];
  complete: () => Promise<void>;
  integrations?: components["schemas"]["IntegrationWithoutMetadata"][];
}) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [integrationPlatform, setIntegrationPlatform] =
    useState<IntegrationPlatform>();
  const [integrationModalOpen, setIntegrationModalOpen] = useState(false);

  const formattedIntegrations = useMemo(() => {
    return Object.values(IntegrationConfig)

      .map((v) => ({
        ...v,
        integrated: props.integrations?.some((i) => i.platform == v.platform),
      }))
      .sort((a, b) => a.display.localeCompare(b.display));
  }, [props.integrations]);

  async function skip() {
    await apiClient.PATCH("/users", { body: { completedOnboarding: true } });
    await navigate({ to: "/dashboard" });
  }

  // Keep checking for when integrations are set up
  useEffect(() => {
    const interval = setInterval(async () => {
      await queryClient.invalidateQueries({
        queryKey: [ONBOARDING_USER_QUERY_KEY],
      });
    }, 2500);

    return () => clearInterval(interval);
  }, []);

  function selectIntegration(platform: IntegrationPlatform) {
    setIntegrationPlatform(platform);
    setIntegrationModalOpen(true);
  }

  const { text, completedOnboarding } = useMemo(() => {
    let hasChatIntegration = formattedIntegrations.some(
      (v) => v.integrated && v.types.includes(IntegrationType.CHAT)
    );
    let hasDirectoryIntegration = formattedIntegrations.some(
      (v) =>
        v.integrated &&
        v.types.includes(IntegrationType.DIRECTORY) &&
        !v.enabledByDefault
    );
    let hasDetectionIntegration = formattedIntegrations.some(
      (v) =>
        v.integrated &&
        v.types.some((v) => v.toString().startsWith("DETECTIONS_")) &&
        !v.enabledByDefault
    );

    let names: string[] = [];
    if (!hasChatIntegration) names.push("chat");
    if (!hasDirectoryIntegration) names.push("directory");
    if (!hasDetectionIntegration) names.push("detection");

    let text: ReactNode;
    if (names.length > 0) {
      text = (
        <>
          We need to set up {names.length == 1 ? " a " : ""}{" "}
          {oxfordComma(names)} {pluralize(names.length, "integration")} before
          we can get started. If you aren't ready, you can click "Skip" below
          and come back later.
        </>
      );
    } else {
      text = "You're all set up! Click below to continue.";
    }

    return {
      text,
      completedOnboarding: hasDirectoryIntegration && hasDetectionIntegration,
    };
  }, [formattedIntegrations]);

  return (
    <motion.div
      className="grid grid-cols-1 lg:grid-cols-2 p-4 gap-8 lg:gap-32"
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.25 }}
      exit={{ opacity: 0, scale: 0.5 }}
    >
      <div className="lg:order-1 order-2">
        <h1 className="text-4xl font-bold mt-4">
          Getting Started with Wirespeed
        </h1>
        <>
          <h2 className="text-3xl font-semibold mt-8">Integrations</h2>
          <p className="mt-4 text-lg">{text}</p>

          <ul className="my-8 flex max-h-[512px] overflow-y-auto rounded-md border-2 shad-wmd flex-col gap-2">
            {formattedIntegrations.map((v) => (
              <li
                onClick={() => selectIntegration(v.platform)}
                className={cn(
                  "flex gap-4 cursor-pointer items-center hover:bg-muted rounded-md p-4",
                  {
                    "bg-muted": v.integrated,
                  }
                )}
              >
                <IntegrationLogo className="h-8 w-8" platform={v.platform} />
                <div className="flex flex-col">
                  <h2 className="text-2xl">{v.display}</h2>
                  <p className="text-xs text-muted-foreground">
                    {getIntegrationConfigByPlatform(v.platform)
                      .types?.map(
                        (t) => getIntegrationTypeConfigByType(t)?.display
                      )
                      .join(", ")}
                  </p>
                </div>
                {v.integrated && (
                  <CheckCircleIcon className="h-6 w-6 text-green-500" />
                )}
              </li>
            ))}
          </ul>

          {completedOnboarding ? (
            <Button onClick={props.complete}>Continue</Button>
          ) : (
            <Button onClick={skip} variant="outline">
              Skip
            </Button>
          )}
          <AddIntegration
            hideTrigger
            onboarding
            onChange={setIntegrationModalOpen}
            open={integrationModalOpen}
            platform={integrationPlatform}
          />
        </>
      </div>
      <div className="order-1 lg:order-2">
        <img
          src="/illustrations/choose.svg"
          className="lg:h-auto h-96 w-auto"
        />
      </div>
    </motion.div>
  );
}

export function AboutWirespeedCarousel(props: {
  carouselComplete: () => void;
  role: ROLE;
}) {
  const [api, setApi] = React.useState<CarouselApi>();
  const [current, setCurrent] = React.useState(0);

  const carouselItems = useMemo(() => {
    return [
      {
        content: (
          <div className="flex h-full flex-1 flex-col gap-4">
            <h2 className="text-lg font-semibold">
              Wirespeed is an MDR platform that helps your company stay on top
              of security alerts!
            </h2>
            <div>
              <p>Things like:</p>
              <ul className="list-disc list-inside">
                <li>
                  Your architect randomly logging in from Ohio when they're
                  based in New Jersey
                </li>
                <li>An accountant running Powershell 😬</li>
                <li>Employee credentials showing up in breaches</li>
              </ul>
            </div>

            <p>
              We do this using our verdicting algorithm that is able to
              determine the good from the bad in <b>milliseconds</b>.
            </p>
            <div className="flex-1"></div>
            <p>
              But what happens when we can't determine the bad from good? &rarr;
            </p>
          </div>
        ),
      },
      {
        content: (
          <>
            <h2 className="text-lg font-semibold">We use Chat Operations!</h2>
            <p>
              Many security teams are afraid to interact with your users. Not
              us! We routinely{" "}
              <a
                target="_blank"
                className="hover:underline text-blue-500"
                href="https://wirespeed.co/posts/breaking-the-4th-wall"
              >
                break the fourth wall
              </a>{" "}
              and reach out to your users via Slack, Teams, or email to
              investigate their activities.
            </p>
            <div className="hidden lg:block">
              <div className="border p-4 mt-2 rounded-md shadow-md">
                <div className="flex flex-row gap-2">
                  <div className="p-4 flex items-center justify-center rounded-md bg-purple-500 h-12 w-12 text-xl">
                    ⚡️
                  </div>
                  <div>
                    <div>
                      <span className="font-bold">Wirespeed</span>
                      <span className="bg-muted  text-xs mx-2 rounded-md p-1">
                        APP
                      </span>
                      <span className="text-muted-foreground text-xs">
                        14m ago
                      </span>
                    </div>
                    <p className="mt-1 text-sm lg:text-md font-normal">
                      We have detected a login from your account from a new
                      location. Did you login from <b>Bloomington, Minnesota</b>{" "}
                      today?
                    </p>
                    <div className="flex mt-2 font-medium flex-row gap-2">
                      <div className="py-2 text-sm lg:text-md px-3 border-2 rounded-md">
                        Yes
                      </div>
                      <div className="py-2 text-sm lg:text-md px-3 border-2 rounded-md">
                        No
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p className="text-xs text-right text-muted-foreground">
                Example slack message
              </p>
            </div>

            <div className="mt-4">
              It's really that simple. If we don't hear back from the user or
              their manager after a predefined period, we will alert you for
              further investigation.
            </div>
          </>
        ),
      },
      {
        content: (
          <div className="flex flex-col h-full">
            <h2 className="text-lg font-semibold">
              Alright we're wrapping up now. A few housekeeping items:
            </h2>
            <ol className="list-decimal flex flex-col gap-4 justify-between list-inside mt-2">
              <li>
                {props.role == ROLE.ADMIN
                  ? "Set up integrations for your detection products, user directories, and chat platforms. You can do that on the next screen."
                  : "Your administrator will set up integrations for your detection products, user directories, and chat platforms."}
              </li>
              <li>
                You will begin in{" "}
                <a
                  className="text-blue-500 hover:underline"
                  target="_blank"
                  href={DOC_LINKS.TEST_MODE}
                >
                  Test Mode
                </a>
                . We're read-only and won't make any changes in your environment
                or interact with your users.
              </li>
              <li>
                Once you're ready, you can disable test mode and start
                increasing chat ops interactions and auto containment. Learn
                more{" "}
                <a
                  className="text-blue-500 hover:underline"
                  href={DOC_LINKS.TEST_MODE}
                >
                  here
                </a>
                .
              </li>
            </ol>
            <div className="flex-1"></div>
            <p>
              Click the button below to get started, we're so excited to have
              you on onboard!
            </p>
          </div>
        ),
      },
    ];
  }, []);

  useEffect(() => {
    if (current == carouselItems.length) {
      props.carouselComplete();
    }
  }, [current, carouselItems]);

  React.useEffect(() => {
    if (api == null) {
      return;
    }

    setCurrent(api.selectedScrollSnap() + 1);

    api.on("select", () => {
      setCurrent(api.selectedScrollSnap() + 1);
    });
  }, [api]);

  return (
    <div className="max-w-2xl w-auto">
      <Carousel setApi={setApi} className="w-full">
        <CarouselContent>
          {carouselItems.map((v, index) => (
            // w-0 because the slides don't get small enough on mobile
            // the width isn't actually zero since we don't hide overflow
            <CarouselItem className="w-0" key={index}>
              <Card className="h-full flex flex-col">
                <CardContent className="max-w-2xl flex-1 w-auto gap-2 p-6">
                  {v.content}
                </CardContent>
              </Card>
            </CarouselItem>
          ))}
        </CarouselContent>
        <div className="flex justify-between mt-2 w-full">
          <div>
            {Array.from({ length: carouselItems.length }, (_, index) => (
              <span
                key={index}
                className={`inline-block w-2 h-2 mx-1 rounded-full ${
                  index + 1 === current ? "bg-primary" : "bg-muted"
                }`}
              />
            ))}
          </div>
          <div className="flex gap-2">
            <CarouselPrevious />
            <CarouselNext />
          </div>
        </div>
      </Carousel>
      <div className="py-2 text-center text-sm text-muted-foreground"></div>
    </div>
  );
}
