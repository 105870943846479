"use client";
import { cn } from "@/lib/utils";
import {
  Link,
  useLocation,
  useNavigate,
  useRouteContext,
  useRouter,
} from "@tanstack/react-router";

import { Button } from "@/components/ui/button";
import { Combobox } from "@/components/ui/combo-box";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Label } from "@/components/ui/label";
import { Logo } from "@/components/ui/logo";
import { Sheet, SheetContent } from "@/components/ui/sheet";
import { Switch } from "@/components/ui/switch";
import {
  apiClient,
  isImpersonating,
  logout,
  setImpersonatedAPIToken,
  switchTeam,
} from "@/lib/api";
import {
  ArrowTopRightOnSquareIcon,
  Bars3Icon,
  MagnifyingGlassIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { showArticle } from "@intercom/messenger-js-sdk";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { DOCS, ROLE, userHasRole } from "@wire/shared";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { ModeToggle } from "./mode-toggle";
import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "./ui/command";

async function searchCases(search: string) {
  const result = await apiClient.POST("/cases", { body: { search } });
  return result.data;
}

export const SERVICE_PROVIDER_PATH = "/sp";

async function billing(e: React.MouseEvent<HTMLDivElement>) {
  e.preventDefault();
  const res = await apiClient.GET("/stripe/portal");
  if (res.error != null) {
    throw new Error("Error getting billing information");
  }
  const url = res.data.url;
  window.open(url, "_blank");
}

export default function Navigation() {
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { team, user, teams } = useRouteContext({ from: "/_application" });
  const [cmdOpen, setCmdOpen] = useState(false);
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const router = useRouter();
  const { data: detectionData } = useQuery({
    queryKey: ["search-cases", search],
    queryFn: () => searchCases(search),
    placeholderData: keepPreviousData,
  });
  const showTeamSelector = useMemo(() => {
    return teams.length > 1;
  }, [teams]);

  interface NavigationOption {
    name: string;
    href?: string;
    current: boolean;
    requiredRole?: ROLE;
    children?: {
      name: string | ReactNode;
      description: string;
      disabled?: boolean;
      target?: "_blank" | "_self";
      href?: string;
      current?: boolean;
      onClick?: (e: any) => void;
      requiredRole?: ROLE;
    }[];
  }

  const navigation = useMemo(() => {
    let options: NavigationOption[] = [
      {
        name: "Dashboard",
        href: "/dashboard",
        current: pathname == "/dashboard",
      },
      {
        name: "Cases",
        href: "/cases",
        current: pathname.startsWith("/cases"),
      },
      {
        name: "Assets",
        current: pathname.startsWith("/assets"),
        children: [
          {
            name: "Users",
            description:
              "Users discovered in detections that are present in your directories",
            href: "/assets/users",
            current: pathname.startsWith("/assets/users"),
          },
          {
            name: "Endpoints",
            description:
              "Endpoints and servers synced from your detection platforms",
            href: "/assets/endpoints",
            current: pathname.startsWith("/assets/endpoints"),
          },
          {
            name: "Locations",
            description: "Locations of detections that have ocurred",
            href: "/assets/locations",
            current: pathname.startsWith("/assets/locations"),
          },
          {
            name: "Processes",
            description:
              "Potentially malicious processes that have triggered detections",
            href: "/assets/processes",
            current: pathname.startsWith("/assets/processes"),
          },
          {
            name: "IP Addresses",
            description: "IP address discovered in your environment",
            href: "/assets/ips",
            current: pathname.startsWith("/assets/ips"),
          },
          {
            name: "Domains",
            description: "Domains associated with events in your environment",
            href: "/assets/domains",
            current: pathname.startsWith("/assets/domains"),
          },
          {
            name: "Files",
            description: "Files pulled from detections on your endpoints",
            href: "/assets/files",
            current: pathname.startsWith("/assets/files"),
          },
        ],
      },
    ];
    let teamSettings: NavigationOption = {
      name: "Settings",
      href: "/settings",
      current: pathname.startsWith("/settings"),
      children: [
        {
          name: "Profile",
          description: "Edit your profile information",
          href:
            user.parentTeamId != team.id
              ? `/settings/profile?switchTeamId=${user.parentTeamId}`
              : "/settings/profile",
          target: user.parentTeamId != team.id ? "_blank" : undefined,
          current: pathname.startsWith("/settings/profile"),
        },
        {
          name: "Notifications",
          description: "Subscribe to platform alerts",
          href: "/settings/notifications",
          current: pathname.startsWith("/settings/notifications"),
        },
        {
          name: "Team",
          description: "Manage team settings",
          href: "/settings/team",
          current: pathname.startsWith("/settings/team"),
        },
        {
          name: "Integrations",
          description: "Integrate with external platforms",
          href: "/settings/integrations",
          current: pathname.startsWith("/settings/integrations"),
        },
        {
          name: "Chat Ops",
          description: "Configure settings for chat operations",
          href: "/settings/chat-ops",
          current: pathname.startsWith("/settings/chat-ops"),
        },
        {
          name: "Containment",
          description: "Manage auto containment of endpoints and identities",
          href: "/settings/containment",
          current: pathname.startsWith("/settings/containment"),
        },
        {
          name: "Exclusions & Automations",
          description: "Detection exclusions and asset automations",
          requiredRole: ROLE.ANALYST,
          href: "/settings/automation",
          current: pathname.startsWith("/settings/automation"),
        },
        {
          name: "System Log",
          description: "View system logs for the platform",
          href: "/settings/system-log",
          current: pathname.startsWith("/settings/system-log"),
        },
      ],
    };
    if (team.stripeCustomer) {
      teamSettings.children?.push({
        name: (
          <div className="flex items-center gap-1">
            Billing <ArrowTopRightOnSquareIcon className="w-4 h-4" />
          </div>
        ),
        description: "Manage billing and subscription preferences",
        onClick: billing,
        requiredRole: ROLE.ADMIN,
        current: pathname.startsWith("/settings/billing"),
      });
    }
    options.push(teamSettings);
    if (team.serviceProvider) {
      options = [
        {
          name: "Dashboard",
          href: `${SERVICE_PROVIDER_PATH}/dashboard`,
          current: pathname.startsWith(`${SERVICE_PROVIDER_PATH}/dashboard`),
        },
        {
          name: "Cases",
          href: `${SERVICE_PROVIDER_PATH}/cases`,
          current: pathname.startsWith(`${SERVICE_PROVIDER_PATH}/cases`),
        },
        {
          name: "Clients",
          href: `${SERVICE_PROVIDER_PATH}/clients`,
          current: pathname.startsWith(`${SERVICE_PROVIDER_PATH}/clients`),
        },
        {
          name: "Settings",
          current: pathname.startsWith(`${SERVICE_PROVIDER_PATH}/settings`),
          children: [
            {
              name: "Profile",
              description: "Edit your profile information",
              href:
                user.parentTeamId != team.id
                  ? `/settings/profile?switchTeamId=${user.parentTeamId}`
                  : `/settings/profile`,
              target: user.parentTeamId != team.id ? "_blank" : undefined,
              current: pathname.startsWith("/settings/profile"),
            },
            {
              name: "Team",
              description: "Manage team settings",
              href: `${SERVICE_PROVIDER_PATH}/settings/team`,
              current: pathname.startsWith(
                `${SERVICE_PROVIDER_PATH}/settings/team`
              ),
            },
            {
              name: "Notifications",
              description: "Subscribe to client alerts",
              href: `${SERVICE_PROVIDER_PATH}/settings/notifications`,
              current: pathname.startsWith(
                `${SERVICE_PROVIDER_PATH}/settings/notifications`
              ),
            },
            {
              name: "Integrations",
              description: "View integrations for all of your clients",
              href: `${SERVICE_PROVIDER_PATH}/settings/integrations`,
              current: pathname.startsWith(
                `${SERVICE_PROVIDER_PATH}/settings/integrations`
              ),
            },
            {
              name: "Add New Client",
              description: "Add a new client",
              href: `${SERVICE_PROVIDER_PATH}/settings/add-new-client`,
              current: pathname.startsWith(
                `${SERVICE_PROVIDER_PATH}/settings/add-new-client`
              ),
            },
            {
              name: "System Log",
              description: "View system logs for the platform",
              href: `${SERVICE_PROVIDER_PATH}/settings/system-log`,
              current: pathname.startsWith(
                `${SERVICE_PROVIDER_PATH}/settings/system-log`
              ),
            },
          ],
        },
      ];
    }
    if (user.superAdmin) {
      options.push({
        name: "Admin",
        current: pathname.startsWith("/admin"),
        children: [
          {
            name: "Queues",
            description: "Observe and manage background queues",
            href: "/admin/queues",
            current: pathname.startsWith("/admin/queues"),
          },
          {
            name: "Integrations",
            description: "Configure backend keys for integration platforms",
            href: "/admin/integrations",
            current: pathname.startsWith("/admin/integrations"),
          },
          {
            name: "Add New Team",
            description: "Invite a new team to the platform",
            href: "/admin/team",
            current: pathname.startsWith("/admin/team"),
          },
          {
            name: "Extraction Queue",
            description:
              "View detections that were unable to be mapped to an existing category",
            href: "/admin/extraction-queue",
            current: pathname.startsWith("/admin/extraction-queue"),
          },
          {
            name: "AQL",
            description: "Process the latest AQL batches",
            href: "/admin/aql",
            current: pathname.startsWith("/admin/aql"),
          },
          {
            name: "Tim's Shack",
            description: "Global views of platform data",
            href: "/admin/tim",
            current: pathname.startsWith("/admin/tim"),
          },
          {
            name: "Onboarding Demo",
            description: "Go to the onboarding demo",
            href: "/user-onboarding/1",
            current: pathname.startsWith("/user-onboarding/1"),
          },
          {
            name: "File Enrichment",
            description: "Enrich files using ReversingLabs",
            href: "/admin/file-enrichment",
            current: pathname.startsWith("/admin/file-enrichment"),
          },
          {
            name: "Verdict Flow",
            description: "UI visualizer and editor for verdict flows",
            href: "/admin/flow",
            current: pathname.startsWith("/admin/flow"),
          },
        ],
      });
    }

    // Handle role authorization
    return options
      .map((v) => {
        if (v.children == null) return v;
        v.children = v.children.filter((c) => {
          if (c.requiredRole == null) return true;
          return userHasRole(user.role, c.requiredRole);
        });
        return v;
      })
      .filter(
        (v) => v.requiredRole == null || userHasRole(user.role, v.requiredRole)
      );
  }, [pathname, team]);

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setCmdOpen((open) => !open);
      }
    };

    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, []);

  function stopImpersonating() {
    setImpersonatedAPIToken();
    window.location.reload();
  }

  const [hideDemo, setHideDemo] = useState(
    localStorage.getItem("hideDemo") === "true"
  );

  useEffect(() => {
    localStorage.setItem("hideDemo", hideDemo.toString());
  }, [hideDemo]);
  const impersonating = useMemo(isImpersonating, [user]);

  const shouldShowDemoToggle = useMemo(() => {
    // Make sure there is at least one demo team available, and at least one non-demo team available
    return (
      teams.filter((v) => v.demo).length > 0 &&
      teams.filter((v) => !v.demo).length > 0
    );
  }, [teams]);

  const selectableTeams = useMemo(() => {
    return teams.filter((v) => v.id == team.id || (hideDemo ? !v.demo : true));
  }, [teams, hideDemo]);

  return (
    <header
      className={cn(
        "sticky truncate top-0 border-b bg-background z-10 px-4 xl:px-6"
      )}
    >
      <div className="flex h-16 gap-4 items-center">
        <nav className="flex flex-row text-lg w-full font-medium">
          <Link
            to="/dashboard"
            className="flex items-center gap-2 text-lg font-semibold"
          >
            <div className="h-9 w-9 rounded-md border flex items-center justify-center shadow-sm hover:bg-muted">
              <img src={team.logoUrl} className="h-6 w-6" alt="Team Logo" />
            </div>
            <span className="sr-only">Wirespeed Logo</span>
          </Link>
          <div className="hidden xl:flex xl:flex-row xl:items-center ml-4">
            {navigation.map((v) => {
              if (v.children != null) {
                return (
                  <DropdownMenu key={v.name}>
                    <DropdownMenuTrigger asChild>
                      <Button
                        variant="ghost"
                        className={cn("text-muted-foreground cursor-pointer", {
                          "text-foreground": v.current,
                        })}
                      >
                        {v.name}
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent
                      align="start"
                      className="text-sm max-w-[600px]"
                    >
                      <DropdownMenuLabel className="px-4">
                        {v.name}
                      </DropdownMenuLabel>
                      <DropdownMenuSeparator />
                      <DropdownMenuGroup className="gap-2 grid grid-cols-2 p-2">
                        {v.children
                          .filter(
                            (v) =>
                              v.requiredRole == null ||
                              userHasRole(user.role, v.requiredRole)
                          )
                          .map((x, idx) => (
                            <DropdownMenuItem
                              disabled={x.disabled}
                              key={x.href}
                              asChild
                            >
                              <Link
                                onClick={x.disabled ? undefined : x.onClick}
                                target={x.target}
                                className={cn(
                                  "p-2 cursor-pointer rounded-md hover:bg-muted",
                                  {
                                    "col-span-2":
                                      // If it's the last item in the list and uneven amount of items, take up full width
                                      v.children != null &&
                                      idx == v.children.length - 1 &&
                                      (idx + 1) % 2 == 1,
                                    "bg-muted": x.current,
                                  }
                                )}
                                to={x.href}
                              >
                                <div>
                                  <p
                                    className={cn("font-medium", {
                                      "text-sm font-semibold": x.current,
                                    })}
                                  >
                                    {x.name}
                                  </p>
                                  <p
                                    className={cn(
                                      "text-muted-foreground transition-colors"
                                    )}
                                  >
                                    {x.description}
                                  </p>
                                </div>
                              </Link>
                            </DropdownMenuItem>
                          ))}
                      </DropdownMenuGroup>
                    </DropdownMenuContent>
                  </DropdownMenu>
                );
              } else {
                return (
                  <Link
                    key={v.href}
                    to={v.href}
                    className={cn(
                      "text-muted-foreground transition-colors hover:text-foreground",
                      { "text-foreground": v.current }
                    )}
                  >
                    <Button variant="ghost">{v.name}</Button>
                  </Link>
                );
              }
            })}
          </div>
        </nav>
        <div>
          <Sheet open={open} onOpenChange={setOpen}>
            <SheetContent side="right" className="overflow-auto">
              <nav className="grid gap-6 overflow-auto text-lg font-medium">
                <Link
                  to="/dashboard"
                  className="flex items-center gap-2 text-lg font-semibold"
                >
                  <Logo words className="h-8 w-auto ml-0" />
                  <span className="sr-only">Wirespeed</span>
                </Link>
                {showTeamSelector && (
                  <div className="flex flex-col gap-1">
                    <Label className="text-muted-foreground text-xs">
                      Team
                    </Label>
                    <Combobox
                      defaultValue={team.id}
                      values={selectableTeams.map((v) => ({
                        previewLabel: v.name,

                        label: (
                          <div className="flex flex-col gap-1">
                            <div>{v.name}</div>
                            <div className="text-muted-foreground text-xs">
                              {v.id == user.parentTeamId
                                ? "Parent Tenant"
                                : v.serviceProvider
                                  ? "Service Provider"
                                  : v.parentTeamName
                                    ? `Managed by ${v.parentTeamName}`
                                    : ""}
                            </div>
                          </div>
                        ),
                        value: v.id,
                      }))}
                      placeholder="Select team"
                      emptyMessage="Team not found"
                      onSelect={(v) => switchTeam(v)}
                    />
                  </div>
                )}
                {navigation.map((v) => {
                  if (v.children != null) {
                    return (
                      <div key={`mobile-${v.href}`}>
                        <div className="text-muted-foreground">{v.name}</div>
                        <div className="ml-4 flex flex-col  gap-4 mt-4">
                          {v.children.map((c) => (
                            <Link
                              disabled={c.disabled}
                              onClick={() => setOpen(false)}
                              key={`mobile-${c.href}`}
                              to={c.href}
                              className={cn(
                                "text-muted-foreground transition-colors hover:text-foreground",
                                { "text-foreground": c.current }
                              )}
                            >
                              {c.name}
                            </Link>
                          ))}
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <Link
                        onClick={() => setOpen(false)}
                        key={`mobile-${v.href}`}
                        to={v.href}
                        className={cn(
                          "text-muted-foreground transition-colors hover:text-foreground",
                          { "text-foreground": v.current }
                        )}
                      >
                        {v.name}
                      </Link>
                    );
                  }
                })}
              </nav>
            </SheetContent>
          </Sheet>
        </div>

        <div className="flex flex-shrink-0 ml-auto items-center gap-2">
          {impersonating && (
            <div className="max-w-96 flex">
              <div
                onClick={stopImpersonating}
                className="hover:bg-muted hidden lg:block truncate cursor-pointer shadow-sm border  border-input h-9 rounded-md p-2 text-xs font-semibold  text-red-500"
              >
                Impersonating {user.email}
              </div>
            </div>
          )}
          {team.serviceProvider && (
            <div
              onClick={() => showArticle(DOCS.SERVICE_PROVIDER)}
              className="hover:bg-muted hidden xl:flex cursor-pointer shadow-sm border border-input h-9 rounded-md p-2 text-xs font-semibold flex-shrink-0 items-center text-blue-500"
            >
              Service Provider
            </div>
          )}
          {team.demo && (
            <div
              onClick={() => showArticle(DOCS.TEAM)}
              className="hover:bg-muted xl:flex hidden cursor-pointer shadow-sm border border-input h-9 rounded-md p-2 text-xs font-semibold flex-shrink-0 items-center text-amber-500"
            >
              Demo Team
            </div>
          )}
          {team.testMode && (
            <div
              onClick={() => showArticle(DOCS.TEAM)}
              className="hover:bg-muted cursor-pointer shadow-sm border border-input h-9 rounded-md p-2 text-xs font-semibold flex-shrink-0 items-center flex text-amber-500"
            >
              Test Mode
            </div>
          )}
          {showTeamSelector && (
            <div className="hidden xl:block">
              <Combobox
                popoverClassName="w-full max-w-[400px]"
                footer={
                  shouldShowDemoToggle && (
                    <div>
                      <div className="py-2 border-t px-4 text-xs text-muted-foreground flex items-center gap-2">
                        <Label>Hide demo teams</Label>
                        <Switch
                          checked={hideDemo}
                          onCheckedChange={setHideDemo}
                        />
                      </div>
                    </div>
                  )
                }
                value={team.id}
                defaultValue={team.id}
                values={selectableTeams.map((v) => ({
                  previewLabel: v.name,
                  label: (
                    <div className="flex flex-col gap-1">
                      <div>{v.name}</div>
                      <div className="text-muted-foreground text-xs">
                        {v.id == user.parentTeamId
                          ? "Parent Tenant"
                          : v.serviceProvider
                            ? "Service Provider"
                            : v.parentTeamName
                              ? `Managed by ${v.parentTeamName}`
                              : ""}
                      </div>
                    </div>
                  ),
                  value: v.id,
                }))}
                placeholder="Select team"
                emptyMessage="Team not found"
                onSelect={(v) => switchTeam(v)}
              />
            </div>
          )}
          <ModeToggle />
          <a href="https://docs.wirespeed.co" target="_blank">
            <Button className="h-9 w-9 text-lg font-light" variant="outline">
              ?
            </Button>
          </a>
          <div className="hidden rounded-md shadow-sm xl:block flex-initial">
            <Button
              className="h-9 w-9 flex-shrink-0"
              variant="outline"
              onClick={() => setCmdOpen(true)}
              size="icon"
            >
              <MagnifyingGlassIcon className="h-5 w-5" />
              <span className="sr-only">Search Cases</span>
            </Button>
            <CommandDialog
              commandProps={{ shouldFilter: false }}
              open={cmdOpen}
              onOpenChange={setCmdOpen}
            >
              <CommandInput
                onValueChange={setSearch}
                placeholder="Search cases..."
              />
              <CommandList>
                <CommandGroup heading="Cases">
                  <CommandEmpty>No results found.</CommandEmpty>
                  {detectionData?.data.map((v) => (
                    <CommandItem
                      onSelect={() => {
                        // eslint-disable-next-line @typescript-eslint/no-floating-promises
                        navigate({
                          to: "/cases/$caseId",
                          params: { caseId: v.id },
                        });
                        setCmdOpen(false);
                      }}
                      key={v.id}
                      value={v.id}
                    >
                      <div>
                        <p>{v.name}</p>
                        <p className="text-muted-foreground text-xs">{v.sid}</p>
                      </div>
                    </CommandItem>
                  ))}
                </CommandGroup>
              </CommandList>
            </CommandDialog>
          </div>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button
                className="h-9 w-9 flex-shrink-0"
                variant="outline"
                size="icon"
              >
                <UserIcon className="h-5 w-5" />
                <span className="sr-only">Toggle user menu</span>
              </Button>
            </DropdownMenuTrigger>

            <DropdownMenuContent align="end">
              <DropdownMenuLabel>My Account</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuItem asChild>
                <Link
                  className="cursor-pointer"
                  target="_blank"
                  to="/settings/profile"
                  search={{ switchTeamId: user.parentTeamId }}
                >
                  Settings
                </Link>
              </DropdownMenuItem>
              {/* <DropdownMenuItem>Support</DropdownMenuItem> */}
              <DropdownMenuSeparator />
              <DropdownMenuItem
                className="cursor-pointer"
                onClick={() => logout()}
              >
                Logout
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
          <Button
            onClick={() => setOpen(true)}
            variant="outline"
            size="icon"
            className="shrink-0 xl:hidden"
          >
            <Bars3Icon className="h-5 w-5" />
            <span className="sr-only">Toggle navigation menu</span>
          </Button>
        </div>
      </div>
      {impersonating && (
        <div title={user.email} className="flex">
          <div
            onClick={stopImpersonating}
            className="hover:bg-muted lg:hidden mb-4 truncate cursor-pointer shadow-sm border  border-input h-9 rounded-md p-2 text-xs font-semibold  text-red-500"
          >
            Impersonating {user.email}
          </div>
        </div>
      )}
    </header>
  );
}
