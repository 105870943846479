import { motion } from "framer-motion";

export default function Underline(props: { className?: string }) {
  return (
    <motion.svg
      className={props.className}
      viewBox="0 0 100 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        initial={{ pathLength: 0, strokeWidth: 0 }}
        animate={{ pathLength: 1, strokeWidth: 1 }}
        transition={{
          duration: 0.25,
          delay: 0.5,
        }}
        d="M8.8157 2.97493C10.477 2.92219 12.139 2.70124 13.7946 2.57408C25.5413 1.67194 37.3754 1.43068 49.1492 1.78083C60.0107 2.10385 70.8498 3.0115 81.6431 4.2492C85.8355 4.72996 90.0137 5.329 94.1663 6.08043C94.8155 6.19789 95.9087 6.40435 96.5883 6.54457C97.0774 6.64549 98.5385 6.89008 98.0397 6.91588C96.5176 6.99461 94.6702 6.60228 93.1958 6.47706C87.9787 6.03397 82.7629 5.72038 77.5249 5.66693C60.6474 5.49471 43.7168 6.9742 27.069 9.71758C18.5906 11.1147 10.276 13.1558 1.98022 15.3547"
        strokeWidth="1px"
        strokeLinecap="round"
      />
    </motion.svg>
  );
}
