import { AnnotationNodeProps } from "@/components/flow/editor/node/annotation-node-editor";
import { BaseCustomNode } from "@/components/flow/node/base-custom-node";
import markdownToHtml from "@/lib/markdownToHtml";
import { cn } from "@/lib/utils";
import { FlowNodeType } from "@wire/shared";
import { Node, NodeProps } from "@xyflow/react";
import { memo, useMemo } from "react";

export const annotationArrowPositions = [
  {
    name: "Up",
    value: "up",
    className: "absolute top-0 left-1/2 -translate-x-1/2 -translate-y-full",
    arrow: "↑",
  },
  {
    name: "Up Left",
    value: "up-left",
    className: "rotate-180 absolute -left-9 -top-9",
    arrow: "⤵",
  },
  {
    name: "Up Right",
    value: "up-right",
    className: "rotate-180 scale-x-[-1] absolute -right-9 -top-9",
    arrow: "⤵",
  },
  {
    name: "Down",
    value: "down",
    className: "absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-full",
    arrow: "↓",
  },
  {
    name: "Down Left",
    value: "down-left",
    className: "absolute scale-x-[-1] -left-9 -bottom-9",
    arrow: "⤵",
  },
  {
    name: "Down Right",
    value: "down-right",
    className: "absolute -right-9 -bottom-9",
    arrow: "⤵",
  },
  {
    name: "Left",
    value: "left",
    className: "absolute -left-9 top-1/2 -translate-y-1/2",
    arrow: "←",
  },
  {
    name: "Left Up",
    value: "left-up",
    className: "rotate-270 scale-x-[-1] absolute -left-9 -top-9",
    arrow: "⤴",
  },
  {
    name: "Left Down",
    value: "left-down",
    className: "-rotate-90 -scale-x-[1] absolute -left-9 -bottom-9",
    arrow: "⤴",
  },
  {
    name: "Right",
    value: "right",
    className: "absolute -right-9 top-1/2 -translate-y-1/2",
    arrow: "→",
  },
  {
    name: "Right Down",
    value: "right-down",
    className: "rotate-90 absolute -right-9 -bottom-9",
    arrow: "⤴",
  },
  {
    name: "Right Up",
    value: "right-up",
    className: "rotate-90 scale-x-[-1] absolute -right-9 -top-9",
    arrow: "⤴",
  },
  {
    name: "None",
    value: "none",
    className: "hidden",
    arrow: "",
  },
];

export const defaultAnnotationNodeProps: AnnotationNodeProps = {
  annotation: "## Annotation",
  backgroundColor: "#ffffff",
  borderColor: "#000000",
};

function AnnotationNode(props: NodeProps<Node<AnnotationNodeProps>>) {
  const html = useMemo(
    () =>
      markdownToHtml(
        props.data.annotation != null && props.data?.annotation?.length > 0
          ? props.data.annotation
          : "## Annotation"
      ),
    [props.data?.annotation]
  );

  const arrow = useMemo(() => {
    if (props.data?.arrowPosition == null) return;
    const arrow = annotationArrowPositions.find(
      (v) => v.value == props.data.arrowPosition
    );
    if (arrow == null) return;
    return <div className={cn(arrow.className, "text-4xl")}>{arrow.arrow}</div>;
  }, [props.data?.arrowPosition]);

  return (
    <BaseCustomNode
      {...props}
      noEndHandle
      noStartHandle
      optionalTitle
      updateNode={() => {}}
      data={{ ...props.data, type: FlowNodeType.ANNOTATION }}
    >
      <div className="flex  rounded-md">
        <div
          className="prose prose-sm prose-headings:my-2 prose-headings:text-foreground prose-p:text-foreground prose-a:text-foreground"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
      <span style={{ color: props.data?.borderColor }}>{arrow}</span>
    </BaseCustomNode>
  );
}

let AnnotationNodeMemo = memo(AnnotationNode);

export { AnnotationNodeMemo as AnnotationNode };
