import { Badge, BadgeProps } from "@/components/ui/badge";
import {
  getStatusConfigByStatus,
  getVerdictConfigByVerdict,
  Status,
  Verdict,
} from "@wire/shared";

export function getVerdictBadgeVariant(
  verdict: Verdict,
  status?: Status
): BadgeProps["variant"] {
  switch (verdict) {
    case Verdict.MALICIOUS:
      if (status != null && status == Status.CLOSED) {
        return "outlineDestructive";
      }
      return "destructive";
    case Verdict.BENIGN:
      return "outlineSuccess";
    case Verdict.SUSPICIOUS:
      return "outlineWarning";
  }
}
export function getVerdictBadge(verdict: Verdict) {
  let config = getVerdictConfigByVerdict(verdict);
  let variant = getVerdictBadgeVariant(verdict);

  return (
    <Badge className="whitespace-nowrap" variant={variant}>
      {config.display}
    </Badge>
  );
}

export function getCaseStatusBadgeVariant(status: Status) {
  let variant: BadgeProps["variant"] = "outline";
  switch (status) {
    case Status.CLOSED:
      variant = "outlineSuccess";
      break;
    case Status.NEW:
      variant = "outlineWarning";
      break;
    case Status.PROCESSING:
      variant = "outlineWarning";
      break;
    case Status.ESCALATED:
      variant = "destructive";
      break;
  }
  return variant;
}

export function getCaseStatusBadge(status: Status) {
  let config = getStatusConfigByStatus(status);
  let variant = getCaseStatusBadgeVariant(status);
  return (
    <Badge className="whitespace-nowrap" variant={variant}>
      {config.display}
    </Badge>
  );
}
