import { createFileRoute, useNavigate } from "@tanstack/react-router";

import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { PhoneInput } from "@/components/ui/phone-input";
import { apiClient } from "@/lib/api";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Logo } from "@/components/ui/logo";
import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import { toast } from "sonner";

async function getRegisterUser(id: string) {
  const response = await apiClient.GET("/auth/register/{id}", {
    params: { path: { id } },
  });
  if (response.error != null) {
    throw new Error("Error getting register information");
  }
  return response.data;
}

const getRegisterUserOptions = (id: string) =>
  queryOptions({
    queryKey: ["register-user"],
    queryFn: () => getRegisterUser(id),
  });

export const Route = createFileRoute("/_authentication/register_/$token")({
  loader: ({ params }) => getRegisterUserOptions(params.token),
  component: RegisterForm,
});

const formSchema = z
  .object({
    email: z.string().email().min(2, {}),
    name: z.string().min(1, {}).max(512, {}),
    phoneNumber: z
      .string()
      .startsWith("+", "Please include your country code (e.g. +1)")
      .min(10, {})
      .max(25, {}),
    password: z.string().min(12).max(256),
    confirmPassword: z.string().min(12).max(256),
    tos: z.boolean(),
  })
  .refine((input) => input.password == input.confirmPassword, {
    message: "Passwords do not match",
    path: ["confirmPassword"],
  });

export default function RegisterForm() {
  const navigate = useNavigate();
  const { token } = Route.useParams();
  const { data: registerUser } = useSuspenseQuery(
    getRegisterUserOptions(token)
  );

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      password: "",
      confirmPassword: "",
      phoneNumber: "",
      name: "",
      tos: false,
    },
  });

  useEffect(() => {
    if (registerUser == undefined) return;
    form.setValue("email", registerUser.email);
    if (registerUser.name) {
      form.setValue("name", registerUser.name);
    }
  }, [registerUser]);

  async function onSubmit(values: z.infer<typeof formSchema>) {
    const response = await apiClient.POST("/auth/register/{id}", {
      body: {
        name: values.name,
        password: values.password,
        tos: values.tos,
        phoneNumber: values.phoneNumber,
      },
      params: { path: { id: token } },
    });
    if (response.error != null) {
      toast.error(response.error.message);
      return;
    }
    await navigate({ to: "/register/$token/mfa", params: { token } });
  }

  return (
    <div className="flex min-h-full flex-1 flex-col items-stretch justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <Logo words />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight ">
          Register your account
        </h2>
      </div>
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Name</FormLabel>
                  <FormControl>
                    <Input placeholder="Clara Oswald" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input
                      disabled
                      autoComplete="email"
                      type="email"
                      placeholder="me@wirespeed.co"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="phoneNumber"
              render={({ field }) => (
                <FormItem className="flex flex-col items-start">
                  <FormLabel className="text-left">Mobile Phone</FormLabel>
                  <FormControl className="w-full">
                    <PhoneInput
                      autoComplete="tel"
                      defaultCountry="US"
                      placeholder="(612) 867-5309"
                      {...field}
                    />
                  </FormControl>
                  <FormDescription className="text-left">
                    Phone number will only be used for critical security
                    escalations
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Password</FormLabel>
                  <FormControl>
                    <Input
                      autoComplete="password"
                      type="password"
                      placeholder="Password"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="confirmPassword"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Confirm Password</FormLabel>
                  <FormControl>
                    <Input
                      type="password"
                      autoComplete="password"
                      placeholder="Confirm Password"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="tos"
              render={({ field }) => (
                <FormItem className="flex items-center space-y-0">
                  <FormControl>
                    <Checkbox
                      required
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <FormLabel className="ml-2">
                    I accept the{" "}
                    <a
                      target="_blank"
                      className="text-blue-500 hover:underline"
                      href="https://wirespeed.co/legal/msa.pdf"
                    >
                      terms and conditions
                    </a>
                  </FormLabel>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button disabled={form.formState.isSubmitting} type="submit">
              Register
            </Button>
          </form>
        </Form>
      </div>
    </div>
  );
}
