import { hasValidAuthToken } from "@/lib/api";
import { update } from "@intercom/messenger-js-sdk";
import { Outlet, createFileRoute, redirect } from "@tanstack/react-router";
import { useEffect } from "react";

export const Route = createFileRoute("/_onboarding")({
  component: LayoutComponent,
  beforeLoad: ({ location }) => {
    if (!hasValidAuthToken()) {
      throw redirect({
        to: "/login",
        search: { redirect: location.pathname },
      });
    }
  },
});

function LayoutComponent() {
  useEffect(() => {
    update({ hide_default_launcher: true });
  }, []);

  return (
    <div className="bg-muted/40 min-h-screen">
      <Outlet />
    </div>
  );
}
