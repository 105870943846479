"use client";

import * as React from "react";

import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandInput,
  CommandInputBlank,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { ReactNode } from "react";

interface ComboboxProps {
  values: { value: string; label: ReactNode; previewLabel?: string }[];
  placeholder: string;
  defaultValue?: string;
  footer?: React.ReactNode;
  value?: string | null;
  inlineInput?: boolean;
  emptyMessage: string;
  popoverClassName?: string;
  async?: boolean;
  loading?: boolean;
  onSearch?: (search: string) => void;
  onSelect: (value: string) => void;
}

export function Combobox(props: ComboboxProps) {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(props.defaultValue ?? props.value);

  function selectValue(value: string) {
    props.onSelect(value);
    setValue(value);
    setOpen(false);
  }

  React.useEffect(() => {
    if (props.value != null) {
      setValue(props.value);
    }
  }, [props.value]);

  const previewValue = React.useMemo(() => {
    if (value != null) {
      let match = props.values.find((v) => v.value === value);
      if (match != null) {
        return match.previewLabel ?? match.label;
      }
    }
    return props.placeholder;
  }, [value, props.values, props.placeholder]);

  const filter = React.useCallback(
    (value: string, search: string) => {
      if (value.toLowerCase().includes(search.toLowerCase())) return 1;
      let matchingValue = props.values.find((v) => v.value == value);
      if (
        typeof matchingValue?.label === "string" &&
        matchingValue?.label.toLowerCase().includes(search.toLowerCase())
      )
        return 1;
      if (
        typeof matchingValue?.previewLabel === "string" &&
        matchingValue?.previewLabel.toLowerCase().includes(search.toLowerCase())
      )
        return 1;
      return 0;
    },
    [props.values, props.async]
  );

  const commandItems = React.useMemo(() => {
    return props.values?.map((option) => (
      <CommandItem
        key={option.value}
        className="break-words cursor-pointer hyphens-auto"
        value={option.value}
        onSelect={selectValue}
      >
        <CheckIcon
          className={cn(
            "mr-2 h-4 w-4 flex-shrink-0",
            value === option.value ? "opacity-100" : "opacity-0"
          )}
        />
        {option.label}
      </CommandItem>
    ));
  }, [props.values, props.value, value]);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-[200px] font-normal justify-between"
        >
          <span className="truncate">{previewValue}</span>

          <ChevronDownIcon
            className={cn(
              "ml-2 h-4 w-4 shrink-0 opacity-50 transition-transform",
              {
                "rotate-180": open,
              }
            )}
          />
        </Button>
      </PopoverTrigger>
      <PopoverContent className={cn("p-0", props.popoverClassName)}>
        <Command shouldFilter={!props.async} filter={filter}>
          {props.inlineInput ? (
            <CommandInputBlank
              onInput={(e) => props.onSearch?.(e.currentTarget.value)}
            />
          ) : (
            <CommandInput
              placeholder={props.placeholder}
              onInput={(e) => props.onSearch?.(e.currentTarget.value)}
            />
          )}
          <CommandEmpty>{props.emptyMessage}</CommandEmpty>
          <CommandList>{commandItems}</CommandList>
        </Command>
        {props.footer}
      </PopoverContent>
    </Popover>
  );
}
