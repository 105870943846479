"use client";
import SystemLogSettings, {
  getSystemLogQueryOptions,
} from "@/components/settings/system-log";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_application/sp/settings/system-log")({
  loader: async ({ context }) => {
    await context.queryClient.ensureQueryData(getSystemLogQueryOptions({}));
  },
  component: SystemLogSettings,
});
