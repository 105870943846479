"use client";
import { AppLayout } from "@/components/app-layout";
import CopyToClipboard from "@/components/copy-to-clipboard";
import { IntegrationLogo } from "@/components/settings/add-integration";
import { TableCard } from "@/components/table-card";
import { CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { apiClient } from "@/lib/api";
import { components } from "@/lib/api.types";
import { dateTimeBuilder } from "@/lib/time";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import {
  IntegrationPlatform,
  getIntegrationConfigByPlatform,
  getIntegrationTypeConfigByType,
} from "@wire/shared";
import { useState } from "react";

export const Route = createFileRoute("/_application/sp/settings/integrations")({
  loader: async ({ context }) => {
    await context.queryClient.ensureQueryData(getIntegrationQueryOptions({}));
  },
  component: IntegrationSettings,
});

async function searchIntegrations(dto: components["schemas"]["PaginationDto"]) {
  const response = await apiClient.POST("/integration", {
    body: { ...dto, includeDisabled: true },
  });
  if (response.error != null) {
    throw new Error("Error searching integration");
  }
  return response.data;
}

const getIntegrationQueryOptions = (
  searchSettings: components["schemas"]["PaginationDto"]
) => ({
  queryKey: [INTEGRATION_SETTINGS_QUERY, searchSettings],
  queryFn: () => searchIntegrations(searchSettings),
  placeholderData: keepPreviousData,
});

export const INTEGRATION_SETTINGS_QUERY = "settings-integration";

export default function IntegrationSettings() {
  const [searchSettings, setSearchSettings] = useState<
    components["schemas"]["PaginationDto"]
  >({});
  useState<IntegrationPlatform>();
  const integrationQuery = useQuery(getIntegrationQueryOptions(searchSettings));

  return (
    <AppLayout>
      <div className="flex flex-col gap-4">
        <TableCard
          query={integrationQuery}
          onUpdate={setSearchSettings}
          onClick={(row) =>
            window.open(
              `/settings/integrations/${row.id}?switchTeamId=${row.teamId}`,
              "_blank"
            )
          }
          headers={[
            {
              key: "id",
              display: "Name",
              format: (val, row) => {
                let config = getIntegrationConfigByPlatform(row.platform);
                return (
                  <div className="flex items-center space-x-2">
                    <IntegrationLogo platform={row.platform} />
                    <div className="flex flex-col">
                      <span>{config.display}</span>
                      <span className="text-xs text-muted-foreground">
                        {config.types
                          .map((v) => getIntegrationTypeConfigByType(v).display)
                          .join(", ")}
                      </span>
                    </div>
                  </div>
                );
              },
            },
            {
              key: "teamName",
              display: "Client",
            },
            {
              key: "identifier",
              display: "Identifier",
              format(value, row) {
                if (value == null)
                  return <div className="text-muted-foreground text-xs">-</div>;
                let label = value.split(" | ")[0];
                let display = value.split(" | ")[1];
                return (
                  <div className="text-muted-foreground text-xs">
                    {label} |{" "}
                    <CopyToClipboard showIcon iconOnlyClick text={display} />
                  </div>
                );
              },
            },
            {
              key: "enabled",
              display: "Enabled",
            },
            {
              key: "healthy",
              display: "Healthy",
            },
            {
              key: "lastUsedAt",
              display: "Last Used",
              format: (val) =>
                val != null ? dateTimeBuilder(val).fromNow() : "-",
            },
          ]}
        >
          <CardHeader>
            <CardTitle>Integrations</CardTitle>
            <CardDescription>
              To add integrations for a specific client, switch to that client
              in the top right.
            </CardDescription>
          </CardHeader>
        </TableCard>
      </div>
    </AppLayout>
  );
}
