import { err, ok, type Result } from "neverthrow";
import type {
  NewDetectionCategory,
  NewDetectionSubCategory,
} from "../../types/detection.types";
import type { Verdict } from "../../types/shared.types";
import {
  evaluateQuery,
  getDisplayNestedValue,
  getNestedValue,
} from "../query/detection-query";

export enum FlowNodeType {
  MATCH = "match",
  SWITCH = "switch",
  ACTION = "action",
  JUMP = "jump",
  ANNOTATION = "annotation",
}

export enum FlowEdgeType {
  MATCH = "match",
  SWITCH = "switch",
  JUMP = "jump",
  ACTION = "action",
}

export type FlowNode = {
  id: string;
  data: {
    title: string;
    description: string;
    entryNode?: boolean;
  };
  type: FlowNodeType;
} & (
  | {
      type: FlowNodeType.SWITCH;
      data: {
        fieldQuery: string;
      };
    }
  | {
      type: FlowNodeType.MATCH;
      data: {
        query: string;
      };
    }
  | {
      type: FlowNodeType.ACTION;
      data: VerdictStepResponse;
    }
  | {
      type: FlowNodeType.JUMP;
      data: {
        jumpToNodeId: string;
      };
    }
);

// Also update `action-node-editor.tsx`
export type VerdictStepResponse = {
  verdict: Verdict;
  subcategory: NewDetectionSubCategory;
  category: NewDetectionCategory;
  containOnChatOpsFailure?: boolean;
  chatOps?: boolean;
  escalate?: boolean;
  contain?: boolean;
  close?: boolean;
};

export type FlowEdge = {
  source: string;
  target: string;
  type: FlowEdgeType;
  id: string;
} & (
  | {
      type: FlowEdgeType.MATCH;
      data: {
        branch: boolean;
        log: string;
        shouldLog: boolean;
      };
    }
  | {
      type: FlowEdgeType.SWITCH;
      data: {
        fieldSelector: string;
        log: string;
        defaultEdge?: boolean;
        shouldLog: boolean;
      };
    }
  | {
      type: FlowEdgeType.JUMP;
      data: {
        log: string;
      };
    }
  | {
      type: FlowEdgeType.ACTION;
      data: {
        verdict: Verdict;
      };
    }
);

export type VerdictFlow = {
  nodes: FlowNode[];
  edges: FlowEdge[];
};

export type FlowVerdictorHistory = {
  nodeId?: string;
  edgeId?: string;
};

export type FlowVerdictorResult = {
  result: VerdictStepResponse;
  logs: string[];
  problemNodeId?: string;
  problemEdgeId?: string;
  history: FlowVerdictorHistory[];
  message?: string;
};
export function formatEdgeLog(log: string, data: any) {
  let out = "";
  const parts = log.split(/(`[^`]*`)/);
  for (const part of parts) {
    if (part.startsWith("`") && part.endsWith("`")) {
      out += getDisplayNestedValue(data, part.slice(1, -1)) as string;
    } else {
      out += part;
    }
  }
  return out;
}

export class FlowVerdictor {
  private edges: FlowEdge[];
  private data: any;
  private nodes: FlowNode[];
  private logs: string[];
  private problemNodeId?: string;
  private problemEdgeId?: string;
  private history: FlowVerdictorHistory[];
  private message?: string;

  constructor(
    data: any,
    flow: VerdictFlow,
    history?: FlowVerdictorHistory[] | null
  ) {
    this.nodes = flow.nodes;
    this.edges = flow.edges;
    this.data = data;
    this.logs = [];
    this.history = history ?? [];
  }

  verdict(): Result<FlowVerdictorResult, Error> {
    try {
      const start = this.getStartNode();
      let currentNode: FlowNode | null = start;
      let actionNode: FlowNode | null = null;
      while (true) {
        let nextNode = this.processNode(currentNode);
        if (nextNode == null) break;
        if (nextNode.type == FlowNodeType.ACTION) {
          actionNode = nextNode;
          break;
        }
        currentNode = nextNode;
      }
      if (actionNode == null) {
        this.message = "No action node found";
        this.problemNodeId = currentNode?.id;
        return err(new Error("No action node found"));
      }
      return ok({
        result: actionNode.data,
        logs: this.logs,
        problemNodeId: this.problemNodeId,
        problemEdgeId: this.problemEdgeId,
        history: this.history,
        message: this.message,
      });
    } catch (e) {
      console.error(e);
      return err(e as Error);
    }
  }

  private processNode(node: FlowNode): FlowNode | undefined {
    switch (node.type) {
      case FlowNodeType.SWITCH:
        return this.processSwitch(node);
      case FlowNodeType.MATCH:
        return this.processMatch(node);
      case FlowNodeType.JUMP:
        return this.processJump(node);
      case FlowNodeType.ACTION:
        return node;
    }
  }

  private processSwitch(node: FlowNode): FlowNode | undefined {
    if (node.type != FlowNodeType.SWITCH) {
      throw new Error("Node is not a switch");
    }
    const switchEdges: FlowEdge[] = this.edges.filter(
      (e) => e.source == node.id
    );
    if (switchEdges.length == 0) return;
    if (switchEdges.some((v) => v.type != FlowEdgeType.SWITCH)) {
      throw new Error("Switch has non switch edges");
    }
    if (node.data.fieldQuery == null) {
      throw new Error("Switch has no field query");
    }

    const switchValue = getNestedValue(this.data, node.data.fieldQuery);
    if (switchValue == null || switchValue.length == 0) {
      return this.processDefaultSwitchEdge(node);
    }

    for (const edge of switchEdges) {
      if (edge.type != FlowEdgeType.SWITCH) {
        throw new Error("Switch has non switch edges");
      }
      if (edge.data.fieldSelector == switchValue) {
        this.history.push({ edgeId: edge.id });
        if (edge.data.shouldLog) {
          this.processLog(edge.data.log);
        }
        return this.getNode(edge.target);
      }
    }
    return this.processDefaultSwitchEdge(node);
  }

  private processDefaultSwitchEdge(node: FlowNode): FlowNode {
    const switchEdges: FlowEdge[] = this.edges.filter(
      (e) => e.source == node.id
    );
    let defaultEdge = switchEdges.find(
      (e) => e.type == FlowEdgeType.SWITCH && e.data?.defaultEdge
    );
    if (defaultEdge != null && defaultEdge.type == FlowEdgeType.SWITCH) {
      this.history.push({ edgeId: defaultEdge.id });
      if (defaultEdge.data.shouldLog) {
        this.processLog(defaultEdge.data.log);
      }
      return this.getNode(defaultEdge.target);
    }
    throw new Error("Switch without default edge");
  }

  private continueProcessingActionNode(node?: FlowNode): FlowNode {
    if (node == null) throw new Error("Node not found");
    let edges = this.edges.filter((e) => e.source == node.id);
    if (edges.length == 0) throw new Error("No action edges found");
    for (const edge of edges) {
      if (this.processActionEdge(edge)) {
        return this.getNode(edge.target);
      }
    }
    throw new Error("No action edge found");
  }

  private getNode(id?: string, log = true): FlowNode {
    if (id == null) throw new Error("Node id not found");
    const node = this.nodes.find((n) => n.id == id);
    if (node == null) throw new Error("Node not found");
    if (log) {
      this.history.push({ nodeId: node.id });
    }
    return node;
  }

  private processActionEdge(edge: FlowEdge): boolean {
    if (edge.type != FlowEdgeType.ACTION) {
      throw new Error("Edge is not an action");
    }
    let response = edge.data.verdict == this.data.verdict;
    return response;
  }

  private processLog(log: string) {
    this.logs.push(formatEdgeLog(log, this.data));
  }

  private runQuery(query: string): boolean {
    return evaluateQuery(query, this.data);
  }

  private processJump(node: FlowNode): FlowNode | undefined {
    if (node.type != FlowNodeType.JUMP) {
      throw new Error("Node is not a jump");
    }
    let edge = this.edges.find(
      (e) => e.source == node.id && e.type == FlowEdgeType.JUMP
    );
    this.history.push({ edgeId: edge?.id });
    if (edge == null) return;
    return this.getNode(edge.target);
  }

  private processMatch(node: FlowNode): FlowNode | undefined {
    let edges = this.edges.filter((e) => e.source == node.id);
    if (edges.length == 0) return;
    let trueEdge = edges.find(
      (e) => e.type == FlowEdgeType.MATCH && e.data.branch
    );
    let falseEdge = edges.find(
      (e) => e.type == FlowEdgeType.MATCH && !e.data.branch
    );
    if (trueEdge == null || falseEdge == null) {
      throw new Error("Match must have true and false edges");
    }
    if (
      trueEdge?.type != FlowEdgeType.MATCH ||
      falseEdge?.type != FlowEdgeType.MATCH
    ) {
      throw new Error("Match has non match edges");
    }

    if (node.type != FlowNodeType.MATCH) {
      throw new Error("Node is not a match");
    }
    let out = this.runQuery(node.data.query);
    if (out) {
      if (trueEdge?.data.shouldLog) {
        this.processLog(trueEdge?.data.log);
      }
      if (trueEdge != null) {
        this.history.push({ edgeId: trueEdge.id });
      }
      return this.getNode(trueEdge.target);
    } else {
      if (falseEdge?.data.shouldLog) {
        this.processLog(falseEdge?.data.log);
      }
      if (falseEdge != null) {
        this.history.push({ edgeId: falseEdge.id });
      }
      return this.getNode(falseEdge.target);
    }
  }

  private getStartNode(): FlowNode {
    if (this.history.length > 0) {
      let lastHistoryItem = this.history[this.history.length - 1];
      if (lastHistoryItem.nodeId == null) {
        throw new Error("Invalid final element");
      }
      return this.continueProcessingActionNode(
        this.getNode(lastHistoryItem.nodeId, false)
      );
    }
    let start = this.nodes.find((n) => n.data.entryNode);
    if (start == null) throw new Error("Start node not found");
    this.history.push({ nodeId: start.id });
    return start;
  }
}
