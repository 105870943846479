import { createFileRoute } from "@tanstack/react-router";

import NotificationSettings from "@/components/settings/notifications";
import { getNotificationQueryOptions } from "@/components/settings/notifications.options";
export const Route = createFileRoute("/_application/sp/settings/notifications")(
  {
    loader: async ({ context }) => {
      await context.queryClient.ensureQueryData(getNotificationQueryOptions());
    },
    component: NotificationSettings,
  }
);
