import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { apiClient } from "@/lib/api";
import { showArticle } from "@intercom/messenger-js-sdk";
import { DOCS } from "@wire/shared";
import { useState } from "react";

export default function AWS(props: { onComplete: () => void }) {
  const [roleArn, setRoleArn] = useState<string>();
  const [externalId, setExternalId] = useState<string>();
  const [error, setError] = useState<string>();

  async function submit() {
    if (roleArn == null) {
      setError("Role ARN is required");
      return;
    }
    if (externalId == null) {
      setError("External ID is required");
      return;
    }

    const response = await apiClient.PUT("/integration/aws", {
      body: {
        roleArn,
        externalId,
      },
    });

    if (response.error != null) {
      setError(
        response.error.message ??
          "Error adding integration, please check the instructions"
      );
      return;
    }

    props.onComplete();
  }

  return (
    <>
      <div className="space-y-4">
        <div>
          <Label>Role ARN</Label>
          <Input
            value={roleArn}
            onChange={(e) => setRoleArn(e.target.value)}
            name="roleArn"
            type="text"
            placeholder="arn:aws:iam::123456789012:role/ExampleRole"
          />
        </div>

        <div>
          <Label>External ID</Label>
          <Input
            type="text"
            value={externalId}
            onChange={(e) => setExternalId(e.target.value)}
            name="externalId"
            placeholder="1234567890"
          />
        </div>
      </div>

      {error && <p className="text-sm text-red-500">{error}</p>}
      <div className="flex flex-col lg:flex-row gap-4">
        <Button onClick={submit}>Integrate</Button>
        <Button
          onClick={() => showArticle(DOCS.INTEGRATIONS.AWS)}
          variant="outline"
        >
          Integration Instructions
        </Button>
      </div>
    </>
  );
}
