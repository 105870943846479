import Footer from "@/components/footer";
import { PropsWithChildren } from "react";

export function AppLayout(props: PropsWithChildren<object>) {
  return (
    <div className="p-4 flex-1 flex flex-col relative h-full">
      {props.children}
      <Footer key="footer" />
    </div>
  );
}

export function ConstrainedAppLayout(props: PropsWithChildren<object>) {
  return (
    <div className="p-4 flex-1 flex flex-col relative h-full w-full max-w-7xl mx-auto">
      {props.children}
      <Footer key="footer" />
    </div>
  );
}
