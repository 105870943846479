import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Logo } from "@/components/ui/logo";
import { Skeleton } from "@/components/ui/skeleton";
import { apiClient } from "@/lib/api";
import { createFileRoute, SearchSchemaInput } from "@tanstack/react-router";
import { ChatOpsMessagePart, DOC_LINKS } from "@wire/shared";
import { useEffect, useMemo, useState } from "react";

export const Route = createFileRoute("/chat-ops")({
  component: ChatOps,
  validateSearch: (
    search: {
      jwt: string;
    } & SearchSchemaInput
  ) => {
    return {
      jwt: search.jwt,
    };
  },
});

function ChatOps() {
  const { jwt } = Route.useSearch();
  const data = useMemo(() => {
    return jwt.split(".")[1];
  }, [jwt]);
  const [loading, setLoading] = useState(true);
  const [messageParts, setMessageParts] = useState<ChatOpsMessagePart[][]>([]);
  const [welcomeMessage, setWelcomeMessage] = useState<string | null>(null);
  const [inputValues, setInputValues] = useState<Record<string, string>>({});
  useEffect(() => {
    (async () => {
      setMessageParts([]);
      await submitChatOps(jwt);
      setLoading(false);
    })().catch(alert);
  }, []);

  async function submitChatOps(choice: string) {
    const response = await apiClient.POST("/integration/email/chat-ops", {
      body: { inputs: inputValues as any, jwt: choice },
    });
    if (response.error != null) {
      alert(response.error.message);
      return;
    }
    let newParts = [...messageParts];
    if (response.data.updatePreviousMessage != null) {
      newParts.pop();
      newParts.push(response.data.updatePreviousMessage as any);
    }
    if (response.data.message != null) {
      newParts.push(response.data.message as any);
    }

    if (response.data.welcomeMessage != null) {
      setWelcomeMessage(response.data.welcomeMessage);
    }
    setMessageParts(newParts);
  }

  const handleInputChange = (id: string, value: string) => {
    setInputValues((prev) => ({ ...prev, [id]: value }));
  };

  const handleSubmit = async (choice: string) => {
    await submitChatOps(choice);
  };

  const footer = useMemo(() => {
    if (welcomeMessage != null) {
      return welcomeMessage;
    }
    return (
      <>
        This message was sent as part of{" "}
        <a
          className="text-blue-500 underline"
          href="https://wirespeed.co"
          target="_blank"
        >
          Wirespeed's
        </a>{" "}
        <a
          className="text-blue-500 underline"
          href={DOC_LINKS.CHAT_OPS_RECEIVED}
          target="_blank"
        >
          ChatOps
        </a>
        . Please contact your security team if you have any questions. Wirespeed
        will never ask for your password or personal information.
      </>
    );
  }, [welcomeMessage]);

  if (loading) {
    return <Skeleton className="max-w-2xl mx-auto h-96 mt-24" />;
  }

  return (
    <div className="max-w-2xl mx-auto mt-12 lg:mt-24 p-4 lg:p-0">
      <div className="flex flex-col gap-4">
        <Logo words className="mb-8" />
        {messageParts.map((v, idx) => (
          <MessageParts
            key={idx}
            messageParts={v}
            inputValues={inputValues}
            handleInputChange={handleInputChange}
            handleSubmit={handleSubmit}
          />
        ))}
        <p className="text-xs text-muted-foreground">{footer}</p>
      </div>
    </div>
  );
}

function MessageParts(props: {
  messageParts: ChatOpsMessagePart[];
  inputValues: Record<string, string>;
  handleInputChange: (id: string, value: string) => void;
  handleSubmit: (value: string) => void;
}) {
  const renderMessagePart = (part: ChatOpsMessagePart, key: string) => {
    switch (part.type) {
      case "title":
        return (
          <h1 className="text-2xl font-bold" key={part.id + key}>
            {part.message}
          </h1>
        );
      case "text":
        return (
          <p
            className=" [&_a]:text-blue-500 normal-lists"
            dangerouslySetInnerHTML={{ __html: part.message }}
            key={part.id + key}
          />
        );
      case "input":
        return (
          <Input
            key={part.id + key}
            type="text"
            placeholder={part.placeholder}
            value={props.inputValues[part.id] || ""}
            onChange={(e) => props.handleInputChange(part.id, e.target.value)}
          />
        );
      case "buttons":
        return (
          <div key={"button-group" + key} className="flex gap-2">
            {part.buttons.map((button) => (
              <Button
                key={button.id + key}
                onClick={() => props.handleSubmit(button.value)}
              >
                {button.text}
              </Button>
            ))}
          </div>
        );
    }
  };

  return props.messageParts.map((v, idx) => renderMessagePart(v, `${idx}`));
}
