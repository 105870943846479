import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { apiClient } from "@/lib/api";
import { components } from "@/lib/api.types";
import { cn } from "@/lib/utils";
import { showArticle } from "@intercom/messenger-js-sdk";
import { queryOptions, useQuery } from "@tanstack/react-query";
import { useRouteContext } from "@tanstack/react-router";
import { DOCS, IntegrationPlatform } from "@wire/shared";
import { useEffect, useMemo, useState } from "react";
import { toast } from "sonner";
import { OAuthButton } from "./oauth-button";

export function UpdateGooglePermissions(props: {
  integration: components["schemas"]["Integration"];
}) {
  const [url, setUrl] = useState<string>();
  useEffect(() => {
    async function loadUrl() {
      let response = await apiClient.POST("/integration/google/url", {});
      setUrl(response.data?.url);
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    loadUrl();
  }, []);
  return (
    <a href={url} target="_blank">
      <Button variant="warning">Click Here to Update Permissions</Button>
    </a>
  );
}

async function getGoogleIntegration() {
  const response = await apiClient.POST("/integration", {
    body: { orderBy: "createdAt" },
  });
  if (response.error != null) {
    toast.error(response.error.message);
    return null;
  }
  let integrations = response.data.data;

  // TODO: What if they have multiple google integrations?
  let google = integrations.find(
    (v) => v.platform == IntegrationPlatform.GOOGLE
  );
  if (google == null) return null;
  const googleResponse = await apiClient.GET(`/integration/{id}`, {
    params: { path: { id: google.id } },
  });
  if (googleResponse.error != null) {
    toast.error(googleResponse.error.message);
    return null;
  }
  return googleResponse.data;
}

export const INTEGRATIONS_QUERY_KEY = "settings-integrations-google";
const getIntegrationsOptions = () =>
  queryOptions({
    queryKey: [INTEGRATIONS_QUERY_KEY],
    queryFn: getGoogleIntegration,
  });

export default function Google(props: {
  onComplete: () => void;
  onboarding?: boolean;
}) {
  const [url, setUrl] = useState<string>();
  const { data: googleIntegration } = useQuery(getIntegrationsOptions());
  const context = useRouteContext({
    from: props.onboarding
      ? "/_onboarding/user-onboarding/$step"
      : "/_application",
  });
  const [customerId, setCustomerId] = useState<string>();
  const [customerEmail, setCustomerEmail] = useState<string>(
    context.user.email
  );
  const [integrating, setIntegrating] = useState(false);
  const [error, setError] = useState<string>();

  const googleDetectionsEnabled = useMemo(() => {
    return !!googleIntegration?.metadata.DETECTIONS_IDENTITY?.healthy;
  }, [googleIntegration]);

  const googleDirectoryEnabled = useMemo(() => {
    return !!googleIntegration?.metadata.DIRECTORY?.healthy;
  }, [googleIntegration]);

  async function submit() {
    if (customerId == null) {
      setError("Customer ID is required");
      return;
    }
    setIntegrating(true);
    const response = await apiClient.PUT("/integration/google/detection", {
      body: {
        customerId,
        customerEmail,
      },
    });
    setIntegrating(false);
    if (response.error != null) {
      setError("Error adding integration, please check the instructions");
      return;
    }

    props.onComplete();
  }
  useEffect(() => {
    async function loadUrl() {
      let response = await apiClient.POST("/integration/google/url", {});
      setUrl(response.data?.url);
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    loadUrl();
  }, []);

  return (
    <div>
      <hr className="my-4" />
      <h2 className="text-md font-semibold flex flex-row items-center gap-2">
        <div
          className={cn(
            "rounded-full p-2 h-8 w-8 flex items-center justify-center bg-muted   border",
            {
              "bg-green-300": googleDirectoryEnabled,
            }
          )}
        >
          1
        </div>
        Google Directory
      </h2>
      <div className="mt-2">
        <OAuthButton title="Integrate Directory" error={error} url={url} />
      </div>
      <hr className="my-4" />
      <h2 className="text-md font-semibold flex flex-row items-center gap-2">
        <div
          className={cn(
            "rounded-full p-2 h-8 w-8 flex items-center justify-center bg-muted   border",
            {
              "bg-green-300": googleDetectionsEnabled,
            }
          )}
        >
          2
        </div>
        Google Security Center
      </h2>
      <p className="text-xs text-muted-foreground mt-1">
        Google security center requires additional permissions that cannot be
        granted via OAuth.
      </p>
      <div className="space-y-4 mt-2">
        <div>
          <Label>Customer ID</Label>
          <Input
            value={customerId}
            onChange={(e) => setCustomerId(e.target.value)}
            name="customerId"
            placeholder="Customer ID"
          />
        </div>
        <div>
          <Label>Customer Email</Label>
          <Input
            value={customerEmail}
            onChange={(e) => setCustomerEmail(e.target.value)}
            name="customerEmail"
            placeholder="Customer Email"
          />
        </div>
      </div>
      <div className="flex flex-col mt-4 lg:flex-row gap-4">
        <Button disabled={integrating} onClick={submit}>
          Integrate Security Center
        </Button>
        <Button
          onClick={() => showArticle(DOCS.INTEGRATIONS.GOOGLE)}
          variant="outline"
        >
          Integration Instructions
        </Button>
      </div>
      {error && <p className="text-sm text-red-500">{error}</p>}
    </div>
  );
}
