import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { apiClient } from "@/lib/api";
import { showArticle } from "@intercom/messenger-js-sdk";
import { DOCS } from "@wire/shared";
import { useState } from "react";

export default function Okta(props: { onComplete: () => void }) {
  const [clientId, setClientId] = useState<string>();
  const [clientSecret, setClientSecret] = useState<string>();
  const [customerDomain, setCustomerDomain] = useState<string>();
  const [key, setKey] = useState<string>();
  const [error, setError] = useState<string>();

  async function submit() {
    if (clientId == null) {
      setError("Customer ID is required");
      return;
    }
    if (clientSecret == null) {
      setError("Client Secret is required");
      return;
    }
    if (customerDomain == null) {
      setError("Okta Domain is required");
      return;
    }

    const response = await apiClient.PUT("/integration/okta/directory", {
      body: {
        clientId,
        clientSecret,
        customerDomain,
      },
    });

    if (response.error != null) {
      setError(
        response.error.message ??
          "Error adding integration, please check the instructions"
      );
      return;
    }

    props.onComplete();
  }

  return (
    <>
      <div className="space-y-4">
        <div>
          <Label>Client ID</Label>
          <Input
            value={clientId}
            onChange={(e) => setClientId(e.target.value)}
            name="clientId"
            placeholder="Client ID"
          />
        </div>
        <div>
          <Label>Client Secret</Label>
          <Input
            type="password"
            value={clientSecret}
            onChange={(e) => setClientSecret(e.target.value)}
            name="clientSecret"
            placeholder="Client Secret"
          />
        </div>
        <div>
          <Label>Okta Domain</Label>
          <Input
            value={customerDomain}
            onChange={(e) => setCustomerDomain(e.target.value)}
            name="customerDomain"
            type="url"
            placeholder="https://company.okta.com"
          />
        </div>
      </div>

      {error && <p className="text-sm text-red-500">{error}</p>}
      <div className="flex flex-col lg:flex-row gap-4">
        <Button onClick={submit}>Integrate</Button>
        <Button
          onClick={() => showArticle(DOCS.INTEGRATIONS.OKTA)}
          variant="outline"
        >
          Integration Instructions
        </Button>
      </div>
    </>
  );
}
