import { AppLayout } from "@/components/app-layout";
import { TableCard } from "@/components/table-card";
import { CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { apiClient, formatSearchQuery } from "@/lib/api";
import { components } from "@/lib/api.types";
import {
  keepPreviousData,
  queryOptions,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useState } from "react";

export const Route = createFileRoute("/_application/assets/files/")({
  component: Files,
  loader: ({ context, deps }) => {
    //eslint-disable-next-line @typescript-eslint/no-floating-promises
    context.queryClient.ensureQueryData(getFileOptions());
  },
});

function getFileOptions(settings: components["schemas"]["PaginationDto"] = {}) {
  return queryOptions({
    queryKey: [FILES_QUERY_KEY, settings],
    queryFn: () => searchFiles(settings),
    placeholderData: keepPreviousData,
  });
}

async function searchFiles(
  searchSettings: components["schemas"]["PaginationDto"] = {}
) {
  let search = formatSearchQuery(searchSettings.search);
  const response = await apiClient.POST("/file", {
    body: { ...searchSettings, search },
  });

  if (response.error != null) {
    throw new Error("Error getting files");
  }

  return response.data;
}

const FILES_QUERY_KEY = "settings-files";

export default function Files() {
  const queryClient = useQueryClient();
  const [fileAutomationSearchSettings, setFileAutomationSearchSettings] =
    useState<components["schemas"]["PaginationDto"]>();
  const [searchSettings, setSearchSettings] =
    useState<components["schemas"]["PaginationDto"]>();
  const navigate = useNavigate();
  function updateSearchSettings(
    settings: components["schemas"]["PaginationDto"]
  ) {
    setSearchSettings({
      ...searchSettings,
      ...settings,
    });
  }
  const query = useQuery(getFileOptions(searchSettings));

  return (
    <AppLayout>
      <div className="flex flex-col gap-4">
        <TableCard
          onUpdate={updateSearchSettings}
          query={query}
          searchable
          compact
          onClickNavigate={(row) => ({
            to: "/assets/files/$fileId",
            params: { fileId: row.id },
          })}
          headers={[
            { display: "Name", key: "name", sortable: true },
            { display: "Path", key: "path", sortable: true },
            { display: "Malware", key: "malware", sortable: true },
            { display: "Benign", key: "benign", sortable: true },
            {
              display: "Late Stage Tool",
              key: "lateStageTool",
              sortable: true,
            },
            { display: "Info Stealer", key: "infoStealer", sortable: true },
            { display: "Ransomware", key: "ransomware", sortable: true },
            { display: "Crypto Miner", key: "cryptoMiner", sortable: true },
            {
              display: "Remote Management",
              key: "remoteManagementTool",
              sortable: true,
            },
          ]}
        >
          <CardHeader>
            <div className="flex  gap-4 items-start lg:items-center flex-col lg:flex-row justify-between">
              <div className="flex flex-col gap-2">
                <CardTitle>Files</CardTitle>
                <CardDescription>
                  Files pulled from detections on your endpoints
                </CardDescription>
              </div>
            </div>
          </CardHeader>
        </TableCard>
      </div>
    </AppLayout>
  );
}
