import { ConstrainedAppLayout } from "@/components/app-layout";
import ManageExclusion from "@/components/manage-exclusion";
import { TableCard } from "@/components/table-card";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { apiClient } from "@/lib/api";
import { components } from "@/lib/api.types";
import { dateTime } from "@/lib/time";
import {
  keepPreviousData,
  queryOptions,
  useQuery,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import {
  createDetectionQuery,
  DETECTION_QUERY_FIELDS,
  getStatusConfigByStatus,
  QUERY_OPERATOR,
  ROLE,
} from "@wire/shared";
import { useMemo, useState } from "react";

export const Route = createFileRoute("/_application/assets/domains/$domainId")({
  component: Domain,
  loader: async ({ params, context }) => {
    await context.queryClient.ensureQueryData(getOptions(params.domainId));
  },
});

async function getData(domainId: string) {
  const [domain] = await Promise.all([
    apiClient.GET("/domain/{id}", {
      params: { path: { id: domainId } },
    }),
  ]);
  if (domain.error != null) {
    throw new Error("Error getting domain information");
  }
  return {
    domain: domain.data,
  };
}

export const ASSET_QUERY_KEY = "domain";
const getOptions = (caseId: string) =>
  queryOptions({
    queryKey: [ASSET_QUERY_KEY, caseId],
    queryFn: () => getData(caseId),
  });

async function searchCases(
  searchSettings: components["schemas"]["SearchCasesDto"],
  domainId: string
) {
  const [cases] = await Promise.all([
    apiClient.POST("/cases", {
      body: { ...searchSettings, assetType: "DOMAIN", assetId: domainId },
    }),
  ]);
  if (cases.error != null) {
    throw new Error("Error getting cases information");
  }
  return cases.data;
}
export const CASES_QUERY_KEY = "case-settings";
const getSearchCaseOptions = (
  searchSettings: components["schemas"]["SearchCasesDto"],
  domainId: string
) =>
  queryOptions({
    queryKey: [CASES_QUERY_KEY, searchSettings, domainId],
    queryFn: () => searchCases(searchSettings, domainId),
    placeholderData: keepPreviousData,
  });

function Domain() {
  const { domainId } = Route.useParams();
  const [createExclusionDialogOpen, setCreateExclusionDialogOpen] =
    useState(false);
  const [searchSettings, setSearchSettings] = useState<
    components["schemas"]["SearchCasesDto"]
  >({});
  const casesQuery = useQuery(getSearchCaseOptions(searchSettings, domainId));
  const {
    data: { domain },
  } = useSuspenseQuery(getOptions(domainId));
  const navigate = useNavigate();

  const defaultExclusionQuery = useMemo(() => {
    let values: [string, string] = ["", ""];
    if (domain.name != null) {
      values = [DETECTION_QUERY_FIELDS.DOMAIN_NAME, domain.name];
    } else {
      return "";
    }
    return createDetectionQuery(
      values[0],
      QUERY_OPERATOR.ALL_ARRAY_VALUES_EQUAL,
      values[1]
    );
  }, []);

  return (
    <ConstrainedAppLayout>
      <ManageExclusion
        title="Create Exclusion"
        query={defaultExclusionQuery}
        name={domain.displayName}
        providedDetectionSid
        detectionSid={casesQuery.data?.data[0]?.detectionSids[0]}
        description="Automatically close future detections that match this query"
        open={createExclusionDialogOpen}
        onClose={() => setCreateExclusionDialogOpen(false)}
      />
      <div className="flex flex-col gap-4">
        <Card>
          <CardHeader className="bg-muted/50 space-y-0 items-center mb-4 flex flex-col gap-4 lg:flex-row lg:justify-between">
            <div>
              <CardTitle>
                <code>{domain.name}</code>
              </CardTitle>
              <CardDescription>Domain</CardDescription>
            </div>
            <DropdownMenu requiredRole={ROLE.ANALYST}>
              <DropdownMenuTrigger asChild>
                <Button>Actions</Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="max-w-64">
                <DropdownMenuItem
                  onClick={() => setCreateExclusionDialogOpen(true)}
                >
                  <div>
                    <h4 className="font-semibold">Create Exclusion</h4>
                    <p className="text-muted-foreground">
                      Automatically ignore detections that are associated with
                      this location
                    </p>
                  </div>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </CardHeader>
          <CardContent className="flex overflow-auto flex-col gap-2">
            <div>
              <h2 className="font-semibold">Created At</h2>
              <p className="text-sm">{dateTime(domain.createdAt)}</p>
            </div>
          </CardContent>
        </Card>
        <TableCard
          onClick={(row) =>
            navigate({
              to: "/cases/$caseId",
              params: { caseId: row.id },
            })
          }
          query={casesQuery}
          onUpdate={(settings) =>
            setSearchSettings({ ...searchSettings, ...settings })
          }
          headers={[
            {
              display: "ID",
              key: "sid",
              sortable: true,
            },
            {
              display: "Name",
              key: "name",
              sortable: true,
            },
            {
              display: "Status",
              key: "status",
              format: (value) => getStatusConfigByStatus(value)?.display,
            },
            {
              display: "Created At",
              key: "firstDetectionSourceDetectedAt",
              sortable: true,
              format(value) {
                return dateTime(value);
              },
            },
          ]}
        >
          <CardHeader className="bg-muted/50 mb-4">
            <CardTitle>Related Cases</CardTitle>
            <CardDescription>
              Cases that this domain has been associated with
            </CardDescription>
          </CardHeader>
        </TableCard>
      </div>
    </ConstrainedAppLayout>
  );
}
