import * as React from "react";

import { cn } from "@/lib/utils";
import { useRouteContext } from "@tanstack/react-router";
import { ROLE, userHasRole } from "@wire/shared";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<
  HTMLInputElement,
  InputProps & { requiredRole?: ROLE }
>(({ className, type, requiredRole, ...props }, ref) => {
  const context = useRouteContext({ from: "__root__" });
  if (
    context.user != null &&
    requiredRole != null &&
    !userHasRole(context.user.role, requiredRole)
  ) {
    props.disabled = true;
  }
  return (
    <input
      type={type}
      className={cn(
        "flex h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50",
        className
      )}
      ref={ref}
      {...props}
    />
  );
});
Input.displayName = "Input";

export { Input };
