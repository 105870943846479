import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { apiClient } from "@/lib/api";
import { useRouteContext } from "@tanstack/react-router";
import { FormEvent, useRef, useState } from "react";
import { toast } from "sonner";

export default function Email(props: {
  onComplete: () => void;
  onboarding?: boolean;
}) {
  const { user } = useRouteContext({
    from: props.onboarding
      ? "/_onboarding/user-onboarding/$step"
      : "/_application",
  });
  const [email, setEmail] = useState(user.email);
  const ref = useRef<HTMLInputElement>(null);

  async function addEmailIntegration(e: FormEvent) {
    e.preventDefault();
    const response = await apiClient.PUT("/integration/email", {
      body: {
        email: email,
      },
    });

    if (response.error != null) {
      toast.error(response.error.message);
    } else {
      toast.success("Integration added");
      props.onComplete();
    }
  }

  return (
    <div>
      <div>
        <form onSubmit={addEmailIntegration}>
          <Label>Security Team Email</Label>
          <Input
            ref={ref}
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <p className="text-muted-foreground text-xs mt-2">
            Critical escalations and those without an identified end user will
            be sent to this email
          </p>
          <Button type="submit" className="mt-4">
            Integrate Email
          </Button>
        </form>
      </div>
    </div>
  );
}
