"use client";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { apiClient } from "@/lib/api";
import { components } from "@/lib/api.types";
import { useNavigate } from "@tanstack/react-router";
import { ROLE } from "@wire/shared";
import { useState } from "react";
import { toast } from "sonner";

export default function DeleteIntegration({
  integration,
}: {
  integration: components["schemas"]["Integration"];
}) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  async function deleteIntegration() {
    setLoading(true);
    const promise = apiClient.DELETE("/integration/{id}", {
      params: { path: { id: integration.id } },
    });
    toast.promise(promise, {
      loading: "Deleting integration...",
    });
    const response = await promise;
    if (response.error != null) {
      toast.error((response as any).error.message);
      setLoading(false);
      return;
    }
    toast.warning("Integration deleted");
    await navigate({ to: "/settings/integrations" });
  }
  return (
    <Dialog requiredRole={ROLE.ADMIN} open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button disabled={loading} variant="outlineDestructive">
          Delete
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Are you absolutely sure?</DialogTitle>
          <DialogDescription>
            This action cannot be undone. This will permanently delete your
            integration and remove your data from our servers.
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            disabled={loading}
            variant="destructive"
            onClick={deleteIntegration}
          >
            Delete
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
