import AutomationSearchBuilder from "@/components/automation-search-builder";
import { BaseCustomEdgeDataProps } from "@/components/flow/edge/base-custom-edge";
import { BaseCustomNodeDataProps } from "@/components/flow/node/base-custom-node";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { components } from "@/lib/api.types";
import { Edge, Node } from "@xyflow/react";
import { memo, useEffect, useMemo, useState } from "react";

interface BaseCustomEditorProps {
  data?: BaseCustomNodeDataProps | BaseCustomEdgeDataProps;
  updateNodeById: (id: string, node: Partial<Node>) => void;
  updateEdgeById: (id: string, edge: Partial<Edge>) => void;
  hideTitle?: boolean;
  hideQuery?: boolean;
  hideDescription?: boolean;
  selectedNode?: Node<BaseCustomNodeDataProps> | undefined;
  selectedEdge?: Edge<BaseCustomEdgeDataProps> | undefined;
  selectedDetection?: components["schemas"]["Detection"] | null;
}

function BaseCustomEditor({
  children,
  hideTitle,
  updateNodeById,
  updateEdgeById,
  selectedNode,
  selectedEdge,
  hideQuery,
  selectedDetection,
  hideDescription,
}: React.PropsWithChildren<BaseCustomEditorProps>) {
  const selected = useMemo(() => {
    return selectedNode ?? selectedEdge;
  }, [selectedNode, selectedEdge]);
  const data = useMemo(() => {
    return selected?.data;
  }, [selectedNode, selectedEdge]);
  const [title, setTitle] = useState(data?.title ?? "");
  const [description, setDescription] = useState(data?.description ?? "");
  const [query, setQuery] = useState(data?.query ?? "");

  useEffect(() => {
    if (
      title != data?.title ||
      description != data?.description ||
      query != data?.query
    ) {
      if (selectedNode != null) {
        updateNodeById(selectedNode.id, {
          data: { title, description, query },
        });
      } else if (selectedEdge != null) {
        updateEdgeById(selectedEdge.id, {
          data: { title, description, query },
        });
      }
    }
  }, [title, description, query]);

  useEffect(() => {
    setTitle(data?.title ?? "");
    setDescription(data?.description ?? "");
    setQuery(data?.query ?? "");
  }, [selected?.type, selected?.id]);

  return (
    <div className="flex flex-col gap-2">
      {!hideTitle && (
        <div>
          <Label className="text-xs">Title</Label>
          <Input value={title} onChange={(e) => setTitle(e.target.value)} />
        </div>
      )}
      {!hideDescription && (
        <div>
          <Label className="text-xs">Description</Label>
          <Textarea
            rows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
      )}
      {!hideQuery && (
        <div>
          <Label className="text-xs">Query</Label>

          <AutomationSearchBuilder
            onQueryChange={setQuery}
            defaultQuery={query}
            data={selectedDetection}
          />
        </div>
      )}
      {children}
    </div>
  );
}

let BaseCustomEditorMemo = memo(BaseCustomEditor);
export { BaseCustomEditorMemo as BaseCustomEditor };
