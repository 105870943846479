import { differenceInMilliseconds, differenceInSeconds } from "date-fns";
import moment from "moment";

export function dateTime(val: string | number, local = false) {
  let date = moment(val);
  if (date.isValid()) {
    return date.utc(false).local(local).format("MMMM Do YYYY @ h:mm:ss a");
  }
  return "N/A";
}

export function dateTimeBuilder(val: string | Date) {
  let date = moment(val);
  if (date.isValid()) {
    return date.utc(false);
  }
  return moment("NaN");
}

export function getTimezone() {
  return new Date()
    .toLocaleTimeString("en-us", { timeZoneName: "short" })
    .split(" ")[2];
}

export function calculateTTRSeconds(start: string, end: string) {
  return differenceInSeconds(end, start);
}

export function calculateTTRMilliseconds(start?: string, end?: string) {
  if (!start || !end) return;
  return differenceInMilliseconds(end, start);
}
