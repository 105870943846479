import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import { apiClient } from "@/lib/api";
import { cn } from "@/lib/utils";
import { useEffect, useRef, useState } from "react";

async function getAPIVersion() {
  try {
    const response = await apiClient.GET("/version");
    return response.data?.version ?? "";
  } catch (err) {
    return;
  }
}

async function getVersionsFromUI() {
  try {
    const response = await fetch(`/versions.json?${Math.random()}`);
    const data: { api: string; ui: string } = await response.json();
    return data;
  } catch (err) {
    return;
  }
}

function semanticVersionGreater(old?: string, current?: string) {
  const oldParts = old?.split(".").map(Number);
  const currentParts = current?.split(".").map(Number);
  if (
    oldParts == null ||
    currentParts == null ||
    oldParts.length != currentParts.length
  ) {
    return;
  }
  for (let i = 0; i < oldParts.length; i++) {
    if (currentParts[i] > oldParts[i]) {
      return true;
    }
  }
  return false;
}

interface Versions {
  api?: string;
  ui: string;
}

export default function Footer() {
  const [apiUpdating, setAPIUpdating] = useState(false);
  const [uiUpdated, setUIUpdated] = useState(false);
  const [promptRefresh, setPromptRefresh] = useState(false);
  const [initialVersions, setInitialVersions] = useState<Versions>();
  const [currentVersions, setCurrentVersions] = useState<Versions>();

  useEffect(
    function handleAPIVersions() {
      let timeout: Timer;
      // The API does slow rollouts, so the UI may have updated and be expecting the new version
      // but one or multiple API servers are still on the old version, consider it updating either way
      if (
        semanticVersionGreater(initialVersions?.api, currentVersions?.api) ||
        semanticVersionGreater(currentVersions?.api, initialVersions?.api)
      ) {
        setAPIUpdating(true);
        // Give the API 2 minutes to finish rolling out
        setTimeout(() => {
          setAPIUpdating(false);
        }, 120000);
      }

      return () => clearTimeout(timeout);
    },
    [currentVersions?.api, initialVersions?.api]
  );

  useEffect(
    function handleUIVersions() {
      let timeout: Timer;
      if (semanticVersionGreater(initialVersions?.ui, currentVersions?.ui)) {
        setUIUpdated(true);
        // Give the update time to roll out
        timeout = setTimeout(() => {
          setPromptRefresh(true);
        }, 120_000);
      }

      return () => clearTimeout(timeout);
    },
    [currentVersions?.ui, initialVersions?.ui]
  );

  useEffect(() => {
    async function checkVersions() {
      const [apiVersion, uiResponse] = await Promise.all([
        getAPIVersion(),
        getVersionsFromUI(),
      ]);
      if (apiVersion == null || uiResponse == null) return;

      setCurrentVersions({
        api: apiVersion ?? currentVersions?.api,
        ui: uiResponse?.ui,
      });
    }
    let interval = setInterval(async () => {
      await checkVersions();
    }, 30_000);

    //eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      if (initialVersions == null) {
        setInitialVersions(await getVersionsFromUI());
      }
      await checkVersions();
    })();

    return () => clearInterval(interval);
  }, []);

  return (
    <footer className="flex flex-1 mt-4 justify-center items-end">
      <div className="text-xs text-center text-muted-foreground">
        <Dialog open={promptRefresh} onOpenChange={setPromptRefresh}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>New Version Available</DialogTitle>
              <DialogDescription>
                A new version of Wirespeed is available
              </DialogDescription>
            </DialogHeader>
            <DialogFooter>
              <Button onClick={() => window.location.reload()}>Refresh</Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
        <div>
          <HoverCard openDelay={50} closeDelay={50}>
            <HoverCardTrigger asChild>
              <span
                className={cn("cursor-pointer", {
                  "text-red-500": uiUpdated,
                })}
              >
                UI: v{initialVersions?.ui}
              </span>
            </HoverCardTrigger>
            <HoverCardContent className="w-auto">
              {uiUpdated ? "Update available" : "Latest version"}
            </HoverCardContent>
          </HoverCard>
          {" | "}
          <HoverCard openDelay={50} closeDelay={50}>
            <HoverCardTrigger asChild>
              <span
                className={cn("cursor-pointer", {
                  "text-yellow-500": apiUpdating,
                })}
              >
                API: v{initialVersions?.api}
              </span>
            </HoverCardTrigger>
            <HoverCardContent className="w-auto">
              {apiUpdating ? "Update in progress" : "Latest version"}
            </HoverCardContent>
          </HoverCard>
        </div>
        <p>© 2024 Wirespeed. All rights reserved.</p>
      </div>
    </footer>
  );
}
