"use client";
import ProfileSettings, {
  getProfileSettingsOptions,
} from "@/components/settings/profile";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_application/settings/profile")({
  loader: async ({ context }) => {
    await context.queryClient.ensureQueryData(getProfileSettingsOptions());
  },
  component: ProfileSettings,
});
